<template>
  <div class="holders" >
    <v-sheet
      class="d-flex"
      :dark="dark"
      :style="dark ? 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(15,34,16,1) 0%, rgba(37,37,37,1) 100%);border-radius:10px' : 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(43,119,3,1) 0%, rgba(214,255,193,1) 100%);border-radius:10px'"
      tile
      >
      <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:5px;margin-left:5px' : 'margin-right:40px;margin-left:40px'" >
          <p class="mt-4"></p>
          <p class="mt-4"></p>
          <div class="text-center font-weight-bold" style="font-size:30px;margin-right:20px;margin-left:20px">Free Features and Tiers for Token Holders</div>
          <p class="mt-3"></p>

        <v-layout row wrap :style="windowWidth < 1400 ? '' : 'margin-right:10%;margin-left:10%;'">

          <v-col
             cols="12"
             sm="4"
           >
             <v-hover
               v-slot:default="{ hover }"
               open-delay="200"
             >
               <v-card
                 :elevation="hover ? 16 : 2"
                 class="mx-auto"
                 :dark="dark"
                 :min-height="drawer ? 650 : 580"
                 max-height="900"
                 :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
               >
               <v-toolbar flat color="transparent" pa-2>
                 <!-- <v-icon :dark="!dark" x-large >mdi-script-text-outline</v-icon> -->
                 <p style="font-size:22px" class="font-weight-bold  mt-4">
                   FREE
                 </p>
                 <v-spacer></v-spacer>
                 <v-img src="/img/tiers/1.png" max-width="50" max-height="50" style="opacity:0.7"></v-img>
               </v-toolbar>

                 <v-layout column pr-4 pl-4>

                   <template v-for="(item, i) in itemsFree[0].text" >
                     <span :key="i" style="font-size:18x" class="font-weight-bold mt-4">
                       {{ item }}
                     </span>
                   </template>

                 </v-layout>

               </v-card>
             </v-hover>
           </v-col>

           <v-col
              cols="12"
              sm="4"
            >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card
                  :elevation="hover ? 16 : 2"
                  class="mx-auto"
                  :dark="dark"
                  :min-height="drawer ? 650 : 580"
                  max-height="900"
                  :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
                >
                <v-toolbar flat color="transparent" pa-2>
                  <!-- <v-icon :dark="!dark" x-large >mdi-script-text-outline</v-icon> 40,000 TMO Holders -->
                  <p style="font-size:22px" class="font-weight-bold  mt-4">
                    GOBLIN
                  </p>
                  <v-spacer></v-spacer>
                  <v-img src="/img/tiers/2.png" max-width="50" max-height="50" style="opacity:0.7"></v-img>
                </v-toolbar>

                <v-layout column pr-4 pl-4  pb-4>

                  <template v-for="(item, i) in items10k[0].text" >
                    <span :key="i" style="font-size:18x" class="font-weight-bold mt-4">
                      {{ item }}
                    </span>
                  </template>

                </v-layout>

                <v-card-actions>
                 <v-btn
                   :loading="loadingData" block
                   @click="subscribeTier('GOBLIN')"
                 >
                 <v-icon v-if="getUser.accLevel < 2" left>mdi-arrow-right-bold</v-icon>
                 <v-icon v-if="getUser.accLevel > 1" left>mdi-check-circle-outline</v-icon>
                   {{ getUser.accLevel === 1 ? 'Activate' : getUser.accLevel === 2 ? 'Active' : 'Active' }}
                 </v-btn>
                </v-card-actions>

                </v-card>
              </v-hover>
            </v-col>

            <v-col
               cols="12"
               sm="4"
             >
               <v-hover
                 v-slot:default="{ hover }"
                 open-delay="200"
               >
                 <v-card
                   :elevation="hover ? 16 : 2"
                   class="mx-auto"
                   :dark="dark"
                   :min-height="drawer ? 650 : 580"
                   max-height="900"
                   :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
                 >
                 <v-toolbar flat color="transparent" pa-2>
                   <!-- <v-icon :dark="!dark" x-large >mdi-script-text-outline</v-icon> 75,000 TMO Holders -->
                   <p style="font-size:22px" class="font-weight-bold  mt-4">
                     OGRE
                   </p>
                   <v-spacer></v-spacer>
                   <v-img src="/img/tiers/3.png" max-width="50" max-height="50" style="opacity:0.9"></v-img>
                 </v-toolbar>

                 <v-layout column pr-4 pl-4 pb-4>

                   <template v-for="(item, i) in items75k[0].text" >
                     <span :key="i" style="font-size:18x" class="font-weight-bold mt-4">
                       {{ item }}
                     </span>
                   </template>

                 </v-layout>

                 <v-card-actions>
                  <v-btn
                    :loading="loadingData1" block
                    @click="subscribeTier('OGRE')"
                  >
                    <v-icon v-if="getUser.accLevel < 3" left>mdi-arrow-right-bold</v-icon>
                    <v-icon v-if="getUser.accLevel > 2" left>mdi-check-circle-outline</v-icon>
                    {{ getUser.accLevel === 1 ? 'Activate' : getUser.accLevel === 2 ? 'Activate' : getUser.accLevel === 3 ? 'Active' : '' }}
                  </v-btn>
                 </v-card-actions>

                 </v-card>
               </v-hover>
             </v-col>

        </v-layout>
        <p class="mt-4"></p>
        <p class="mt-4"></p>
        <p class="mt-4"></p>
        <p class="mt-4"></p>
      </v-layout>
    </v-sheet>

    <v-dialog
        persistent
        v-model="subscribeTierDialog"
        width="500"
      >
        <v-card :dark="dark">
          <v-toolbar flat :color="dark ? 'grey darken-4' : 'grey lighten-3'">
            <v-card-title>
              Activate {{ selectedTier.type }} Tier
            </v-card-title>
          </v-toolbar>

          <p class="mt-1"></p>

          <v-card-text v-if="getUser.accLevel === 1 && selectedTier.type === 'GOBLIN'">
            You are currently a FREE User.<br><br>In Order to go the the GOBLIN Tier you need to Hold a minimum of 40,000 TMO Tokens.
          </v-card-text>
          <v-card-text v-if="getUser.accLevel === 1 && selectedTier.type === 'OGRE'">
            You are currently a FREE User.<br><br>In Order to go the the OGRE Tier you need to Hold a minimum of 75,000 TMO Tokens.
          </v-card-text>

          <v-card-text v-if="getUser.accLevel === 2">
            You are currently a GOBLIN.<br><br>In Order to go the the OGRE Tier you need to Hold a minimum of 75,000 TMO Tokens.
          </v-card-text>

          <v-card-text v-if="getUser.accLevel === 3">
            You are currently an OGRE.<br><br>You can enjoy all the features from FREE, GOBLIN AND OGRE Tier.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn v-if="getUser.accLevel < 3"
              color="#388E3C"
              @click="subscribeTierClicked"
            >
              Buy Now
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import {scroller} from 'vue-scrollto/src/scrollTo'
export default {
  name: 'tiers',
  props: {
    dark: Boolean,
    windowWidth: Number,
    drawer: Boolean
  },
  data () {
    return {
      logoSrcWalletDark: '',
      logoSrcWalletLight: '',
      loadingData: false,
      loadingData1: false,
      subscribeTierDialog: false,
      selectedTier: {},
      itemsFree: [
        {
          title: 'Free Tier',
          text: ['✔ Add Tokens', '✔ Manage tokens and update details', '✔ Online Discussion / Chat', '✔ Report possible Scams', '✔ Token vetting', '✔ Explore & search for tokens', '✔ Add 10 Tokens to my favorites list', '✔ Access to MEME/NFT Exchange'],
          color: '#388E3C',
          colorHex: this.getTheme
        }
      ],
      items10k: [
        {
          title: '10K Tier',
          text: ['✔ Get alerts for launch events', '✔ Alerts for recently added tokens to CG and CMC', '✔ Get alerts for scams & dangerous projects (honeypots)', '✔ My Wallet total portfolio value', '✔ Limited airdrops and access to airdrops from participating launches', '✔ Add cross chain Tokens with Cardano, Polkadot, NEO and TRON', '✔ Add 20 Tokens to my favorites list', '✔ Links and ratings to other launchpads (kind of CoinGecko for launchpads) all chains'],
          color: '#388E3C',
          colorHex: this.getTheme
        }
      ],
      items75k: [
        {
          title: '75K Tier',
          text: ['✔ First access to additional features when launched', '✔ Token LAUNCHPAD rolling out for all platforms/chains', '✔ Swap platform', '✔ Limit orders on swap platforms', '✔ Airdrops to TMO holders from projects launching', '✔ Smart Contract interfaces... Produce your own token and launch your own project on Token Monster on multiple chains', '✔ Unlimited Tokens to my favorites list', '✔ Use Our Trading Bots'],
          color: '#388E3C',
          colorHex: this.getTheme
        }
      ]
    }
  },
  watch: {
  },
  computed: {
    getUser () {
      return this.$store.state.user
    }
  },
  created () {
    // this.windowWidth = this.$props.windowWidth
    this.logoSrcWalletDark = './img/icons/my_wallet_new_dark.png'
    this.logoSrcWalletLight = './img/icons/my_wallet_new_light.png'
  },
  methods: {
    openRealness () {
      this.$emit('realnessClicked')
    },
    openReadMoreTMO () {

    },
    subscribeTier (type) {

      if (type === 'GOBLIN') {
        this.loadingData = true
        this.selectedTier = {
          type: type,
          cost: '1,000 USD p/month',
          costTMO: 'calculate current cenversion',
          holding: '40,000 TMO'
        }
      } else if (type === 'OGRE') {
        this.loadingData1 = true
        this.selectedTier = {
          type: type,
          cost: '3,000 USD p/month',
          costTMO: 'calculate current cenversion',
          holding: '75,000 TMO'
        }
      }
      this.subscribeTierDialog = true
    },
    subscribeTierClicked () {
      this.subscribeTierDialog = false
      this.loadingData = false
      this.loadingData1 = false
      const firstScrollTo = scroller()
      setTimeout(() => {
        firstScrollTo('#buytmo')
      }, 200)
    },
    closeDialog () {
      this.subscribeTierDialog = false
      this.loadingData = false
      this.loadingData1 = false
    }
  }
}
</script>

<style>
  .myDiv {
    background:url('/img/app/card_lime_light_02.jpeg') no-repeat;
  }
  .myDivDark {
    background:url('/img/app/card_lime_dark.jpeg') no-repeat;
  }
</style>
