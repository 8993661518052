<template>
  <v-card :dark="dark" tile flat style="width: 100%;height: auto">
    <v-layout column align-center :style="windowWidth < 800 ? 'margin-right:20px' : 'margin-right:100px;margin-left:100px'">
      <p class="mt-3"></p>
      <div class="display-1 text--center">Roadmap</div>
      <p class="mt-3"></p>
      <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown" style="width:100%;">
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          :color="item.color"
          :icon="item.icon"
          fill-dot
          :dark="dark"
          large
        >
        <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold`"
              :style="`color: ${item.colorHex}`"
              v-text="item.date"
            ></span>
          </template>
          <v-card
            :color="item.color"
            dark
          >
            <v-card-title :style="dark ? 'color: #FFF' : 'color: #FFF'" class="title">{{ item.title }}</v-card-title>
            <v-card-text class="white text--primary" style="padding-top:10px">
              <template v-for="(t, index) in item.text">
                <p :key="index">{{ t }}</p>
              </template>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>

      <p class="mt-5"></p>

    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: 'timeline',
  props: {
    dark: Boolean,
    windowWidth: Number
  },
  data () {
    return {
      items: [
        {
          title: 'Token Monster startup mode',
          text: ['✅ Website and App Launch', '✅ Online Discussion Chat','✅ Report possible Scams', '✅ Add tokens BSC/ETH/KuCoin', '✅ Promote tokens / Promotion packages', '✅ Manage tokens and update details', '✅ Explore & search for tokens', '✅ MetaMask Integration', '✅ Binance Chain Wallet Connect', '✅  Wallet Connect for Mobile', '✅ Setup social channels', '✅ Start pre-launch marketing plans on social channels'],
          date: 'Phase 1',
          color: '#388E3C',
          colorHex: this.getTheme,
          icon: 'mdi-calendar-start'
        },
        {
          title: 'Token Monster (TMO) Launch',
          text: ['✅ Final Tokenomics release', '✅  Contract Deployment', '⬜ Liquidity locked', '⬜ Trade on Pancakewsap V2', '⬜ Activate TMO post-launch marketing plans', '⬜ 1st Whitepaper release', '⬜ Alerts via App/SMS/Email for Token Launches', '⬜ Alerts for recently added tokens to CG and CMC', '✅ Promote and Support Polygon (MATIC) chain', '✅ Promote and Support KuCoin (KCC) chain', '⬜ Token vetting', '⬜ Apply to CoinGecko and CoinMarketCap', '⬜ Listing on CoinGecko and CoinMarketCap', '⬜ Reach 3,000 TMO Holders', '⬜ Reach 2,000 Telegram members'],
          date: 'Phase 2',
          color: '#388E3C',
          colorHex: this.getTheme,
          icon: 'mdi-rocket-launch-outline'
        },
        {
          title: 'Taking shape and getting it out',
          text: ['⬜ Publish Final Whitepaper', '⬜ MEME & NFT platform App development', '⬜ MEME & NFT platform demo and previews', '⬜ Cross chain with Cardano, Polkadot, NEO and TRON', '⬜ Token LAUNCHPAD rolling out for all platforms/chains', '⬜ Swap platform', '⬜ Limit orders on swap platforms', '⬜ Airdrops to TMO holders from projects launching', '⬜ Airdrops to TMO holders from Token Monster from time to time', '⬜ Continue marketing and funneling','⬜ Apply to other exchanges', '⬜ Reach 15,000 TMO Holders', '⬜ Reach 5,000 Telegram members'],
          date: 'Phase 3',
          color: '#388E3C',
          colorHex: this.getTheme,
          icon: 'mdi-bullhorn-outline'
        },
        {
          title: 'Time to grow!',
          text: ['⬜ MetaMask & Binance Chain Wallet ( Full Funtionality )', '⬜ Hybrid MEME & NFT platform Launch (Mobile)', '⬜ MEME/NFT Exchange', '⬜ Collect and Trade / Exchange', '⬜ Implementation of Trading Bots', '⬜ Smart Contract interfaces... Produce your own token and launch your own project on Token Monster on multiple chains', '⬜ Links and ratings to other launchpads (kind of CoinGecko for launchpads) all chains', '⬜ Apply to more exchanges', '⬜ Reach 30,000 TMO Holders', '⬜ Reach 15,000 Telegram members'],
          date: 'Phase 4',
          color: '#388E3C',
          colorHex: this.getTheme,
          icon: 'mdi-sprout-outline'
        }
      ]
    }
  },
  watch: {
  },
  created () {
    // this.windowWidth = this.$props.windowWidth
  },
  methods: {
    signUpNow () {
      this.$emit('signUpClicked')
    }
  }
}
</script>
