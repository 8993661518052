<template>
  <div id="tokencomments" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column align-center v-if="loading" >

         <v-layout column style="width:100%">
           <p class="mt-2"></p>
           <v-spacer></v-spacer>
             <template v-for="n in 5" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="list-item-avatar-two-line"
                 width="100%"
                 height="60"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout >

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else style="width:100%">

           <v-layout column pa-2 style="width:100%">

             <v-list-item-content style="margin-left:10px">
               <span class="titleText" style="color:#388E3C" >Scam Reports</span>
             </v-list-item-content>

             <v-layout>
               <v-subheader style="margin-top:-10px" class="grey--text">{{ now }} - {{ tokenScamReports.length }} Reports</v-subheader>
               <v-spacer></v-spacer>
               <v-btn text color="blue" style="text-transform: none !important;"  @click="codeOfConductClicked">Code of Conduct
                 <v-icon color="blue" right>mdi-information-outline</v-icon>
               </v-btn>
             </v-layout>
             <v-divider></v-divider>

            <p class="mt-1"></p>

            <span style="padding-right:10px;font-size:14px;margin-bottom:10px" v-if="tokenScamReportsArr.length > 0"
              class="font-weight-bold">
              Reported by Token Monster users
            </span>

            <v-layout row pt-4 v-if="tokenScamReportsArr.length > 0">

              <template v-for="(item, index) in tokenScamReportsArr" >
               <v-badge v-if="item.count > 0"
                  color="red"
                  overlap
                  :key="index"
                >
                  <span slot="badge">{{ item.count }}</span>
                  <v-chip
                    class="ma-2"
                  >
                    {{ item.name }}
                  </v-chip>
                </v-badge>
              </template>
            </v-layout>
            <v-layout row pt-4 v-if="tokenScamReportsArr.length === 0">
              <span style="padding-left:10px;font-size:16px;color:green">Yai, No reports here.</span>
            </v-layout>

            <p class="mt-1"></p>

             <span style="padding-right:10px;font-size:14px" class="font-weight-bold mt-2">
               Report a possible scam for {{ tokenItem.name }} ({{ tokenItem.symbol }})
             </span>

             <p class="mt-1"></p>

             <v-autocomplete
               v-model="reportReason"
               :items="reportReasons"
               item-value="id"
               item-text="name"
               label="Select your reason..."
               placeholder="Select or search..."
               outlined
               :rules="[v => !!v || v === 0]"
             ></v-autocomplete>

             <v-text-field v-if="reportReason === 6"
               v-model="otherReason"
               label="Other Reason"
               placeholder="Please, describe your reason..."
               maxlength="200"
               outlined
             ></v-text-field>

             <v-btn color="green darken-1" :loading="loadingData" v-if="mmConnected || binanceConnected || walletConnected"
               @click="postScamRepoprtClicked" :disabled="reportReason === ''">Report Scam
             </v-btn>

             <v-btn outlined color="green darken-1" v-else text @click="connectWallet">Connect to file Scam Report</v-btn>

           </v-layout>

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

         <v-dialog v-model="codeOfConductDialog"  transition="dialog-bottom-transition" max-width="600">
           <v-card :dark="dark">
             <p class="mt-0"></p>
             <v-toolbar flat>
               <v-card-title :style="windowWidth < 770 ? 'font-size:22px;margin-top:20px' : 'font-size:22px'" class="wrap-text font-weight-bold">Scam Reports - Code of Conduct</v-card-title>
               <v-spacer></v-spacer>
               <v-btn icon @click="codeOfConductDialog = false">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
             </v-toolbar>

             <v-layout column pa-4 style="margin-left:20px">
               <p><span style="font-weight: 400;">Token Monster Scam Reports is intended to be informative in which users can be alerted on POSSIBLE scams. We kindly request that all users using Scam Reports follow the guidelines laid out in this Code of Conduct.</span></p>
<p><span style="font-weight: 400;">Before filing a Scam Report please make sure you always check the following:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Always double check that you are reporting a real issue.&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Always do your own research. (DYOR)</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">If the same issue has already been reported you can still report it and Token Monster will increase the count on that particular Scam Report.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Do not report scams when Tokens have already launched and are vetted to be safe.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Do not report an already vetted Token as a scam if you have suffered loss of funds due to buying or selling at the &ldquo;wrong&rdquo; moments.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Slow RUGS are hard to detect and have not been added as reason</span></li><br>
</ul>
<p><span style="font-weight: 400;">Token Monster reserves the right to immediately suspend a user which demonstrates inappropriate conduct as outlined, including but not limited to, any violation to the Code of Conduct above. If a user is in violation of the Code of Conduct the Token Monster Team may warn the user for such violation or may, at the Token Monster Team&rsquo;s sole discretion, immediately suspend this user.</span></p>

             </v-layout>

           </v-card>
         </v-dialog>

      </v-layout>
    </v-slide-y-transition>

    <v-snackbar
      :timeout="4000" :bottom="'bottom'" v-model="snackbar"
    >
      <v-icon color="green" style="margin-right:10px">mdi-check-circle-outline</v-icon>
      <span style="padding-left:10px">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#388E3C"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span >{{ snackbarText }}</span>
    </v-snackbar>

  </div>
</template>

<script>
import store from '@/store/index'
import firebase from 'firebase/app'
import { db } from '@/main'
var resizebase64 = require('resize-base64')
const dateformat = require('dateformat')
export default {
  name: 'scamReport',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    tokenItem: Object
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      showBottomNav: false,
      codeOfConductDialog: false,
      now: dateformat(new Date().getTime(), 'dd mmm, yyyy'),
      show: false,
      resultInterval: null,
      tokenScamReports: [],
      tokenScamReportsArr: [],
      report: '',
      firstReply: true,
      replyComment: '',
      replyKeepOpenId: '',
      valid: false,
      reportReason: '',
      reportReasons: [
        {name: 'Can’t sell (possible honeypot) - ERROR / SCAM', id: 0},
        {name: 'Rug Pull - SCAM', id: 1},
        {name: 'Liquidity Pool in unlocked wallet - RISK', id: 2},
        {name: 'Liquidity Lock only few Hours (or less) - RISK', id: 3},
        {name: 'Unlocked wallet(s) with big Token percentage(s) - RISK', id: 4},
        {name: 'Tokenomics not implemented as Dev Team has projected - RISK', id: 5},
        {name: 'Other', id: 6}
      ],
      otherReason: ''
    }
  },
  components: {
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getUser () {
      return this.$store.state.user
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
    this.loading = true
    // this.tokenComments = []
  },
  mounted () {
  // this.viewWidth = Math.round(this.windowWidth / 2)
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    this.currentUser = firebase.auth().currentUser
    this.init()
  },
  methods: {
    init () {
      // console.log('getUser.myTeams')
      // console.log(this.getUser.myTeams)
      this.getScamReports()
    },
    checkNew () {
      // console.log('Check new token opened 2...')
      this.getScamReports()
    },
    setLoadingTrue () {
      this.loading = true
    },
    getScamReports () {
      this.loading = true
      // console.log('Get token Scam Reports...')
      this.tokenScamReports = []
      this.tokenScamReportsArr = []
      let scamQuery = db.collection('tokens').doc(this.tokenItem.id).collection('scamreports').where('status', '==', 2).orderBy('created', 'desc')
      this.commentsRef = scamQuery.onSnapshot(querySnapshot => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`)
        if (querySnapshot.empty) {
          // console.log('No matching documents.')
          this.loadingData = false
          this.loading = false
          return
        }
        this.tokenScamReports = []
        this.tokenScamReportsArr = [
          { name: 'Honeypot', count: 0 },
          { name: 'Rugpull', count: 0 },
          { name: 'LP in Unlocked Wallet', count: 0 },
          { name: 'LP Locktime NOK', count: 0 },
          { name: 'Unlocked wallets holding big %', count: 0 },
          { name: 'Tokenomics NOK', count: 0 },
          { name: 'Other', count: 0 }
        ]
        querySnapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          var obj = doc.data()
          obj.id = doc.id
          this.tokenScamReports.push(obj)
          this.loading = false
          this.tokenScamReportsArr[doc.data().report].count += 1
        })
        this.loadingData = false
        this.loading = false
        // console.log(this.tokenScamReportsArr)
        // ...
        }, err => {
          console.log(`Encountered error: ${err}`)
          // this.loadingData = false
        })
    },
    connectWallet() {
      this.$emit('connectWalletClicked')
    },
    postScamRepoprtClicked () {
      // Check if user already reported such
      let check = this.tokenScamReports.filter(item => item.uid === this.getUser.uid && item.report === this.reportReason)
      // console.log(check.length > 0)
      if (check.length > 0) {
        this.snackbarText = 'You have already reported this issue'
        this.snackbarWarning = true
        return
      }

      this.loadingData = true
      var resizedAvatar
      if (this.getUser.avatar !== '') {
        resizedAvatar = resizebase64(this.getUser.avatar, 200, 200)
      } else {
        resizedAvatar = this.getUser.avatar
      }
      var payload = {
        postkey: this.tokenItem.id,
        report: this.reportReason,
        other: this.otherReason,
        name: this.getUser.displayName,
        uid: this.getUser.uid,
        avatar: resizedAvatar
      }
      this.$store.dispatch('postScamReport', payload)
        .then(() => {
          // this.$emit('updatedex', type)
          this.snackbarText = 'Your Report has been submitted.'
          this.snackbar = true
          this.loadingData = false
          // this.replyKeepOpenId = ''
          this.increaseScamCount(this.tokenItem.id)
        }).catch(error => {
          console.log(error)
          // this.addCollection = false
        })
    },
    increaseScamCount(docId) {
      let obj =  {
        scam_count: this.tokenItem.scam_count + 1,
        scam_last: new Date().getTime()
      }
      this.tokenItem.scam_count += 1
      // console.log('######### increase Scam Count ############')
      // console.log(docId)
      // console.log(obj)
      db.collection('tokens').doc(docId).update(obj)
        .then(() => {
            // console.log('Token scam_count in bucket updated')
          })
        .catch(error => {
            console.log(error)
          })
    },
    codeOfConductClicked () {
      this.codeOfConductDialog = true
    },
    makeDate (date) {
      return dateformat(new Date(date), 'dd mmm, yyyy HH:MM')
    },
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e);
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
  .titleText {
    font-size:28px;
    font-weight: 900;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
  .my-text-field-1 .v-input__append-inner .v-icon {
    color: #388E3C;
  }
</style>
