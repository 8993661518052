import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import VueScrollTo from 'vue-scrollto'
import PhoneInput from 'vue2-phone-input'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VueClipboards from 'vue-clipboards'
import Gravatar from 'vue-gravatar'
import 'tiptap-vuetify/dist/main.css'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: 'G-HECK993W1B' }
}, router)

Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease",
  offset: -64,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
 })
 Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})
 Vue.component('phone-input', PhoneInput)
 Vue.use(VueClipboards)
 Vue.use(VueApexCharts)
 Vue.component('v-gravatar', Gravatar)

if (window.location.protocol === 'file:' || window.location.port === '3000') {
  var cordovaScript = document.createElement('script')
  cordovaScript.setAttribute('type', 'text/javascript')
  cordovaScript.setAttribute('src', 'cordova.js')
  document.body.appendChild(cordovaScript)
}

// Firebase Production
var firebaseConfig = {
  apiKey: "AIzaSyBRYLN2PC7bLlHNaSjpHXn2PsP0Dil-U0A",
  authDomain: "tokenmonster-app.firebaseapp.com",
  projectId: "tokenmonster-app",
  storageBucket: "tokenmonster-app.appspot.com",
  messagingSenderId: "770690541673",
  appId: "1:770690541673:web:c215f28d85c8abfbdadaf8",
  measurementId: "G-HECK993W1B"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
// firebase.firestore().settings({ experimentalForceLongPolling: true })
export const db = firebase.firestore()

let app
// firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    // console.log('New instance created')
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
// })
