<template>
  <div id="howbuytmo">
    <v-sheet
      class="d-flex"
      :dark="dark"
      :style="dark ? 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(69,110,112,1) 0%, rgba(52,52,52,1) 100%);' : 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(112,131,132,1) 0%, rgba(234,254,255,1) 100%)'"
      tile
      >
      <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:5px;margin-left:5px;' : 'margin-right:40px;margin-left:40px;width: 100%;'" >
        <p class="mt-4"></p>
        <p v-if="windowWidth > 770" class="mt-4"></p>
        <div class="text-center font-weight-bold" :style="windowWidth < 770 ? 'font-size:16px;margin-right:10px;margin-left:10px' : 'font-size:24px;margin-right:20px;margin-left:20px'">BUY Token Monster (TMO)</div>
        <p class="mt-0"></p>
        <div class="text-center font-weight-bold" :style="windowWidth < 770 ? 'font-size:13px;margin-right:10px;margin-left:10px' : 'font-size:20px;margin-right:20px;margin-left:20px'">Contract Address</div>
        <span :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:20px'" class="wrap-text font-weight-medium">
          BSC: {{ contractAddress }}
          <v-icon :small="windowWidth < 770" style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => '0x49c248641eb904c9d5a33904d388212a89218187'"  @success="handleSuccess" @error="handleError">mdi-content-copy</v-icon>
        </span>
        <p class="mt-3"></p>

        <div class="text-center font-weight-bold" :style="windowWidth < 770 ? '' : 'margin-left:25%;margin-right:25%'">
          <span :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:18px;'" class="font-weight-medium text-center">
            <v-icon color="yellow accent-4" >mdi-alert-outline</v-icon><strong>WARNING</strong><v-icon color="yellow accent-4" >mdi-alert-outline</v-icon><br>
            BEWARE OF SCAMMERS AND GHOST CONTRACTS. WE WILL ANNOUNCE THE LAUNCH OF OUR TOKEN ON PANCAKE SWAP ON OUR OFFICIAL CHANNELS SOON.
          </span>
        </div>

       <p class="mt-2"></p>

        <v-layout v-if="windowWidth > 770" row wrap :style="windowWidth < 770 ? 'text-align: center;' : 'margin-right:20px;margin-left:20px;width: 80%;text-align: center;'">
          <v-col
             cols="12"
             sm="5"
           >
             <v-btn disabled rounded x-large @click="gotoLink('https://exchange.pancakeswap.finance/#/swap?outputCurrency=' + contractAddress)" style="text-transform: none !important;">
               <v-img :src="srcPancake" max-width="28" max-height="28" style="margin-right:10px">
               </v-img>Buy on PancakeSwap
             </v-btn>
         </v-col>

         <v-col
            cols="12"
            sm="4"
          >
            <v-btn rounded x-large @click="gotoLink('https://poocoin.app/tokens/' + contractAddress)" style="text-transform: none !important;">
              <v-img :src="srcPoocoin" max-width="28" max-height="28" style="margin-right:10px">
              </v-img>Poocoin Chart
            </v-btn>
        </v-col>

        <v-col
           cols="12"
           sm="3"
         >
           <v-btn rounded x-large @click="gotoLink('https://bscscan.com/token/' + contractAddress)">
             <v-img :src="dark ? srcBscDark : srcBsc" max-width="28" max-height="28" style="margin-right:10px">
             </v-img>BSCSCAN
           </v-btn>
       </v-col>

       </v-layout>

       <v-layout v-else column align-center>

         <v-btn disabled rounded x-large @click="gotoLink('https://exchange.pancakeswap.finance/#/swap?outputCurrency=' + contractAddress)" style="text-transform: none !important;">
           <v-img :src="srcPancake" max-width="28" max-height="28" style="margin-right:10px">
           </v-img>Buy on PancakeSwap
         </v-btn>
         <p class="mt-0"></p>
         <v-btn rounded x-large @click="gotoLink('https://poocoin.app/tokens/' + contractAddress)" style="text-transform: none !important;">
           <v-img :src="srcPoocoin" max-width="28" max-height="28" style="margin-right:10px">
           </v-img>Poocoin Chart
         </v-btn>
         <p class="mt-0"></p>
         <v-btn rounded x-large @click="gotoLink('https://bscscan.com/token/' + contractAddress)">
           <v-img :src="dark ? srcBscDark : srcBsc" max-width="28" max-height="28" style="margin-right:10px">
           </v-img>BSCSCAN
         </v-btn>

       </v-layout>

       <p class="mt-4"></p>
       <p class="mt-4"></p>

       <div class="text-center font-weight-bold" :style="windowWidth < 770 ? 'font-size:16px;margin-right:10px;margin-left:10px' : 'font-size:24px;margin-right:20px;margin-left:20px'">Need Help?</div>
       <p class="mt-4"></p>
       <div class="text-center font-weight-bold" :style="windowWidth < 770 ? 'font-size:14px;margin-right:10px;margin-left:10px' : 'font-size:20px;margin-right:20px;margin-left:20px'">If you are new to all this and have a lot of questions, please follow our guide.</div>
       <p class="mt-2"></p>
        <v-btn rounded x-large to="/howtobuy">
          <v-icon size="30" left>mdi-help-rhombus-outline
          </v-icon>How to Buy Guide
        </v-btn>
       <p class="mt-4"></p>
      </v-layout>
    </v-sheet>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon style="margin-left:10px" color="green">mdi-check-circle-outline</v-icon>
      <span >{{ snackbarText }}</span>
            <v-btn text color="#1B5E20" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span style="padding-left:10px">{{ snackbarText }}</span>
      <v-spacer></v-spacer>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'why',
  props: {
    dark: Boolean,
    windowWidth: Number,
    drawer: Boolean
  },
  data () {
    return {
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      contractAddress: '0x49c248641eb904c9d5a33904d388212a89218187',
      srcPancake: '',
      srcPoocoin: '',
      srcBsc: '',
      srcBscDark: ''
    }
  },
  watch: {
  },
  created () {
    this.srcPancake = './img/icons/pancakeswap.png'
    this.srcPoocoin = './img/icons/poocoin.png'
    this.srcBsc = './img/icons/bscscan_light.png'
    this.srcBscDark = './img/icons/bscscan_dark.png'
  },
  methods: {
    init () {
    },
    gotoLink (link) {
      window.open(link, '_blank')
    },
    handleSuccess (e) {
      console.log(e);
      this.snackbarText = 'Contract Address copied.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    }
  }
}
</script>
