<template>
  <div id="tokencomments" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column align-center v-if="loading" >

         <v-layout column style="width:100%">
           <p class="mt-2"></p>
           <v-spacer></v-spacer>
             <template v-for="n in 5" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="list-item-avatar-two-line"
                 width="100%"
                 height="60"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout >

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else style="width:100%">

          <v-layout column pa-2 style="width:100%">

            <v-list-item-content style="margin-left:10px">
              <span class="titleText" style="color:#388E3C" >Join the Discussion</span>
            </v-list-item-content>

            <v-layout>
              <v-subheader style="margin-top:-10px" class="grey--text">{{ now }} - {{ tokenComments.length }} Comments</v-subheader>
              <v-spacer></v-spacer>
              <v-btn text color="blue" style="text-transform: none !important;"  @click="codeOfConductClicked">Code of Conduct
                <v-icon color="blue" right>mdi-information-outline</v-icon>
              </v-btn>
            </v-layout>

            <v-divider></v-divider>

            <v-list two-line >
              <!--  if parent_id === 0 -->
              <template v-for="(item, index) in tokenComments">
                <v-list-item v-if="item.pid === '0'"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-img v-if="item.avatar !== ''" :src="item.avatar"></v-img>
                    <v-gravatar v-else :email="item.name" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title style="font-size:14px" class="grey--text" >{{ makeDate(item.created) }} by {{ item.name }}</v-list-item-title>
                    <span >{{ item.comment }}</span>
                  </v-list-item-content>

                  <v-list-item-icon v-if="mmConnected" style="margin-bottom:0px">
                    <v-layout column >
                      <v-tooltip left >
                        <template v-slot:activator="{ on }">
                          <v-btn @click.stop="flagComment(item)" v-on="on" icon >
                            <v-icon >
                            mdi-flag-variant-outline
                          </v-icon>
                        </v-btn>
                        </template>
                        <span>{{ getLanguage === 'id' ? 'Flag as inappropriate' : 'Flag as inappropriate' }} </span>
                      </v-tooltip>

                      <v-tooltip left >
                        <template v-slot:activator="{ on }">
                          <v-btn @click.stop="openReplies(index)" color="blue" v-on="on" icon style="margin-top:-5px">
                            <v-icon >
                            mdi-reply-all
                          </v-icon>
                        </v-btn>
                        </template>
                        <span>{{ getLanguage === 'id' ? 'Balas komentar ini' : 'Reply to this comment' }} </span>
                      </v-tooltip>
                    </v-layout>
                  </v-list-item-icon>
                </v-list-item>

                <v-layout column :key="index + 'f'" v-if="!item.first">
                <template v-for="(reply, index2) in item.replies">
                  <v-list-item style="margin-top:-20px;margin-left:35px"
                    :key="index2 + 'r'" v-if="(index2 === 0 && item.replies.length > 0)"
                  >
                  <v-btn text small color="green darken-1" class="font-weight-bold" @click="openReplies(index)">
                    <v-icon v-if="item.open" >mdi-chevron-up</v-icon>
                    <v-icon v-else >mdi-chevron-down</v-icon>
                    {{ item.open ? 'Hide' : 'View' }} {{ item.replies.length }} {{ item.replies.length == 1 ? 'reply' : 'replies' }}
                  </v-btn>
                  </v-list-item>

                  <v-list-item :style="parseInt(index2) === 0 ? 'margin-top:-20px;margin-left:45px' : 'margin-left:45px'"
                    :key="index2 + 'o'" v-show="item.open"
                  >
                    <v-list-item-avatar size="32">
                      <v-img v-if="reply.avatar !== ''" :src="reply.avatar"></v-img>
                      <v-gravatar v-else :email="reply.name" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title style="font-size:14px" class="grey--text" >{{ makeDate(reply.created) }} by {{ reply.name }}</v-list-item-title>
                      <span >{{ reply.comment }}</span>
                    </v-list-item-content>

                    <v-list-item-icon v-if="mmConnected" style="margin-bottom:0px">

                        <v-tooltip left >
                          <template v-slot:activator="{ on }">
                            <v-btn @click.stop="flagComment(reply)" v-on="on" icon >
                              <v-icon >
                              mdi-flag-variant-outline
                            </v-icon>
                          </v-btn>
                          </template>
                          <span>{{ getLanguage === 'id' ? 'Flag as inappropriate' : 'Flag as inappropriate' }} </span>
                        </v-tooltip>

                    </v-list-item-icon>

                  </v-list-item>

                  <v-list-item style="margin-left:45px"
                    :key="index2 + 're'" v-show="parseInt(index2) + 1 === item.replies.length && item.open"
                  >
                    <v-text-field v-if="mmConnected"
                      class="my-text-field-1"
                      v-model="item.replyComment"
                      color="#388E3C"
                      outlined
                      :placeholder="tokenComments.length > 0 ? 'Reply to comment...' : 'Be the first to reply...'"
                      maxlength="200"
                      append-icon="mdi-comment-text-outline"
                      counter
                      :id="item.id"
                      v-on:keyup="replyCommentClicked"
                      @click:append="replyCommentClicked('click', item.id, item.replyComment)"
                    ></v-text-field>
                  </v-list-item>

                </template>
                </v-layout>
                <v-layout column :key="index + 'fi'" v-if="item.first && item.open">
                  <v-list-item style="margin-left:45px"
                    :key="index + 'first'"
                  >
                    <v-text-field v-if="mmConnected"
                      class="my-text-field-1"
                      v-model="item.replyComment"
                      color="#388E3C"
                      outlined
                      :placeholder="tokenComments.length > 0 ? 'Reply to comment...' : 'Be the first to reply...'"
                      maxlength="200"
                      append-icon="mdi-comment-text-outline"
                      counter
                      :id="item.id"
                      v-on:keyup="replyCommentClicked"
                      @click:append="replyCommentClicked('click', item.id, item.replyComment)"
                    ></v-text-field>
                  </v-list-item>

                </v-layout>

              </template>

            </v-list>

            <!-- <v-divider v-if="tokenComments.length > 0"></v-divider> -->

            <v-text-field v-if="mmConnected || binanceConnected || walletConnected"
              class="my-text-field-1"
              v-model="comment"
              color="#388E3C"
              outlined
              :placeholder="tokenComments.length > 0 ? 'Post new comment...' : 'Be the first to comment...'"
              maxlength="200"
              append-icon="mdi-comment-text-outline"
              counter
              v-on:keyup="postCommentClicked"
              @click:append="postCommentClicked('click')"
            ></v-text-field>

             <v-btn outlined color="green darken-1" v-else text @click="connectWallet">Connect to post comments</v-btn>

             <p class="mt-2"></p>



          </v-layout>

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

         <v-dialog
           v-model="flagDialog"
           persistent
           max-width="360"
         >
           <v-card>
             <v-card-title class="headline wrap-text">
               {{ lang[getLanguage].FD_FLAG_COMMENT_TXT }}
             </v-card-title>
             <v-card-text>{{ flagItem.comment }}</v-card-text>
             <v-card-text>

             </v-card-text>
             <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn
                 color="grey darken-1"
                 text
                 @click="flagDialog = false"
               >
                 Cancel
               </v-btn>
               <v-btn
                 color="green darken-1"
                 outlined
                 @click="processFlagging"
               >
                 {{ lang[getLanguage].FD_FLAG_COMMENT }}
               </v-btn>
             </v-card-actions>
           </v-card>
         </v-dialog>

         <v-dialog v-model="codeOfConductDialog"  transition="dialog-bottom-transition" max-width="600">
           <v-card :dark="dark">
             <p class="mt-0"></p>
             <v-toolbar flat>
               <v-card-title :style="windowWidth < 770 ? 'font-size:22px;margin-top:20px' : 'font-size:22px'" class="wrap-text font-weight-bold">Discussion / Chat - Code of Conduct</v-card-title>
               <v-spacer></v-spacer>
               <v-btn icon @click="codeOfConductDialog = false">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
             </v-toolbar>

             <v-layout column pa-4 style="margin-left:20px"> <!-- We intend to do AMA on twitch very soon. -->
<p><span style="font-weight: 400;">Token Monster Discussion / Chat is intended to be informative in which users can find answers to their general information and questions. We kindly request that all users using online chats follow the guidelines laid out in this Code of Conduct.</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Please be polite and professional. Inappropriate conduct or harassment towards other users will not be tolerated.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">No Vulgar language. Vulgar or abusive language, masked with symbols, characters or otherwise, will not be tolerated.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">No racist remarks, sexually oriented remarks, or religious persecution will be tolerated; any such remarks will trigger the immediate suspension of the user account.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Actions that seem specifically designed to test the limits of what is not offensive and to serve no other purpose will not be tolerated.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Any other action that shall, at the sole discretion of Token Monster Team, be deemed offensive will not be tolerated.</span></li>
</ol>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Token Monster reserves the right to immediately suspend a user which demonstrates inappropriate conduct as outlined, including but not limited to, any violation to the Code of Conduct above. If a user is in violation of the Code of Conduct the Token Monster Team may warn the user for such violation or may, at the Token Monster Team&rsquo;s sole discretion, immediately suspend this user. Other actions may also be taken against users found to be in violation of the Code that include, but are not limited to, the following:</span></p>
<ol>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Receiving a written warning via chat and/or email regarding the misconduct incident.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Immediate suspension of the user account.</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Perpetual banning from one or any of the following Token Monster services or resources:</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">a) Online Chat / Discussion.</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">b) Other services or resources deemed appropriate for banning by Token Monster, at its sole discretion. We strive to provide you with the best products and services in the industry, including our online chat service. We&rsquo;d like to thank you in advance for following the guidelines outlined in this code of conduct when using Token Monster Online Chat!</span></li><br>
</ol>
<p><span style="font-weight: 400;">Token Monster reserves the right to change or amend the code of conduct at any time and without notice. Also, Token Monster reserves the right to continue to do or to refuse to do business with anybody that does not adhere to the guidelines outlined herein. You are encouraged to review these guidelines from time to time to ensure your adherence and compliance.</span></p>
<p>&nbsp;</p>

             </v-layout>
           </v-card>
         </v-dialog>

      </v-layout>
    </v-slide-y-transition>

    <v-snackbar
      :timeout="4000" :bottom="'bottom'" v-model="snackbar"
    >
      <v-icon color="green" style="margin-right:10px">mdi-check-circle-outline</v-icon>
      <span style="padding-left:10px">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#388E3C"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import store from '@/store/index'
import firebase from 'firebase/app'
import { db } from '@/main'
var resizebase64 = require('resize-base64')
const dateformat = require('dateformat')
export default {
  name: 'tokenComments',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    tokenItem: Object
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      showBottomNav: false,
      codeOfConductDialog: false,
      flagDialog: false,
      signUpView: 1,
      installMM: false,
      carousel: 0,
      count: 0,
      flagItem: [],
      now: dateformat(new Date().getTime(), 'dd mmm, yyyy'),
      show: false,
      resultInterval: null,
      tokenComments: [],
      comment: '',
      firstReply: true,
      replyComment: '',
      replyKeepOpenId: '',
      valid: false
    }
  },
  components: {
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getUser () {
      return this.$store.state.user
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
    this.loading = true
    // this.tokenComments = []
  },
  mounted () {
  // this.viewWidth = Math.round(this.windowWidth / 2)
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    this.currentUser = firebase.auth().currentUser
    this.init()
  },
  methods: {
    init () {
      // console.log('getUser.myTeams')
      // console.log(this.getUser.myTeams)
      this.getComments()
    },
    checkNew () {
      // console.log('Check new token opened 2...')
      this.getComments()
    },
    setLoadingTrue () {
      this.loading = true
    },
    getComments () {
      this.loading = true
      // console.log('Get token comments...')
      this.tokenComments = []
      let commentQuery = db.collection('tokens').doc(this.tokenItem.id).collection('comments').where('status', '==', 2).orderBy('created', 'desc')
      this.commentsRef = commentQuery.onSnapshot(querySnapshot => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`)
        if (querySnapshot.empty) {
          console.log('No matching documents.')
          this.loadingData = false
          this.loading = false
          return
        }
        this.tokenComments = []
        querySnapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          var obj = doc.data()
          obj.id = doc.id
          obj.pid = doc.data().pid === undefined ? '0' : doc.data().pid
          if (obj.pid === '0') {
            obj.replies = []
            obj.first = true
            if (this.replyKeepOpenId === obj.id) {
              obj.open = true
            } else {
              obj.open = false
            }
          }
          this.tokenComments.push(obj)
          this.loading = false
        })
        for (var r in this.tokenComments) {
          let pid = this.tokenComments[r].pid
          if (this.tokenComments[r].pid !== '0') {
            var idx = this.tokenComments.findIndex(item => item.id == pid)
            // console.log(idx)
            // console.log(this.tokenComments[r])
            this.tokenComments[idx].replies.push(this.tokenComments[r])
            this.tokenComments[idx].first = false
          }
        }
        this.loadingData = false
        this.loading = false
        // console.log(this.tokenComments)
        // ...
        }, err => {
          console.log(`Encountered error: ${err}`)
          // this.loadingData = false
        })
    },
    lookupReply (pid) {
      if (pid === '0') {
        return false
      } else {
        return true
      }
    },
    openReplies (idx) {
      this.tokenComments[idx].open = !this.tokenComments[idx].open
    },
    connectWallet() {
      this.$emit('connectWalletClicked')
    },
    postCommentClicked (e) {
      // console.log('search')
      // console.log(e)
      if (this.comment === '') return
      if (e === 'click') {
        this.postForumComment()
        return
      }
      if (e.keyCode === 13 && this.comment !== '') {
        // alert('Enter was pressed')
        this.postForumComment()
        return
      }
    },
    postForumComment () {
      var resizedAvatar
      if (this.getUser.avatar !== '') {
        resizedAvatar = resizebase64(this.getUser.avatar, 200, 200)
      } else {
        resizedAvatar = this.getUser.avatar
      }
      var payload = {
        postkey: this.tokenItem.id,
        comment: this.comment,
        name: this.getUser.displayName,
        uid: this.getUser.uid,
        pid: '0',
        avatar: resizedAvatar
      }
      this.$store.dispatch('postForumComment', payload)
        .then(() => {
          // this.$emit('updatedex', type)
          this.comment = ''
          this.snackbarText = 'Your Comment has been posted.'
          this.snackbar = true
          this.comment = ''
          // this.replyKeepOpenId = ''
          this.increaseCommentCount(this.tokenItem.id)
        }).catch(error => {
          console.log(error)
          // this.addCollection = false
        })
    },
    replyCommentClicked (e, parentId, reply) {
      // console.log(e)
      // console.log(parentId)
      if (e === 'click') {
        this.replyComment = reply

        if (this.replyComment === '') return

        this.replyKeepOpenId = parentId
        this.replyForumComment(parentId)
        return
      }
      if (e.keyCode === 13) {
        this.replyComment = e.target.value

        if (this.replyComment === '') return

        this.replyKeepOpenId = e.target.id
        this.replyForumComment(e.target.id)
        return
      }
    },
    replyForumComment (parentId) {
      var resizedAvatar
      if (this.getUser.avatar !== '') {
        resizedAvatar = resizebase64(this.getUser.avatar, 200, 200)
      } else {
        resizedAvatar = this.getUser.avatar
      }
      var payload = {
        postkey: this.tokenItem.id,
        comment: this.replyComment,
        name: this.getUser.displayName,
        uid: this.getUser.uid,
        pid: parentId,
        avatar: resizedAvatar
      }
      this.$store.dispatch('postForumComment', payload)
        .then(() => {
          // this.$emit('updatedex', type)
          this.comment = ''
          this.snackbarText = 'Your Reply has been posted.'
          this.snackbar = true
          this.replyComment = ''
          this.increaseCommentCount(this.tokenItem.id)
        }).catch(error => {
          console.log(error)
          // this.addCollection = false
        })
    },
    increaseCommentCount(docId) {
      let obj =  {
        comment_count: this.tokenItem.comment_count + 1,
        comment_last: new Date().getTime()
      }
      this.tokenItem.comment_count += 1
      // console.log('######### increaseCommentCount ############')
      // console.log(docId)
      // console.log(obj)
      db.collection('tokens').doc(docId).update(obj)
        .then(() => {
            // console.log('Token comment_count in bucket updated')
          })
        .catch(error => {
            console.log(error)
          })
    },
    flagComment (item) {
      // Flag comment
      this.flagItem = item
      // console.log(this.flagItem)
      // console.log(this.tokenItem)
      this.flagDialog = true
    },
    processFlagging () {
      let obj =  {
        status: 2,
        flagged: true,
        flagged_by: this.currentUser.uid,
        flagged_date: new Date().getTime()
      }
      db.collection('tokens').doc(this.tokenItem.id).collection('comments').doc(this.flagItem.id).update(obj)
        .then(() => {
            // console.log('Comment in bucket updated')
            // Snackbar That confirms
            this.snackbar = true
            this.snackbarText = 'This comment has been flagged and will be reviewd!'
            this.flagDialog = false
          })
        .catch(error => {
            console.log(error)
          })
    },
    codeOfConductClicked () {
      this.codeOfConductDialog = true
    },
    makeDate (date) {
      return dateformat(new Date(date), 'dd mmm, yyyy HH:MM')
    },
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e);
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
  .titleText {
    font-size:28px;
    font-weight: 900;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
  .my-text-field-1 .v-input__append-inner .v-icon {
    color: #388E3C;
  }
</style>
