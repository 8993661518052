<template>
  <div id="templatename" >
    <v-slide-y-transition mode="out-in">
      <v-layout column :style="windowWidth < 768 ? '' : 'margin-left:25%;margin-right:25%'">
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column v-if="loading">
         <p class="mt-2"></p>

          <v-skeleton-loader
            class="mx-auto"
            :width="windowWidth < 800 ? '95%' : '100%'"
            height="160"
            type="card"
            :dark="dark"
          ></v-skeleton-loader>

          <p class="mt-4"></p>

          <v-skeleton-loader
            class="mx-auto"
            :width="windowWidth < 800 ? '95%' : '100%'"
            height="100"
            type="card"
            :dark="dark"
          ></v-skeleton-loader>

          <p class="mt-0"></p>

          <v-layout :column="windowWidth < 770">
            <v-skeleton-loader
              class="mx-auto"
              :width="windowWidth < 770 ? '95%' : '49%'"
              :height="windowWidth < 770 ? '100' : '120'"
              type="image"
            ></v-skeleton-loader>
            <v-spacer v-if="windowWidth > 770"></v-spacer>
            <p class="mt-0" v-if="windowWidth < 770"></p>
            <v-skeleton-loader
              class="mx-auto"
              :width="windowWidth < 770 ? '95%' : '49%'"
              :height="windowWidth < 770 ? '100' : '120'"
              type="image"
            ></v-skeleton-loader>
        </v-layout>

        <v-layout column style="width:100%">
          <p class="mt-2"></p>
          <v-spacer></v-spacer>
            <template v-for="n in 5" >
              <v-skeleton-loader
                class="mx-auto"
                type="list-item-avatar-two-line"
                width="100%"
                height="60"
                :key="n"
              ></v-skeleton-loader>
              <v-spacer :key="n + 'b'"></v-spacer>
            </template>
          <v-spacer></v-spacer>
        </v-layout >

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else pt-2>
           <!-- v-show="!loadingData" -->
           <p class="mt-0"></p>
          <v-card
            :color="dark ? '#0a210c' : 'green lighten-5'"
            :dark="dark"
            :width="windowWidth < 770 ? '95%' : '100%'"
          >
            <div class="d-flex">

              <v-layout column  v-if="windowWidth < 770" style="width:120px">
                <v-avatar style="border-radius:5px;margin-bottom:10px"
                  class="ma-2"
                  :size="windowWidth < 770 ? 80 : 120"
                  tile
                >
                  <v-img :src="tokenItem.logo" style="opacity:0.8;border-radius:5px;">
                  </v-img>
                </v-avatar>

                <v-btn style="margin-left:35px" :dark="dark" icon v-if="windowWidth < 770" @click="likeThisItem(tokenItem)">
                  <v-icon :dark="dark" left>
                    {{ lookupLiked(tokenItem) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                  </v-icon> {{ tokenItem.likes }}
                </v-btn>
              </v-layout>

              <v-avatar v-else style="border-radius:5px;margin-bottom:10px"
                class="ma-2"
                :size="windowWidth < 770 ? 80 : 120"
                tile
              >
                <v-img :src="tokenItem.logo" style="opacity:0.8;border-radius:5px;">
                </v-img>
              </v-avatar>

              <div>

                <v-layout s>
                  <v-card-title :style="windowWidth < 770 ? 'font-size:14px;padding-bottom: 0px;padding-top:15px;margin-left:-15px;line-height:1.2' : 'font-size:20px;padding-bottom: 0px;padding-top:15px;margin-left:-10px'" class="font-weight-bold">{{ tokenItem.name }} ({{ tokenItem.symbol }})
                  </v-card-title>

                  <v-chip :style="windowWidth < 770 ? 'margin-right:5px;margin-top:14px' : 'margin-right:30px;margin-top:17px'" v-if="tokenItem.promoted"
                    :color="dark ? 'grey darken-2' : 'grey lighten-2'" label :small="windowWidth > 770"
                    :x-small="windowWidth < 770"
                  >
                    Promoted
                  </v-chip>
                </v-layout>

                <v-layout column pt-2 pl-1>

                  <span v-if="tokenItem.contract_address !== ''" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:16px'" class="wrap-text font-weight-medium">
                    {{ tokenItem.network }}: {{ windowWidth > 770 ? tokenItem.contract_address : tokenItem.contract_address.substr(0, 20) + '...' }}
                    <v-icon color="grey" small style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => tokenItem.contract_address"  @success="handleSuccess" @error="handleError">mdi-content-copy</v-icon>
                  </span>

                  <span v-else-if="tokenItem.contract_address === ''" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:16px'" class="wrap-text font-weight-medium">
                    {{ tokenItem.network }}: Contract Address TBA
                  </span>

                  <span v-if="nowList > tokenItem.launch_date" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:15px'" class="wrap-text font-weight-medium">
                    {{ 'Launched: ' +  makeDate(tokenItem.launch_date) }} (UTC)
                  </span>
                  <span v-if="nowList > tokenItem.launch_date" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:15px'" class="wrap-text font-weight-medium">
                    {{ 'Launched: ' +  makeDateLocal(tokenItem.launch_date) }} (Local time)
                  </span>

                  <span v-if="tokenItem.presale && tokenItem.launch_date_presale > nowList" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:15px'" class="wrap-text font-weight-medium">
                    {{ 'Presale Start: ' +  makeDate(tokenItem.launch_date_presale) }} (UTC)
                  </span>
                  <span v-else-if="tokenItem.launch_date > nowList" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:15px'" class="wrap-text font-weight-medium">
                    {{ 'Launch: ' +  makeDate(tokenItem.launch_date) }} (UTC)
                  </span>

                  <span v-if="tokenItem.presale && tokenItem.launch_date_presale > nowList" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:15px'" class="wrap-text font-weight-medium">
                    {{ 'Presale Start: ' +  makeDateLocal(tokenItem.launch_date_presale) }} (Local time)
                  </span>
                  <span v-else-if="tokenItem.launch_date > nowList" :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:15px'" class="wrap-text font-weight-medium">
                    {{ 'Launch: ' +  makeDateLocal(tokenItem.launch_date) }} (Local time)
                  </span>



                </v-layout>

                <v-layout pl-2 pb-2>
                  <v-btn :dark="dark" text v-if="windowWidth > 770">
                    <v-icon :dark="dark" left> <!-- v-on="on" -->
                      mdi-eye-outline
                    </v-icon> {{ tokenItem.views }}
                  </v-btn>
                  <v-btn :dark="dark" text v-if="windowWidth > 770" @click="likeThisItem(tokenItem)">
                    <v-icon :dark="dark" left>
                      {{ lookupLiked(tokenItem) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                    </v-icon> {{ tokenItem.likes }}
                  </v-btn>
                  <v-layout pa-2 column>
                    <span v-if="windowWidth > 770"  class="caption">Added {{ timeDifference(tokenItem.created /  1000) }}</span>
                    </v-layout>
                </v-layout>
              </div>

            </div>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="updateToFavorites"
                  v-if="windowWidth > 800"
                  v-on="on"
                  style="margin-top:20px;margin-right:-15px"
                  :color="inFav(tokenItem.id) ? 'yellow darken-1' : ''"
                  absolute
                  fab
                  top
                  right
                  small
                  icon
                ><v-icon >{{ inFav(tokenItem.id) ? 'mdi-star' :'mdi-star-outline'}}</v-icon>
                </v-btn>
              </template>
              <span>{{ inFav(tokenItem.id) ? 'Remove from Favorites' : 'Add to Favorites' }} </span>
            </v-tooltip>

                <v-btn
                  @click="gotoLink(tokenItem.link_website)"
                  style="margin-right:185px"
                  absolute
                  bottom
                  fab
                  :disabled="tokenItem.link_website === ''"
                  right
                  small
                  :color="dark ? 'grey lighten-1' : 'grey lighten-5'"
                ><v-icon >mdi-web</v-icon>
                </v-btn>

                <v-btn
                  @click="gotoLink(tokenItem.link_telegram)"
                  style="margin-right:135px"
                  absolute
                  bottom
                  fab
                  :disabled="tokenItem.link_telegram === 'https://t.me/' || tokenItem.link_telegram === ''"
                  right
                  small
                  color="#0088cc"
                ><telegram-icon size="25" class="custom-class"></telegram-icon>
                </v-btn>

                <v-btn
                  @click="gotoLink(tokenItem.link_twitter)"
                  style="margin-right:85px"
                  absolute
                  bottom
                  fab
                  :disabled="tokenItem.link_twitter === 'https://twitter.com/' || tokenItem.link_twitter === ''"
                  right
                  small
                  color="light-blue"
                ><v-icon >mdi-twitter</v-icon>
                </v-btn>

                <v-btn
                  @click="gotoLink(tokenItem.link_reddit)"
                  style="margin-right:35px"
                  absolute
                  bottom
                  fab
                  :disabled="tokenItem.link_reddit === ''"
                  right
                  small
                  color="red"
                ><v-icon >mdi-reddit</v-icon>
                </v-btn>

                <v-btn
                  @click="gotoLink(tokenItem.link_discord)"
                  style="margin-right:-15px"
                  absolute
                  bottom
                  fab
                  :disabled="tokenItem.link_discord === ''"
                  right
                  small
                  color="#5563ea"
                ><v-icon >mdi-discord</v-icon>
                </v-btn>
          </v-card>

          <p class="mt-4"></p>

          <v-card v-if="tokenItem.presale"
            :dark="dark"
            :width="windowWidth < 770 ? '95%' : '100%'"
            :color="dark ? 'yellow darken-3'  : 'yellow accent-4'"
          >
            <v-layout column pa-2>

              <!-- HAS PRESALE -->
              <span :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:16px'" v-if="tokenItem.presale && tokenItem.launch_date_presale > nowList"
                    class="wrap-text font-weight-medium">This Token will have a Presale on: <a  @click.stop="gotoLink(tokenItem.presale_link)">{{ tokenItem.presale_link }}</a>
              </span>

              <!-- PRESALE LIVE -->
              <span :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:16px'" v-else-if="tokenItem.presale && tokenItem.launch_date_presale < nowList && tokenItem.launch_date > nowList"
                    class="wrap-text font-weight-medium">Presale is Live on: <a  @click.stop="gotoLink(tokenItem.presale_link)">{{ tokenItem.presale_link }}</a>
              </span>

              <span :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:16px'" v-else
                    class="wrap-text font-weight-medium">This Token had a Presale on: <a  @click.stop="gotoLink(tokenItem.presale_link)">{{ tokenItem.presale_link }}</a>
              </span>
            </v-layout>
          </v-card>

          <p class="mt-2" v-if="tokenItem.presale"></p>

          <v-card v-if="tokenItem.launch_date > nowList"
            :dark="dark"
            :width="windowWidth < 770 ? '95%' : '100%'"
            :color="dark ? '#0a210c' : 'green lighten-5'"
          >
            <v-layout column align-center pa-2>
              <vue-countdown v-if="tokenItem.presale && tokenItem.launch_date_presale > nowList" :time="tokenItem.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                <v-icon small style="margin-right:5px">mdi-clock-start</v-icon>Presale Starts in: {{ days === 0 ? '' : days + ' Days' }} {{ hours === 0 ? '' : hours + ' Hrs.' }} {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}
              </vue-countdown>
              <vue-countdown :time="tokenItem.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                <v-icon small style="margin-right:5px">mdi-rocket-launch</v-icon>Launching in: {{ days === 0 ? '' : days + ' Days' }} {{ hours === 0 ? '' : hours + ' Hrs.' }} {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}
              </vue-countdown>
            </v-layout>
          </v-card>

          <p class="mt-2" v-if="tokenItem.launch_date > nowList"></p>

          <v-card
            :style="dark ? 'border:#388E3C solid 2px' : 'border:#a8ad8a solid 2px'"
            :dark="dark"
            :width="windowWidth < 770 ? '95%' : '100%'"
            outlined
          >
            <v-layout column pa-2>
              <span :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:16px'" v-html="tokenItem.description"
                    class="wrap-text font-weight-medium">
              </span>
            </v-layout>
          </v-card>

          <p class="mt-1"></p>

          <v-card v-if="mmConnected || binanceConnected || walletConnected"
            :dark="dark"
            :width="windowWidth < 770 ? '95%' : '100%'"
            :color="dark ? '#0a210c' : 'green lighten-5'"
          >
            <v-layout column align-center pa-2
                      v-if="(this.getChain === '0x38') || (this.getChain === '0x1') || (this.getChain === '0x89') || (this.getChain === '0x141')">
              <span>My Balance: <strong>{{ tokenBalanceText }}</strong> {{ tokenItem.symbol }}</span>
              <span><strong>${{ valueUSD }}</strong> {{ tokenBalance > 0 && tokenItem.price === 0 ? 'Latest ' + tokenItem.symbol + ' Price not Available' : '' }}</span>
            </v-layout>
            <v-layout column align-center pa-2 v-else>
              To see your {{ tokenItem.symbol }} balance, please connect to the {{ tokenItem.network }} network.
              <p class="mt-0"></p>

              <v-btn v-if="tokenItem.network === 'ETH'" style="text-transform: none !important" color="green lighten-3"  @click="addETHNetwork">
                <v-icon left >mdi-swap-horizontal</v-icon>Switch to Ethereum Mainnet
              </v-btn>

              <v-btn v-if="tokenItem.network === 'BSC'" style="text-transform: none !important" color="green lighten-3"  @click="addBSCNetwork">
                <v-icon left >mdi-swap-horizontal</v-icon>Switch to BSC Mainnet
              </v-btn>

              <v-btn v-else-if="tokenItem.network === 'MATIC'" style="text-transform: none !important" color="green lighten-3"  @click="addMaticNetwork">
                <v-icon left >mdi-swap-horizontal</v-icon>Switch to Polygon (MATIC) Network
              </v-btn>

              <v-btn v-else-if="tokenItem.network === 'KCC'" style="text-transform: none !important" color="green lighten-3"  @click="addKCCNetwork">
                <v-icon left >mdi-swap-horizontal</v-icon>Switch to KuCoin (KCC) Network
              </v-btn>

            </v-layout>
          </v-card>

          <p class="mt-2" v-if="mmConnected || binanceConnected || walletConnected"></p>

          <v-layout row wrap :style="windowWidth < 770 ? 'padding:8px;width:100%' : 'width:100%'">

            <!-- ################## Token Info Card ################## -->

            <v-col :style="windowWidth < 770 ? 'padding-left:0px;padding-right:0px' : 'padding-left:0px'"
               cols="12"
               sm="6"
             >
             <v-hover
               v-slot:default="{ hover }"
               open-delay="200"
             >
               <v-card style="width:100%"
                 :elevation="hover ? 16 : 2"
                 class="mx-auto"
                 :dark="dark"
                 :color="dark ? '#272727' : '#fbfdf3'"
                 height="180"
               >
               <v-layout column pt-2 pb-2>
                 <v-layout>
                   <span style="padding-left:10px;padding-right:10px;font-size:14px" class="font-weight-bold mt-2">
                     Total Supply: {{ numberWithCommas(tokenItem.total_supply) }}
                   </span>
                   <v-spacer></v-spacer>
                   <v-icon v-if="tokenItem.network === 'ETH'" color="blue-grey lighten-1" style="margin-right:10px">
                     mdi-ethereum
                   </v-icon>
                   <v-img v-if="tokenItem.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;margin-right:10px">
                   </v-img>
                   <v-img v-if="tokenItem.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;margin-right:10px">
                   </v-img>
                   <v-img v-if="tokenItem.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;margin-right:10px">
                   </v-img>
                 </v-layout>
                 <span style="padding-left:10px;padding-right:10px;font-size:14px" class="font-weight-bold mt-2">
                   Total Burned: {{ tokenItem.total_burned }}%
                 </span>
                 <span style="padding-left:10px;padding-right:10px;font-size:14px" class="font-weight-bold mt-2">
                   Latest Price {{tokenItem.network === 'BSC' ? 'BNB' : tokenItem.network === 'ETH' ? 'ETH' : tokenItem.network === 'MATIC' ? 'MATIC' : tokenItem.network === 'KCC' ? 'USD' : '' }}: {{ eToNumber(tokenItem.price_text) }}
                 </span>
                 <span style="padding-left:10px;padding-right:10px;font-size:14px" class="font-weight-bold mt-2">
                   Market Cap: ${{ numberWithCommas(tokenItem.mc) }}
                 </span>
                 <span style="padding-left:10px;padding-right:10px;font-size:14px" class="font-weight-bold mt-2">
                   Total Holders: {{ numberWithCommas(tokenItem.total_holders) }}
                 </span>
               </v-layout>

               </v-card>
             </v-hover>
           </v-col>

           <!-- ################## Token Links Card ################## -->

           <v-col :style="windowWidth < 770 ? 'padding-left:0px;padding-right:0px' : 'padding-right:0px'"
              cols="12"
              sm="6"
            >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card
                  :elevation="hover ? 16 : 2"
                  class="mx-auto"
                  :color="dark ? '#272727' : '#fbfdf3'"
                  :dark="dark"
                  height="180"
                >

                <v-layout column pa-4 v-if="tokenItem.network === 'BSC' && tokenItem.contract_address !== ''">
                  <v-btn text style="text-transform: none !important;width:180px;padding-left: 5px;" @click="gotoLink('https://exchange.pancakeswap.finance/#/swap?outputCurrency=' + tokenItem.contract_address)">
                    <v-img :src="srcPancake" max-width="28" max-height="28"></v-img><span style="margin-left:10px">Pancakeswap</span>
                  </v-btn>
                  <v-btn text style="text-transform: none !important;width:180px" @click="gotoLink('https://poocoin.app/tokens/' + tokenItem.contract_address)">
                    <v-img :src="srcPoocoin" max-width="28" max-height="28"></v-img><span style="margin-left:10px">Poocoin Chart</span>
                  </v-btn>
                  <v-btn text style="text-transform: none !important;width:210px;padding-left: 15px;margin-bottom:4px" @click="gotoLink('https://bscscan.com/address/' + tokenItem.contract_address)">
                    <v-img :src="dark ? srcBscDark : srcBsc" max-width="28" max-height="28"></v-img><span style="margin-left:10px">BscScan Contract</span>
                  </v-btn>
                  <v-btn text style="text-transform: none !important;width:197px;padding-left: 5px;" @click="gotoLink('https://dextools.io/app/pancakeswap/pair-explorer/' + tokenItem.contract_address)">
                    <v-img :src="dark ? srcDextDark : srcDext" max-width="32" max-height="32"></v-img><span style="margin-left:10px">Dextools Chart</span>
                  </v-btn>
                </v-layout>


                <v-layout column pa-4 v-if="tokenItem.network === 'ETH' && tokenItem.contract_address !== ''">
                  <v-btn text style="text-transform: none !important;width:142px;padding-left: 5px;" @click="gotoLink('https://app.uniswap.org//#/swap?inputCurrency=' + tokenItem.contract_address)">
                    <v-img :src="dark ? srcUniswapDark : srcUniswap" max-width="32" max-height="32"></v-img><span style="margin-left:10px">Uniswap</span>
                  </v-btn>

                  <v-btn text style="text-transform: none !important;width:197px;padding-left: 5px;" @click="gotoLink('https://dextools.io/app/uniswap/pair-explorer/' + tokenItem.contract_address)">
                    <v-img :src="dark ? srcDextDark : srcDext" max-width="32" max-height="32"></v-img><span style="margin-left:10px">Dextools Chart</span>
                  </v-btn>

                  <v-btn text style="text-transform: none !important;width:230px;padding-left: 15px;margin-bottom:4px" @click="gotoLink('https://etherscan.io//address/' + tokenItem.contract_address)">
                    <v-img :src="dark ? srcEthDark: srcEth" max-width="28" max-height="28"></v-img><span style="margin-left:10px">EtherScan Contract</span>
                  </v-btn>
                </v-layout>

                <v-layout column pa-4 v-if="tokenItem.network === 'MATIC' && tokenItem.contract_address !== ''">
                  <v-btn text style="text-transform: none !important;width:160px;padding-left: 5px;" @click="gotoLink('https://quickswap.exchange/#/swap?outputCurrency=' + tokenItem.contract_address)">
                    <v-img :src="srcQuickSwap" max-width="28" max-height="28"></v-img><span style="margin-left:10px">Quickswap</span>
                  </v-btn>
                  <v-btn text style="text-transform: none !important;width:180px" @click="gotoLink('https://polygon.poocoin.app/tokens/' + tokenItem.contract_address)">
                    <v-img :src="srcPoocoin" max-width="28" max-height="28"></v-img><span style="margin-left:10px">Poocoin Chart</span>
                  </v-btn>
                  <v-btn text style="text-transform: none !important;width:245px;padding-left: 15px;margin-bottom:4px" @click="gotoLink('https://polygonscan.com/address/' + tokenItem.contract_address)">
                    <v-img :src="srcPolygon" max-width="28" max-height="28"></v-img><span style="margin-left:10px">PolygonScan Contract</span>
                  </v-btn>
                </v-layout>

                <v-layout column pa-4 v-if="tokenItem.network === 'KCC' && tokenItem.contract_address !== ''">
                  <v-btn text style="text-transform: none !important;width:150px;padding-left: 5px;" @click="gotoLink('https://kuswap.finance/#/swap?outputCurrency=' + tokenItem.contract_address)">
                    <v-img :src="srcKuSwap" max-width="28" max-height="28"></v-img><span style="margin-left:10px">KuSwap</span>
                  </v-btn>

                  <v-btn text style="text-transform: none !important;width:180px;padding-left: 5px;" @click="gotoLink('https://koffeeswap.exchange/#/pro/KCS/' + tokenItem.contract_address)">
                    <v-img :src="srcKoffeeSwap" max-width="28" max-height="28"></v-img><span style="margin-left:10px">KoffeeSwap</span>
                  </v-btn>

                  <v-btn text style="text-transform: none !important;width:197px;padding-left: 5px;" @click="gotoLink('https://explorer.kcc.io/en/token/' + tokenItem.contract_address)">
                    <v-img :src="srcKccExplorer" max-width="28" max-height="28"></v-img><span style="margin-left:10px">KCC Explorer</span>
                  </v-btn>
                </v-layout>

                <v-layout column pa-4 v-if="tokenItem.contract_address === ''">
                  <p>The contract has not been deployed, or the admins/devs have not updated the contract here yet.</p>
                  <v-btn text color="red darken-1" style="text-transform: none !important;width:280px;height:60px;padding-left:5px;margin-bottom:4px">
                    <v-icon color="yellow accent-4" large>mdi-alert-outline</v-icon><span >Please, be aware of scams<br>and shadow contracts,<br> make sure to DYOR.</span>
                  </v-btn>
                </v-layout>

                </v-card>
              </v-hover>
            </v-col>
          </v-layout>

          <p class="mt-1"></p>

          <v-layout pt-1 pr-2 pl-2>

            <v-btn-toggle
               v-model="toggleView"
               borderless
               color="#388E3C"
             >
               <v-btn value="discussion" ><v-icon v-if="windowWidth > 770" left>mdi-forum-outline</v-icon>
                 <span :style="windowWidth < 770 ? 'font-size:12px;text-transform: none !important' : 'font-size:16px;text-transform: none !important'">Discussion</span>
               </v-btn>

               <v-btn value="scam"><v-icon v-if="windowWidth > 770" left style="margin-right:20px;">mdi-alert-outline</v-icon>
                 <v-badge style="margin-right:10px;margin-top:2px"
                    :color="tokenItem.scam_count === 0 ? 'green' : tokenItem.scam_count < 4 ? 'orange' : 'red'"
                    :content="tokenItem.scam_count === 0 ? '0' : tokenItem.scam_count"
                  >
                    <span :style="windowWidth < 770 ? 'font-size:12px;text-transform: none !important' : 'font-size:16px;text-transform: none !important'">
                      Scam Reports
                    </span>
                  </v-badge>
               </v-btn>

               <v-btn value="vetting"><v-icon v-if="windowWidth > 770" left>mdi-list-status</v-icon>
                 <span :style="windowWidth < 770 ? 'font-size:12px;text-transform: none !important' : 'font-size:16px;text-transform: none !important'">Vetting Score</span>
               </v-btn>

             </v-btn-toggle>

          </v-layout>

          <TokenComments v-if="toggleView === 'discussion'" style="width:100%" ref="commentComp" :windowWidth="windowWidth" :windowHeight="windowHeight"
                         :tokenItem="tokenItem" v-on:connectWalletClicked="connectWallet">
          </TokenComments>

          <TokenScamReports v-if="toggleView === 'scam'" style="width:100%" ref="scamComp" :windowWidth="windowWidth" :windowHeight="windowHeight"
                         :tokenItem="tokenItem" v-on:connectWalletClicked="connectWallet">
          </TokenScamReports>

          <TokenVetting v-if="toggleView === 'vetting'" style="width:100%" ref="vettingComp" :windowWidth="windowWidth" :windowHeight="windowHeight"
                         :tokenItem="tokenItem" v-on:connectWalletClicked="connectWallet">
          </TokenVetting>

          <p class="mt-2"></p>

          <v-bottom-navigation v-if="showBottomNav"
            :input-value="showBottomNav"
            color="teal accent-4"
            background-color="teal lighten-5"
            fixed
          >

          </v-bottom-navigation>

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

         <v-snackbar :timeout="4000" bottom v-model="snackbarFavAdd">
            <v-icon color="green darken-3">mdi-check-circle-outline</v-icon>
            <span style="margin-left:5px">{{ snackbarText }}</span>
            <v-spacer></v-spacer>
          </v-snackbar>

          <v-snackbar :timeout="4000" bottom v-model="snackbarFavRemove">
            <v-icon color="amber darken-4">mdi-trash-can-outline</v-icon>
            <span style="margin-left:5px">{{ snackbarText }}</span>
            <v-spacer></v-spacer>
          </v-snackbar>

          <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
            <v-icon color="green">mdi-check-circle-outline</v-icon>
            <span style="margin-left:5px">{{ snackbarText }}</span>
                  <v-btn text color="#388E3C" @click.native="snackbar = false">Close</v-btn>
          </v-snackbar>

          <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
            <v-icon color="amber">mdi-alert-outline</v-icon>
            <span style="padding-left:10px">{{ snackbarText }}</span>
            <v-spacer></v-spacer>
          </v-snackbar>

      </v-layout>
    </v-slide-y-transition>
  </div>
</template>

<script>
import store from '@/store/index'
import { db } from '@/main'
import { TelegramIcon } from 'vue-simple-icons'
import TokenComments from './TokenComments'
import TokenScamReports from './TokenScamReports'
import TokenVetting from './TokenVetting'
import TokenMonsterAPI from '@/clients/TokenMonsterAPI'
import VueCountdown from '@chenfengyuan/vue-countdown'
const dateformat = require('dateformat')
export default {
  name: 'tokenDetail',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    tokenItem: Object
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      showBottomNav: false,
      snackbar: false,
      snackbarFavAdd: false,
      snackbarFavRemove: false,
      snackbarWarning: false,
      snackbarText: '',
      signUpDialog: false,
      videoDialog: false,
      signUpView: 1,
      installMM: false,
      nowList: new Date().getTime(),
      toggleView: 'discussion',
      count: 0,
      show: false,
      startDelay1: 5000,
      startDelay2: 5000,
      resultInterval: null,
      countDown: 8,
      toggle: 'left',
      srcPancake: '',
      srcPoocoin: '',
      srcBsc: '',
      srcBscDark: '',
      srcEth: '',
      srcEthDark: '',
      srcUniswap: '',
      srcUniswapDark: '',
      srcDext: '',
      srcDextDark: '',
      srcPolygon: '',
      srcQuickSwap: '',
      srcKuSwap: '',
      srcKoffeeSwap: '',
      srcKccExplorer: '',
      likeArr: [],
      tokenBalance: 0,
      valueUSD: 0,
      tokenBalanceText: ''
    }
  },
  components: {
    TokenComments,
    TokenScamReports,
    TokenVetting,
    VueCountdown,
    TelegramIcon
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
    },
    mmConnected () {
      if (!this.mmConnected && !this.binanceConnected) {
        this.tokenBalanceText = 0
        this.tokenBalance = 0
      } else {
        this.getTokenBalance()
      }
    },
    binanceConnected () {
      if (!this.mmConnected && !this.binanceConnected) {
        this.tokenBalanceText = 0
        this.tokenBalance = 0
      } else {
        this.getTokenBalance()
      }
    },
    getChain () {
      this.getTokenBalance()
    },
    getAccounts () {
      // console.log('accounts changed in Token Detail')
      // console.log(this.getAccounts)
      this.getTokenBalance()
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    },
    getChain () {
      return this.$store.state.user.networkChainID
    },
    getAccounts () {
      return this.$store.state.user.accounts
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getLatestBNB () {
      return this.$store.state.latestBNB
    },
    getLatestETH () {
      return this.$store.state.latestETH
    },
    getLatestMATIC () {
      return this.$store.state.latestMATIC
    },
    getLatestKCS () {
      return this.$store.state.latestKCS
    },
    getUser () {
      return this.$store.state.user
    },
    getLikes () {
      return this.$store.state.user.likes
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
    this.loading = true
    // this.$refs.commentComp.setLoadingTrue()
  },
  mounted () {
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    // console.log(this.getPlatform)
    this.srcPancake = './img/icons/pancakeswap.png'
    this.srcPoocoin = './img/icons/poocoin.png'
    this.srcBsc = './img/icons/bscscan_light.png'
    this.srcBscDark = './img/icons/bscscan_dark.png'
    this.srcEth = './img/icons/etherscan_light.png'
    this.srcEthDark = './img/icons/etherscan_dark.png'
    this.srcUniswap = './img/icons/uniswap_light.png'
    this.srcUniswapDark = './img/icons/uniswap_dark.png'
    this.srcDext = './img/icons/dext_light.png'
    this.srcDextDark = './img/icons/dext_dark.png'
    this.srcPolygon = './img/icons/polygon.png'
    this.srcQuickSwap = './img/icons/quickswap.png'
    this.srcKuSwap = './img/icons/kuswap.png'
    this.srcKoffeeSwap = './img/icons/koffeeswap.png'
    this.srcKccExplorer = './img/icons/kccexplorer.png'
    this.init()
  },
  methods: {
    init () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
      setTimeout(() => {
        this.loading = false
      }, 2000)
      // console.log('getPriceQuote 0')
      if (this.tokenItem.price === 0 && this.tokenItem.mc === 0) {
        console.log('getPriceQuote 1')
        this.getTokenPriceQuote()
      }
      this.tokenBalanceText = '0'
      if (this.getAccounts.length > 0) {
        console.log('########### this.getTokenBalance() ############')
        this.getTokenBalance()
      }
    },
    getTokenBalance () {
      // https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=0xe9e7cea3dedca5984780bafc599bd69add087d56&address=0x89e73303049ee32919903c09e8de5629b84f59eb&tag=latest&apikey=YourApiKeyToken
      this.tokenBalanceText = 0
      this.tokenBalance = 0
      this.valueUSD = 0
      let tokenAddress = this.tokenItem.contract_address
      let walletAddress = this.getAccounts[0]
      let network = this.tokenItem.network
      var decimals = parseInt(this.tokenItem.total_decimals)
      Promise.resolve(TokenMonsterAPI.getTokenBalance(tokenAddress, walletAddress, network))
      .then(result => {
        // console.log(result)
        var balance = 0
        var tempBalance = 0
        var tokenArr = []
        if (network === 'KCC') {
          if (result.data.data.code === 1) {
            tokenArr = result.data.data.data
            var token  = tokenArr.filter(item => item.hash === tokenAddress)[0]
            balance = token.balance
            var dec = parseInt(token.tokenInfo.d)
            this.tokenBalance = balance / (10**dec)
            tempBalance = this.tokenBalance.toString()
            this.valueUSD = Math.round((this.tokenItem.price) * this.tokenBalance * 100) / 100
          } else {
            balance = 0
            tempBalance = 0
          }

        } else {
          var latestPrice = this.tokenItem.network === 'BSC' ? this.getLatestBNB : this.tokenItem.network === 'ETH' ? this.getLatestETH : this.tokenItem.network === 'MATIC' ? this.getLatestMATIC : 0
          balance = result.data.data.result
          this.tokenBalance = parseInt(balance) / (10**decimals)
          tempBalance = this.tokenBalance.toString()
          this.valueUSD = Math.round((this.tokenItem.price * latestPrice) * this.tokenBalance * 100) / 100
        }
        // console.log('tempBalance')
        // console.log(tempBalance)
        if (this.tokenBalance > 0) {
          var decDot = tempBalance.split('.')[1] === undefined ? '' : tempBalance.split('.')[1]
          this.tokenBalanceText = this.numberWithCommas(tempBalance.split('.')[0]) + '.' + decDot
        }
      })
      .catch(err => {
        // this.loading = false
        console.log('Error getting Token Balance.', err)
        // show friendly error in user screen
      })
    },
    getTokenPriceQuote () {
      console.log('getPriceQuote 2')

      if (this.tokenItem.network === 'KCC') {
        Promise.resolve(TokenMonsterAPI.getTokenInfoKCC(this.tokenItem.contract_address, this.tokenItem.network))
        .then(result => {
          // console.log(result) // result.data.data.data.tokenInfo
          // result.data.data.data.holderCnt
          // var tokenInfo
          var holders = 0
          if (result.data.data.code === 1) {

            // tokenInfo = result.data.data.data.tokenInfo
            // this.tokenName = tokenInfo.f
            // this.tokenSymbol = tokenInfo.s
            // this.decimals = parseInt(tokenInfo.d)
            // this.totalSupply = parseInt(tokenInfo.t)
            holders = result.data.data.data.holderCnt
            this.tokenItem.total_holders = parseInt(holders)
            this.tokenItem.holders = parseInt(holders)

          } else {
            this.tokenItem.total_holders = '-'
            this.tokenItem.holders = '-'
          }
        })
        .catch(err => {
          this.loading = false
          console.log('Error getting KCC token info.', err)
          // show friendly error in user screen
        })

        //  https://api.coingecko.com/api/v3/simple/token_price/kucoin-community-chain?contract_addresses=0x4a81704d8c16d9fb0d7f61b747d0b5a272badf14&vs_currencies=USD%2CKCS&include_market_cap=true
        Promise.resolve(TokenMonsterAPI.getKCCPriceMc(this.tokenItem.contract_address, this.tokenItem.network))
        .then(result => {
          // console.log(result) // result.data.data.data
          // result.data.data.data
          if (result.statusText === 'OK') {

            this.tokenItem.price = parseFloat(result.data.data[this.tokenItem.contract_address].usd)
            this.tokenItem.price_text = result.data.data[this.tokenItem.contract_address].usd
            this.tokenItem.mc = parseInt(result.data.data[this.tokenItem.contract_address].usd_market_cap)
            if (this.tokenItem.mc === 0 && this.tokenItem.price > 0) {
              // console.log('obj.price')
              // console.log(obj.price)
              // console.log('obj.total_supply')
              // console.log(obj.total_supply)
              this.tokenItem.mc = Math.round(this.tokenItem.price * this.tokenItem.total_supply)
            }

          } else {
            this.tokenItem.price = '-'
            this.tokenItem.price_text = 'Not Available'
            this.tokenItem.mc = '-'
          }
        })
        .catch(err => {
          this.loading = false
          console.log('Error getting KCC token info.', err)
          // show friendly error in user screen
        })

      } else {

        Promise.resolve(TokenMonsterAPI.getTokenPriceQuote(this.tokenItem.contract_address, this.tokenItem.network, this.tokenItem.total_supply))
        .then(result => {
          // console.log('result get quote')
          // console.log(result)
          var latestPrice = this.tokenItem.network === 'BSC' ? this.getLatestBNB : this.tokenItem.network === 'ETH' ? this.getLatestETH : this.tokenItem.network === 'MATIC' ? this.getLatestMATIC : 0
          this.tokenItem.price = parseFloat((result.data.data.guaranteedPrice / 100) * 98)
          this.tokenItem.price_text = (result.data.data.guaranteedPrice / 100) * 98
          this.tokenItem.mc = Math.round((this.tokenItem.price * latestPrice) * this.tokenItem.total_supply * ((100 - this.tokenItem.total_burned) / 100))
          /* console.log(this.tokenItem.price * latestPrice)
          console.log('total_burned')
          console.log(100 - this.tokenItem.total_burned)
          console.log('Market Cap')
          console.log(this.tokenItem.mc) */
          // commit('setPopularTokensBNB', obj, index)
        })
        .catch(err => {
          this.loading = false
          console.log('Error getting documents.', err)
          // show friendly error in user screen
        })
      }
    },
    connectWallet() {
      this.$emit('connectWalletClicked')
    },
    checkNewTokenOpened () {
      // console.log('Check new token opened 1...')
      setTimeout(() => {
        this.$refs.commentComp.setLoadingTrue()
      }, 500)
      setTimeout(() => {
        this.$refs.commentComp.checkNew()
      }, 1500)

      setTimeout(() => {
        this.$refs.scamComp.setLoadingTrue()
      }, 500)
      setTimeout(() => {
        this.$refs.scamComp.checkNew()
      }, 1500)
      this.tokenBalanceText = '0'
      if (this.getAccounts.length > 0) {
        console.log('########### this.getTokenBalance() ############')
        this.getTokenBalance()
      }
    },
    checkStoryLength (description) {
      if (description.length < 200) {
        return description
      } else {
        return description.substr(0, 200) + '...'
      }
    },
    checkUrlLength (url) {
      if (url.length < 45) {
        return url
      } else {
        return url.substr(0, 45) + '...'
      }
    },
    updateToFavorites () {
      // console.log(this.getUser.favorites)
      var type
      var favArr = this.getUser.favorites
      if (favArr.includes(this.tokenItem.id)) {
        // console.log('remove from fav')
        type = false
        favArr.splice(favArr.indexOf(this.tokenItem.id), 1)
      } else {
        // console.log('add to fav')
        type = true
        favArr.push(this.tokenItem.id)
      }
      // console.log(this.getUser.favorites)
      var payload = {
        postkey: this.getUser.docId,
        favorites: favArr
      }
      this.$store.dispatch('updateToFavorites', payload)
        .then(() => {
          // this.$emit('updatedex', type)
          if (type) {
            this.snackbarText = 'Added to favorites'
            this.snackbarFavAdd = true
          } else {
            this.snackbarText = 'Removed from favorites'
            this.snackbarFavRemove = true
          }
        }).catch(error => {
          console.log(error)
          // this.addCollection = false
        })
    },
    inFav (id) {
      return this.getUser.favorites.includes(id)
    },
    likeThisItem (item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = 'You have already liked this item'
        this.snackbarWarning = true
        return
      }
      store.commit('SetLikes', item.id)
      this.likeArr.push(item.id)
      let dispatchObj = {
        likes: item.likes += 1
      }
      db.collection('tokens').doc(item.id).update(dispatchObj)
      .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = 'Your like has been recorded'
          this.snackbar = true
        })
      .catch(error => {
          console.log(error)
        })
    },
    lookupLiked (item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1
    },
    async addETHNetwork () {
      if (this.getUser.walletProvider === 'MetaMask') {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x1', // A 0x-prefixed hexadecimal string
                  chainName: 'Ethereum Mainnet',
                  nativeCurrency: {
                    name: 'Ethereum',
                    symbol: 'ETH', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                  blockExplorerUrls:['https://etherscan.io/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        try {
          await window.BinanceChain.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.BinanceChain.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x1', // A 0x-prefixed hexadecimal string
                  chainName: 'Ethereum Mainnet',
                  nativeCurrency: {
                    name: 'Ethereum',
                    symbol: 'ETH', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                  blockExplorerUrls:['https://etherscan.io/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      }
    },
    async addBSCNetwork () {
      if (this.getUser.walletProvider === 'MetaMask') {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x38', // A 0x-prefixed hexadecimal string
                  chainName: 'BSC Mainnet',
                  nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                  blockExplorerUrls:['https://bscscan.com/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        try {
          await window.BinanceChain.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.BinanceChain.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x38', // A 0x-prefixed hexadecimal string
                  chainName: 'BSC Mainnet',
                  nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                  blockExplorerUrls:['https://bscscan.com/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      }
    },
    async addMaticNetwork () {
      if (this.getUser.walletProvider === 'MetaMask') {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x89' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x89', // A 0x-prefixed hexadecimal string
                  chainName: 'Polygon Network',
                  nativeCurrency: {
                    name: 'Matic',
                    symbol: 'MATIC', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
                  blockExplorerUrls:['https://polygonscan.com/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        try {
          await window.BinanceChain.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x89' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.BinanceChain.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x89', // A 0x-prefixed hexadecimal string
                  chainName: 'Polygon Network',
                  nativeCurrency: {
                    name: 'Matic',
                    symbol: 'MATIC', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
                  blockExplorerUrls:['https://polygonscan.com/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      }
    },
    async addKCCNetwork () {
      if (this.getUser.walletProvider === 'MetaMask') {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x141' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x141', // A 0x-prefixed hexadecimal string
                  chainName: 'KCC Network',
                  nativeCurrency: {
                    name: 'KuCoin Token',
                    symbol: 'KCS', // 2-6 characters long
                    decimals: 6,
                  },
                  rpcUrls: ['https://rpc-mainnet.kcc.network'],
                  blockExplorerUrls:['https://explorer.kcc.io/en']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        try {
          await window.BinanceChain.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x141' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.BinanceChain.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x141', // A 0x-prefixed hexadecimal string
                  chainName: 'KCC Network',
                  nativeCurrency: {
                    name: 'KuCoin Token',
                    symbol: 'KCS', // 2-6 characters long
                    decimals: 6,
                  },
                  rpcUrls: ['https://rpc-mainnet.kcc.network'],
                  blockExplorerUrls:['https://explorer.kcc.io/en']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      }
    },
    gotoLink (link) {
      window.open(link, '_blank')
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = 'Contract Address copied.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    },
    makeDate (date) {
      return dateformat(new Date(date), 'UTC:dd mmmm, yyyy - HH:MM')
    },
    makeDateLocal (date) {
      return dateformat(new Date(date), 'dd mmmm, yyyy - HH:MM')
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    eToNumber(num) {
      let sign = "";
      (num += "").charAt(0) == "-" && (num = num.substring(1), sign = "-")
      let arr = num.split(/[e]/ig)
      if (arr.length < 2) return sign + num
      let dot = (.1).toLocaleString().substr(1, 1), n = arr[0], exp = +arr[1]
      let w = (n = n.replace(/^0+/, '')).replace(dot, ''),
        pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp,
        L = pos - w.length, s = "" + BigInt(w)
      w = exp >= 0 ? (L >= 0 ? s + "0".repeat(L) : r()) : (pos <= 0 ? "0" + dot + "0".repeat(Math.abs(pos)) + s : r())
      if (!+w) w = 0; return sign + w
      function r() {return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`)}
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
</style>
