<template>
  <v-container pa-0 fluid>
    <v-sheet
      class="mx-auto"
      max-width="100%"
      tile
      :color="dark ? '#312e2e' : 'grey lighten-3' "
      :dark="dark"
      style="width: 100%;height: auto"
    >
    <v-layout column  :style="windowWidth < 800 ? '' : 'margin-right:80px;margin-left:80px'">
        <p style="margin-top:80px"></p>
        <p class="sectionHeader text-center">Contact Information and Useful Links</p>
        <p class="mt-2"></p>
        <p class="text-center">Learn more about Token Monster, chat with the team and the community.<br>Have your say in shaping the future.</p>
        <p class="mt-4"></p>
        <p class="mt-4"></p>
        <v-row style="margin-top: 10px;">

          <v-col
             cols="12"
             sm="3"
           >
             <v-hover
               v-slot:default="{ hover }"
               open-delay="200"
             >
               <v-card
                 :elevation="hover ? 16 : 2"
                 class="mx-auto"
                 height="200"
                 max-width="350"
                 :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
               >
                 <v-layout column align-center pa-4>
                   <p style="padding-left:10px;padding-right:10px;font-size:16px" class="text-center font-weight-medium mt-2">
                     Email us for any inquiry or questions. <br>We are happy to answer!
                   </p>

                   <v-layout column align-center >
                     <v-btn icon style="font-size:13px;text-transform: none !important" class="font-weight-medium"
                           @click="openLink('mailto:hello@tokenmonster.app')"><v-icon  size="60" >mdi-email</v-icon></v-btn>
                     <v-btn text style="font-size:15px;text-transform: none !important" class="font-weight-medium"
                           @click="openLink('mailto:hello@tokenmonster.app')">hello@tokenmonster.app
                     </v-btn>
                   </v-layout>
                 </v-layout>
               </v-card>
             </v-hover>
           </v-col>

           <v-col
              cols="12"
              sm="3"
            >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card
                  :elevation="hover ? 16 : 2"
                  class="mx-auto"
                  height="200"
                  max-width="350"
                  :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
                >
                 <v-layout column align-center pa-4>
                   <p style="padding-left:10px;padding-right:10px;font-size:16px" class="text-center font-weight-medium mt-2">
                     Chat in real-time with the<br>Token Monster community
                   </p>

                   <p class="mt-2"></p>

                     <v-layout style="width:100%">
                       <v-spacer></v-spacer>

                       <v-layout column align-center :style="drawer ? 'margin-top:-22px' : ''">
                         <v-btn icon  @click="openLink('https://t.me/token_monster')" style="padding-bottom:10px">
                           <telegram-icon size="50" class="custom-class"></telegram-icon>
                         </v-btn>
                         <v-btn text  @click="openLink('https://t.me/token_monster')">
                           Chat
                         </v-btn>
                       </v-layout>

                       <v-spacer></v-spacer>

                       <v-layout column align-center :style="drawer ? 'margin-top:-22px' : ''">
                         <v-btn icon @click="openLink('https://t.me/token_monster_pa')" style="padding-bottom:10px">
                           <telegram-icon size="50" class="custom-class"></telegram-icon>
                         </v-btn>
                         <v-btn text @click="openLink('https://t.me/token_monster_pa')">
                           PA Channel
                         </v-btn>
                       </v-layout>

                       <v-spacer></v-spacer>
                    </v-layout>
                 </v-layout>
                </v-card>
              </v-hover>
            </v-col>

            <v-col
               cols="12"
               sm="3"
             >
               <v-hover
                 v-slot:default="{ hover }"
                 open-delay="200"
               >
                 <v-card
                   :elevation="hover ? 16 : 2"
                   class="mx-auto"
                   height="200"
                   max-width="350"
                   :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
                 >
                 <v-layout column align-center pa-4>
                   <p style="padding-left:10px;padding-right:10px;font-size:16px" class="text-center font-weight-medium mt-2">
                     Follow @TokenMonsterTMO for updates and news.
                   </p>

                   <p class="mt-2"></p>

                   <v-btn icon  @click="openLink('https://twitter.com/TokenMonsterTMO')">
                     <v-icon size="70" >mdi-twitter</v-icon>
                   </v-btn>

                 </v-layout>

                 </v-card>
               </v-hover>
             </v-col>

             <v-col
                cols="12"
                sm="3"
              >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    :elevation="hover ? 16 : 2"
                    class="mx-auto"
                    height="200"
                    max-width="350"
                    :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
                  >
                  <v-layout column align-center pa-4>
                    <p style="padding-left:10px;padding-right:10px;font-size:16px" class="text-center font-weight-medium mt-2">
                      Talk, chat, hang out, and stay close with the community.
                    </p>

                    <p class="mt-2"></p>

                    <v-btn icon  @click="openLink('https://www.reddit.com/r/TokenMonster/')">
                      <v-icon size="70" >mdi-reddit</v-icon>
                    </v-btn>

                  </v-layout>

                  </v-card>
                </v-hover>
              </v-col>

              <v-col
                 cols="12"
                 sm="12"
               >
               <p class="mt-2"></p>
               <p style="font-size:18px" class="text-center font-weight-bold">Other links</p>


               <v-layout pt-4 :column="windowWidth < 800" align-center>
                  <v-spacer ></v-spacer>

                  <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://github.com/TokenMonsterTMO/TokenMonsterProject')">
                    <v-icon large left>mdi-github</v-icon><span style="width:50px">GitHub</span>
                  </v-btn>

                  <v-spacer ></v-spacer>

                  <v-btn text small style="text-transform: none !important;width:220px" @click="openLink('https://discord.gg/sMrKdy6k')">
                    <v-icon large left>mdi-discord</v-icon><span style="width:160px">Discord</span>
                  </v-btn>

                  <v-spacer ></v-spacer>

                  <v-btn text small style="text-transform: none !important;width:220px" @click="openLink('https://tokenmonster.app/docs/Token%20Monster.pdf')">
                    <v-icon large left>mdi-presentation</v-icon><span style="width:160px">Token Monster Slides</span>
                  </v-btn>

                  <v-spacer ></v-spacer>
                </v-layout>

               <v-layout pt-4 :column="windowWidth < 800" align-center>
                  <v-spacer ></v-spacer>

                  <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://t.me/token_monster_oac_chinese')">
                    <v-icon large left>mdi-telegram</v-icon><span style="width:50px">Chinese</span>
                  </v-btn>

                  <v-spacer ></v-spacer>

                  <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://t.me/token_monster_oac_korea')">
                    <v-icon large left>mdi-telegram</v-icon><span style="width:50px">Korean</span>
                  </v-btn>

                  <v-spacer ></v-spacer>

                  <v-btn text small style="text-transform: none !important;width:180px" @click="openLink('https://t.me/token_monster_oac_indonesia ')">
                    <v-icon large left>mdi-telegram</v-icon><span style="width:140px">Bahasa Indonesia</span>
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://t.me/token_monster_oac_hindi ')">
                    <v-icon large left>mdi-telegram</v-icon><span style="width:50px">Hindi</span>
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-layout>

                <v-layout pt-4 :column="windowWidth < 800" align-center>
                   <v-spacer ></v-spacer>

                   <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://t.me/token_monster_oac_german')">
                     <v-icon large left>mdi-telegram</v-icon><span style="width:50px">German</span>
                   </v-btn>

                   <v-spacer ></v-spacer>

                   <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://t.me/token_monster_oac_french')">
                     <v-icon large left>mdi-telegram</v-icon><span style="width:50px">French</span>
                   </v-btn>

                   <v-spacer ></v-spacer>

                   <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://t.me/token_monster_oac_spanish')">
                     <v-icon large left>mdi-telegram</v-icon><span style="width:60px">Spanish</span>
                   </v-btn>

                   <v-spacer></v-spacer>

                   <v-btn text small style="text-transform: none !important;width:130px" @click="openLink('https://t.me/token_monster_oac_portuguese')">
                     <v-icon large left>mdi-telegram</v-icon><span style="width:60px">Portugese</span>
                   </v-btn>

                   <v-spacer></v-spacer>
                 </v-layout>


               </v-col>
        </v-row>

        <p class="mt-4"></p>
          <hr>
        <p class="mt-4"></p>

        <p class="text-center">If you'd like to donate to this project, please send some BNB here. Thank you for your support!</p>
        <p :style="windowWidth < 770 ? 'font-size:12px;' : 'font-size:14px'" class="wrap-text text-center font-weight-medium">
          {{ windowWidth > 770 ? donateAddress : donateAddress.substr(0, 20) + '...' }}
          <v-icon color="grey" small style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => donateAddress" @success="handleSuccess" @error="handleError">mdi-content-copy</v-icon>
        </p>

        <p class="mt-4"></p>
        <p class="mt-4"></p>

   </v-layout>

   <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
     <v-icon color="green">mdi-check-circle-outline</v-icon>
     <span style="margin-left:5px">{{ snackbarText }}</span>
           <v-btn text color="#388E3C" @click.native="snackbar = false">Close</v-btn>
   </v-snackbar>

    </v-sheet>
  </v-container>
</template>

<script>
import { TelegramIcon } from 'vue-simple-icons'
export default {
  name: 'footer',
  props: {
    dark: Boolean,
    drawer: Boolean,
    windowWidth: Number,
    windowHeight: Number
  },
  data () {
    return {
      model: null,
      active: true,
      snackbar: false,
      snackbarText: '',
      logoSrcDark: '',
      logoSrcLight: '',
      logoSrcWalletDark: '',
      logoSrcWalletLight: '',
      logoSrcStexDark: '',
      logoSrcStexLight: '',
      logoSrcCGDark: '',
      logoSrcCGLight: '',
      logoSrcCMCDark: '',
      logoSrcCMCLight: '',
      logoSrcBCDark: '',
      logoSrcBCLight: '',
      donateAddress: '0x770e725359cd9A3Cf34FEeb832A16969a8D21660'
    }
  },
  components: {
    TelegramIcon
  },
  watch: {
  },
  created () {
    // this.windowWidth = this.$props.windowindowWidth
    this.logoSrcDark = './img/bonezyard_logo_old.png'
    this.logoSrcLight = './img/bonezyard_logo_old.png'
    this.logoSrcWalletDark = './img/icons/my_wallet_color_dark.png'
    this.logoSrcWalletLight = './img/icons/my_wallet_color_light.png'
    this.logoSrcStexDark = './img/icons/stex_contact_dark.png'
    this.logoSrcStexLight = './img/icons/stex_contact_light.png'
    this.logoSrcCGDark = './img/icons/coingecko-dark.png'
    this.logoSrcCGLight = './img/icons/coingecko-light.png'
    this.logoSrcCMCDark = './img/icons/cmc_dark.png'
    this.logoSrcCMCLight = './img/icons/cmc_light.png'
    this.logoSrcBCDark = './img/icons/bitcourier_dark.png'
    this.logoSrcBCLight = './img/icons/bitcourier_light.png'
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank')
    },
    openTerms () {
      this.$emit('termsClicked')
    },
    openPrivacy () {
      this.$emit('privacyClicked')
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = 'Contract Address copied.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    },
  }
}
</script>

<style>
.wrapper{
  background: #fafafa;
  display: flex;
  justify-content: center;
}
.sectionHeader {
  font-family: 'Comfortaa';
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}
.cardsH {
  background: #FFF;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: grid;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}
.cardsV {
  background: #FFF;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: table-column;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}
</style>
