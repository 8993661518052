<template>
  <v-sheet
    class="d-flex"
    :dark="dark"
    :style="dark ? 'width: 100%;height: auto;background: linear-gradient(to right, #363636, #314141);' : 'width: 100%;height: auto;background: linear-gradient(to right, #badadb, #314141);'"
    tile
    >
    <v-layout column align-center >
        <p class="mt-4"></p>
        <div class="whyTitle text--center">TMO Tokenomics</div>
        <p class="mt-3"></p>

      <v-layout row wrap :style="windowWidth < 770 ? 'margin-right:3px;margin-left:3px' : 'margin-right:30px;margin-left:30px'">

        <v-col
           cols="12"
           sm="12"
         >
           <v-hover
             v-slot:default="{ hover }"
             open-delay="200"
           >
             <v-card
               :elevation="hover ? 16 : 2"
               class="mx-auto"
               :max-height="windowWidth < 770 ? '' : 850"
               :width="windowWidth < 770 ? '' : 900"
               max-width="900"
               :dark="dark"
               :style="dark ? 'background: linear-gradient(180deg, rgba(42,51,51,1) 0%, rgba(29,29,29,1) 100%);' : 'background: linear-gradient(180deg, rgba(59,81,82,1) 0%, rgba(129,162,163,1) 100%);'"
             >
             <v-row style="padding-top: 20px;">
                <v-col
                  cols="12"
                  md="6"
                >
                <div
                  class="cardTitleTok flex-grow-1 text-center"
                >
                  Total Supply: 500 Million
                </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                <div
                  class="cardTitleTok flex-grow-1 text-center"
                >
                  Token Type: BEP-20 (BSC)
                </div>
                </v-col>
              </v-row>

              <p class="mt-4"></p>

              <div style="padding-top:10px"
                class="cardTitleTokSmall flex-grow-1 text-center"
              >
                100% Liquidity Locked
              </div>

              <!-- <div style="padding-top:20px"
                class="cardTitleTokSmall flex-grow-1 text-center"
              >
                Max buy: 2.5 Million (~ 0.5% of Total Supply)
              </div>
              <div style="padding-top:10px"
                class="cardTitleTokSmall flex-grow-1 text-center"
              >
                Max hold: 25 Million (~ 5.0% of Total Supply)
              </div> -->

              <div style="padding-top:30px"
                class="cardTitleTok flex-grow-1 text-center"
              >
              <p style="padding-left:20px;padding-right:10px" class="font-weight-bold mt-2">
                TOKEN DISTRIBUTION
              </p>
              </div>

              <v-row style="padding-top: 10px;;margin-bottom:20px">
                <!--  <v-col
                   cols="12"
                   md="4"
                 >
                 <div style="margin-left:50px"
                   class="cardTitleTokDist flex-grow-1"
                 >
                   Sale : 70%<br>
                   VC Round 1 :  10%<br>
                   Development :  5%<br>
                   Marketing : 5%<br>
                   Reserved : 10% (VC Round 2)
                 </div>
               </v-col> -->
                 <v-col
                   cols="12"
                   md="12"
                 >
                 <div
                   class="cardTitleTok flex-grow-1 text-center"
                 >
                 <v-layout column align-center >
                   <div id="chart" :style="windowWidth < 770 ? 'width:' + windowWidth + 'px;height:400px' : 'width:700px;height:700px;'">
                     <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
                   </div>
                 </v-layout>
                 <!-- <v-img
                   :src="dark ? 'img/home/tokenomics_pie_01.png' : 'img/home/tokenomics_pie_01.png'"
                   class="white--text align-end"
                   style="opacity:0.9"
                   :width="windowWidth < 770 ? 360 : 800"
                 >
               </v-img> -->

                 </div>
                 </v-col>
               </v-row>

             </v-card>
           </v-hover>
         </v-col>

      </v-layout>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
    </v-layout>
  </v-sheet>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'why',
  props: {
    dark: Boolean,
    windowWidth: Number
  },
  data () {
    return {
      logoSrcWalletDark: '',
      logoSrcWalletLight: '',
      series: [80, 6, 6, 4, 4],
      chartOptions: {}
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  watch: {
  },
  created () {
    // this.windowWidth = this.$props.windowWidth
    this.logoSrcWalletDark = './img/icons/my_wallet_new_dark.png'
    this.logoSrcWalletLight = './img/icons/my_wallet_new_light.png'
    this.chartOptions = {
            chart: {
              type: 'donut',
            },
            labels: ['Public Sale', 'Development', 'Marketing', 'Team', 'Airdrops / Bounties'],
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val + '%'
              }
            },
            plotOptions: {
              pie: {
                startAngle: -270,
                endAngle: 90,
              }
            },
            legend: {
              formatter: function(val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex] + '%'
              },
              position: 'top',
              fontSize: '14px',
              labels: {
                  colors: '#BDBDBD'
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap');

.whyTitle {
  font-family: 'Comfortaa';
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitleTok {
  font-family: 'Comfortaa';
  font-size: 25px;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitleTokDist {
  font-family: 'Comfortaa';
  font-size: 20px;
  // text-transform: uppercase;
}
.cardTitleTokBottom {
  font-family: 'Comfortaa';
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitleTokSmall {
  font-family: 'Comfortaa';
  font-size: 22px;
  text-align: center;
  // text-transform: uppercase;
}

</style>
