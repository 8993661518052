export const LANG = {

  en: {
    // menu and module titles
    APP: 'TokenMonster',
    SLOGAN: 'Explore new tokens!', // 'The place for soccer enthusiasts.'
    WELCOME: 'WELCOME TO Token Monster!',
    WELCOME_TEXT_UPDATED: 'Welcome Text Updated',
    ONLINE: ' You are online.',
    OFFLINE: ' Connection lost, you are offline!',
    LANG: 'Language',
    ABOUT: 'About Token Monster',
    ABOUT_TEXT: 'Developed by Martin Kuijs',
    ABOUT_VERSION: 'Version:',

    // Get started
    LANDING: '',

    // Signup
    YOUR_NUMBER: 'What\'s your number?',
    THE_CODE: 'What\'s the code?',
    WE_TEXT: 'We will text you a code to verify',
    SMS_SENT: 'Sent to: ',
    ENTER_CODE: 'Enter the code',
    RE_SEND: 'Resend Code',
    PROBLEMS: 'Problems receiving the code?',
    INVALID_CODE: 'Invalid SMS code format!',
    INV_CODE: 'Invalid code!',
    CODE_MUST_BE: 'The code must be 6 numbers',
    CHECK_SMS: 'Please check your SMS or resend the code.',
    CHECK_NR: 'Please check your number.',
    SMS_ERROR: 'Error. SMS not sent!',
    ALREADY_ACCOUNT: 'Already have an account?',
    LOGIN_EMAIL: 'Login with your email address',
    NEW_PHONE: 'New phone number?',
    CHANGE_PHONE: 'Change your phone number here',

    // Sign Up Next
    IN_ORDER: 'In order to complete the setup of your account, we need a little more information from you...',
    ACCOUNT_CREATED: 'Account created successfully.',
    CONGRATULATIONS: 'WELCOME TO',
    CONGRATULATIONS_A: 'TokenMonster!',
    ACC_READY: 'Your account has been successfully registered! You can now sign in on any device with the mobile number or email you’ve provided.',
    ACC_TEMP_EMAIL: 'In the event you’ve changed your mobile number, please use either your personal email or the TokenMonster email provided to you in Your Account to log in again.',
    NO_EMAIL: 'I don\'t have an email address',
    SET_PASSWORD: 'Please, set your password',
    AGREE_TERMS: 'I agree to the Terms and Privacy Policy',

    // Sign In
    SIGN_IN_HERE: 'Please, Sign In here',
    PW_RECOVERY: 'Password Recovery',
    ENTER_EMAIL: 'Please, enter your email address',
    ENTER_NEW_EMAIL: 'Please, enter your new email address',
    CHANGE_PHONE_NR: 'Change Your Phone Number',
    SIGN_IN_FIRST: 'Please, Sign In first',
    FORGOT_PWD: 'Forgot your password?',
    NO_ACCOUNT: 'No Account yet?',
    SIGN_UP_NOW: 'Sign Up now!',
    SEND_RESET_LINK: 'We will send a reset link to your email.',
    EMAIL_SENT: 'Email Sent',
    CHECK_EMAIL: 'Please check your inbox, an email is on the way.',
    EMAIL_INSTRUCTIONS: 'All you have to do is open it and click the link. Of course, if you have any questions, you can contact us.',

    // Home
    HOME: 'Home',

    // User Menu
    MY_ACCOUNT: 'My Account',
    ACC_VERIFICATION: 'Account Verification',
    ACC_EMAIL: 'Account Email',
    ACC_PHONE: 'Account Phone',
    ACC_TYPE: 'Account Type',
    ACC_TIER: 'Account Tier',
    ACC_STATUS: 'Account Status',
    ACC_INFO: 'Account Information',
    ACC_PERSONAL: 'Personal Information',
    ACC_NAME: 'Display Name',
    ACC_ABOUT: 'About me',
    ACC_ABOUT_TEXT: 'Write something about yourself...',
    ACC_FULL_NAME: 'Full Legal Name',
    ACC_DOB: 'Date of Birth',
    ACC_DOB_TEXT: 'Add your birthday',
    ACC_GENDER: 'Gender',
    ACC_MALE: 'Male',
    ACC_FEMALE: 'Female',
    ACC_NOT_SET: 'Not Set',
    ACC_LANG: 'Language',
    ACC_OB: 'TokenMonster Settings',
    ACC_TEAMS: 'My Favourite Teams',
    ACC_LEAGUES: 'My Leagues',
    ACC_CH_PIC: 'Change Profile Picture',
    ACC_SET_PIC: 'Set as Profile Picture',
    ACC_EMAIL_NOT_SET: 'Your email adress has not been set',
    ACC_EMAIL_ASS: 'We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.',
    ACC_NOT_VERIFIED: 'Your email adress has not been verified',
    ACC_VERIFIED: 'Your email adress has been verified',
    ACC_VERIFY: 'Please follow the link below to verify your email.',
    ACC_APP: 'App',
    ACC_APP_VER: 'Current Version',
    ACC_APP_TERMS: 'Terms and Conditions',
    ACC_APP_PRIVACY: 'Privacy Policy',

    // Contact Us
    CONTACT_US: 'Contact Us',
    CONTACT_US_DROP: 'Drop us a line.',

    // Settings
    SETTINGS: 'Settings',
    PRO_CH_PL: 'Please, set your password',
    SET_UPDATED: 'Settings Updated',
    PWD_CHANGED: 'Password has been changed',

    // Buttons
    BUT_RECENT: 'RECENT',
    BUT_NEXT: 'Next',
    BUT_CONTINUE: 'Continue to your account',
    BUT_CONT_DRAFT: 'Continue Writing',
    BUT_SIGN_IN: 'Sign In',
    BUT_SIGN_UP: 'Sign Up',
    BUT_RESET_PW: 'Reset Password',
    BUT_CANCEL: 'Cancel',
    BUT_BACK: 'Back',
    BUT_BACK_TO_SIGN_IN: 'Back to Sign In',
    BUT_VERIFY: 'Verify',
    BUT_LATER: 'I\'ll do this later',
    BUT_CHANGE_EMAIL: 'Change your email address',
    BUT_CHANGE: 'Change',
    BUT_DONE: 'Done',
    BUT_OK: 'OK',
    BUT_SAVE_DRAFT: 'Save as Draft',
    BUT_SUMBIT: 'Submit',
    BUT_SUBM_PHOTO: 'Submit Photos',
    BUT_VIEW_ALL: 'View Detail',
    BUT_ALL_NEWS: 'See All News',
    BUT_ALL_VIDEOS: 'See All Videos',
    BUT_ALL_FF: 'See All in Fan Forum',
    BUT_VIEW_GRID: 'View Grid',
    BUT_CONFIRM: 'Confirm',
    BUT_CLOSE: 'Close',
    BUT_CHECK: 'Check Status',
    BUT_CHECK_PHOTO: 'Check Publishing Status',
    BUT_LOAD_MORE: 'Load more...'

  },
  id: {
    // menu and module titles
    APP: 'TokenMonster',
    SLOGAN: 'Explore new tokens!',
    WELCOME: 'SELAMAT DATANG DI Token Monster!',
    WELCOME_TEXT_UPDATED: 'Teks Diperbarui',
    ONLINE: ' You are online.',
    OFFLINE: ' Connection lost, you are offline!',
    LANG: 'Language',
    ABOUT: 'About Token Monster',
    ABOUT_TEXT: 'Developed by Martin Kuijs',
    ABOUT_VERSION: 'Version:',

    // Get started
    LANDING: 'Penggalangan dana untuk proyek dan orang yang berarti bagi Anda',

    // Signup
    YOUR_NUMBER: 'Masukkan nomor telepon Anda?',
    THE_CODE: 'Apa kodenya?',
    WE_TEXT: 'Kami akan mengirimkan kodenya melalui SMS untuk proses verifikasi',
    SMS_SENT: 'Dikirim ke: ',
    ENTER_CODE: 'Masukkan kodenya',
    RE_SEND: 'Kirim ulang kode',
    PROBLEMS: 'Ada masalah saat menerima kode?',
    INVALID_CODE: 'Format kode SMS salah!',
    INV_CODE: 'Kode tidak valid!',
    CODE_MUST_BE: 'Kode harus terdiri dari 6 angka',
    CHECK_SMS: 'Silahkan cek SMS Anda atau kirim ulang kodenya.',
    CHECK_NR: 'Silahkan cek nomor ponsel Anda.',
    SMS_ERROR: 'Terjadi kesalahan. SMS tidak terkirim!',
    ALREADY_ACCOUNT: 'Sudah memiliki akun?',
    LOGIN_EMAIL: 'Silahkan login dengan email Anda',
    NEW_PHONE: 'Memiliki nomor ponsel yang baru?',
    CHANGE_PHONE: 'Ubah nomor ponsel Anda disini',

    // Sign Up Next
    IN_ORDER: 'Untuk melengkapi proses pembuatan akun, kami membutuhkan sedikit informasi tentang Anda...',
    ACCOUNT_CREATED: 'Akun sudah berhasil dibuat',
    CONGRATULATIONS: 'SELAMAT DATANG DI',
    CONGRATULATIONS_A: 'TokenMonster!',
    ACC_READY: 'Akun Anda sudah berhasil teregistrasi! Silahkan masuk dengan perangkat apa pun menggunakan nomor ponsel atau alamat email yang terdaftar.',
    ACC_TEMP_EMAIL: 'Jika Anda telah mengubah nomor ponsel Anda, harap gunakan email pribadi Anda atau email TokenMonster yang diberikan kepada Anda di Akun Anda untuk masuk kembali.',
    NO_EMAIL: 'Saya tidak punya alamat email',
    SET_PASSWORD: 'Silahkan buat password Anda',
    AGREE_TERMS: 'Saya mengetujui persyaratan dan kebijakan privasi',

    // Sign In
    SIGN_IN_HERE: 'Silahkan, login disini',
    PW_RECOVERY: 'Pemulihan Kata Sandi',
    ENTER_EMAIL: 'Silahkan ketik alamat email Anda',
    ENTER_NEW_EMAIL: 'Silahkan ketik alamat email baru.',
    CHANGE_PHONE_NR: 'Ubah nomor ponsel Anda',
    SIGN_IN_FIRST: 'Silahkan login terlebih dahulu',
    FORGOT_PWD: 'Lupa password?',
    NO_ACCOUNT: 'Belum punya akun?',
    SIGN_UP_NOW: 'Daftar sekarang!',
    SEND_RESET_LINK: 'Kami akan mengirimkan link reset ke email Anda.',
    EMAIL_SENT: 'Email terkirim',
    CHECK_EMAIL: 'Silahkan cek inbox Anda, kami baru saja mengirimkan email.',
    EMAIL_INSTRUCTIONS: 'Silahkan cek inbox Anda, kami baru saja mengirimkan email. Coba buka email dari kami dan klik link yang tersedia. Tentu saja, jika Anda memiliki pertanyaan, silahkan hubungi kami.',

    // Home
    HOME: 'Home',

    // User Menu
    MY_ACCOUNT: 'Akun Saya',
    ACC_VERIFICATION: 'Verifikasi akun',
    ACC_EMAIL: 'Email akun',
    ACC_PHONE: 'Nomor ponsel ',
    ACC_TYPE: 'Tipe akun',
    ACC_TIER: 'Akun Tier',
    ACC_STATUS: 'Status akun',
    ACC_INFO: 'Informasi akun',
    ACC_PERSONAL: 'Informasi pribadi',
    ACC_NAME: 'Nama tampilan',
    ACC_ABOUT: 'Tentang saya',
    ACC_ABOUT_TEXT: 'Tulis sesuatu tentang diri Anda...',
    ACC_FULL_NAME: 'Nama lengkap',
    ACC_DOB: 'Tanggal lahir',
    ACC_DOB_TEXT: 'Masukkan tanggal lahir Anda',
    ACC_GENDER: 'Jenis kelamin',
    ACC_MALE: 'Pria',
    ACC_FEMALE: 'Perempuan',
    ACC_NOT_SET: 'Belum diatur',
    ACC_LANG: 'Bahasa',
    ACC_OB: 'Pengaturan TokenMonster',
    ACC_TEAMS: 'Tim Favorit Saya',
    ACC_LEAGUES: 'Liga pilihan saya',
    ACC_CH_PIC: 'Ganti Foto Profil',
    ACC_SET_PIC: 'Simpan Gambar Profil',
    ACC_EMAIL_NOT_SET: 'Email Anda belum di set',
    ACC_EMAIL_ASS: 'Kami telah memberikan Anda alamat email yang hanya dapat digunakan untuk login bersama dengan kata sandi yang Anda buat.',
    ACC_NOT_VERIFIED: 'Email Anda belum diverifikasi',
    ACC_VERIFIED: 'Email Anda sudah diverifikasi',
    ACC_VERIFY: 'Silahkan klik link dibawah ini untuk verifikasi email Anda.',
    ACC_APP: 'App',
    ACC_APP_VER: 'Versi',
    ACC_APP_TERMS: 'Syarat dan Ketentuan',
    ACC_APP_PRIVACY: 'Kebijakan Privasi',

    // Contact Us
    CONTACT_US: 'Hubungi Kami',
    CONTACT_US_DROP: 'Kirimi kami pesan.',

    // Settings
    PRO_CH_PL: 'Silahkan buat password Anda',
    SET_UPDATED: 'Pengaturan Disimpan',
    PWD_CHANGED: 'Kata sandi telah diubah',

    // Buttons
    BUT_RECENT: 'RECENT',
    BUT_NEXT: 'Lanjut',
    BUT_CONTINUE: 'Lanjutkan ke akun Anda',
    BUT_CONT_DRAFT: 'Lanjut Menulis',
    BUT_SIGN_IN: 'Masuk',
    BUT_SIGN_UP: 'Daftar',
    BUT_RESET_PW: 'Reset Password',
    BUT_CANCEL: 'Cancel',
    BUT_BACK: 'Kembali',
    BUT_BACK_TO_SIGN_IN: 'Kembali ke Masuk',
    BUT_VERIFY: 'Verifikasi',
    BUT_LATER: 'I\'ll do this later',
    BUT_CHANGE_EMAIL: 'Ubah alamat email Anda',
    BUT_CHANGE: 'Ubah',
    BUT_DONE: 'Selesai',
    BUT_OK: 'OK',
    BUT_SAVE_DRAFT: 'Simpan sebagai draft',
    BUT_SUMBIT: 'Submit',
    BUT_SUBM_PHOTO: 'Submit Foto',
    BUT_VIEW_ALL: 'Lihat Detail',
    BUT_ALL_NEWS: 'Lihat Semua Berita',
    BUT_ALL_VIDEOS: 'Lihat Semua Video',
    BUT_ALL_FF: 'Lihat Semua Fan Forum',
    BUT_VIEW_GRID: 'Lihat Grid',
    BUT_CONFIRM: 'Konfirmasi',
    BUT_CLOSE: 'Tutup',
    BUT_CHECK: 'Cek Status',
    BUT_CHECK_PHOTO: 'Cek Status Penerbitan',
    BUT_LOAD_MORE: 'Muat lebih banyak...'
  }
}
