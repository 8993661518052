<template>
  <v-sheet
    class="d-flex"
    :dark="dark"
    :style="dark ? 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(15,34,16,1) 0%, rgba(37,37,37,1) 100%);border-radius:10px' : 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(43,119,3,1) 0%, rgba(214,255,193,1) 100%);border-radius:10px'"
    tile
    >
    <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:5px;margin-left:5px' : 'margin-right:50px;margin-left:50px'" >
        <p class="mt-4"></p>
        <p class="mt-4"></p>
        <div class="text-center font-weight-bold" style="font-size:30px;margin-right:20px;margin-left:20px">Token Monster (TMO) is a streaming charts DAPP and introduction to token projects on the BSC/ETH and other blockchains as well as a MEME & NFT token platform and  market place built
on the Binance Smart Chain.</div>
        <p class="mt-3"></p>

      <v-layout row wrap :style="windowWidth < 1400 ? '' : 'margin-right:10%;margin-left:10%;'">

        <v-col
           cols="12"
           sm="6"
         >
           <v-hover
             v-slot:default="{ hover }"
             open-delay="200"
           >
             <v-card
               :elevation="hover ? 16 : 2"
               class="mx-auto"
               :dark="dark"
               min-height="600"
               max-height="900"
               :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
             >
             <v-toolbar flat color="transparent" pa-2>
               <!-- <v-icon :dark="!dark" x-large >mdi-script-text-outline</v-icon> -->
               <p style="font-size:22px" class="font-weight-bold  mt-4">
                 OUR STORY
               </p>
             </v-toolbar>
                <p style="padding-left:20px;padding-right:20px;font-size:16px" class="font-weight-medium mt-2">
                 This token will surprise the community and grow into a valuable resource where users can evaluate new tokens that come to market and see real-time information like Market Capital (MC) and Total Holders.<br><br>
                 One of the features Token Monster has to offer during a token launch is the Discussion/Chat that can be used by the audience to communicate "need to knows" like possible scam/rug pull or when selling is not possible (honeypot). Usually the social channels (Telegram etc) will be muted and there is no option for the audience to talk to each other...until now!<br><br>
                 In the 2nd phase the TokenMonster will be enhanced so that users can enjoy, collect and exchange MEMES / NFTs and much more.
               </p>
               <v-layout column align-center pb-4>

                 <p style="font-size:18px" class="font-weight-bold  mt-4">
                   Is this for real?
                 </p>

                 <v-btn :color="dark ? '#1B5E20' : '#A5D6A7'" style="width:320px;text-transform: none !important;" @click="openRealness">
                   <v-icon left size="25" small>mdi-check-outline</v-icon>Token Monster Realness Check
                 </v-btn>
              </v-layout>

             </v-card>
           </v-hover>
         </v-col>

         <v-col
            cols="12"
            sm="6"
          >
            <v-hover
              v-slot:default="{ hover }"
              open-delay="200"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                class="mx-auto"
                :dark="dark"
                min-height="600"
                max-height="900"
                :style="dark ? 'background: linear-gradient(180deg, rgba(26,60,27,1) 0%, rgba(37,37,37,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(43,119,3,1) 100%);'"
              >
              <v-toolbar flat color="transparent" pa-2>
                <!-- <v-icon :dark="!dark" x-large >mdi-script-text-outline</v-icon> -->
                <p style="font-size:22px" class="font-weight-bold  mt-4">
                  OUR TOKEN
                </p>
              </v-toolbar>
              <p style="padding-left:20px;padding-right:20px;font-size:16px" class="font-weight-medium mt-2">
                The TMO Token focuses on the implementation of tools and features to loyal token holders and the community.<br><br>See the details in the Tokenomics section and the Token distribution.

              </p>
                <p style="padding-left:20px;padding-right:20px;font-size:16px" class="font-weight-medium mt-2">
                  <strong>Why hold TMO tokens?</strong><br>

                  When holding 10,000 TMO the system will open up features like:<br>
                  - Set Alerts (via App, SMS, Email) on Launch Events, CoinGecko and CMC lists (Recently Added, Trending etc.)<br>
                  - Promo's and airdrops only to TMO holders.<br>
                  - Affiliates of Token Monster will receive discounts to airdrop tokens to TMO holders.<br>
                  - The platform levels deeper to TMO holders.
                  <br><br>
                  Many features will only be available to TMO holders in the future so buy now and HODL!
                </p>

                <v-layout column align-center pb-4>

                  <v-btn :color="dark ? '#1B5E20' : '#A5D6A7'" style="width:320px;text-transform: none !important;" v-scroll-to="'#tokenholders'">
                    See More...
                  </v-btn>
               </v-layout>

              </v-card>
            </v-hover>
          </v-col>

         <v-col
            cols="12"
            sm="4"
          >
            <v-hover
              v-slot:default="{ hover }"
              open-delay="200"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                :dark="dark"
                min-height="120"
                max-height="450"
                max-width="300"
              >
              <v-layout column align-center pt-2>
                <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-bullhorn-outline</v-icon>
                <p style="padding-left:10px;padding-right:10px;font-size:16px" class="text-center font-weight-bold mt-2">
                  PROMOTE PROJECTS AND TOKENS
                </p>
              </v-layout>

              </v-card>
            </v-hover>
          </v-col>


         <v-col
            cols="12"
            sm="4"
          >
            <v-hover
              v-slot:default="{ hover }"
              open-delay="200"
            >
              <v-card
                :elevation="hover ? 16 : 2"
                :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                :dark="dark"
                min-height="120"
                max-height="450"
                max-width="300"
              >

              <v-layout column align-center pt-2>
                <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-trending-up</v-icon>
                <p style="padding-left:10px;padding-right:10px;font-size:16px" class="text-center font-weight-bold mt-2">
                  ADD TOKENS AND SEE WHICH ARE TRENDING
                </p>
              </v-layout>

              </v-card>
            </v-hover>
          </v-col>

          <v-col
             cols="12"
             sm="4"
           >
             <v-hover
               v-slot:default="{ hover }"
               open-delay="200"
             >
               <v-card
                 :elevation="hover ? 16 : 2"
                 :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                 :dark="dark"
                 min-height="120"
                 max-height="450"
                 max-width="300"
               >

               <v-layout column align-center pt-2>
                 <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-axis-arrow-info</v-icon>
                 <p style="padding-left:5px;padding-right:5px;font-size:16px" class="text-center font-weight-bold mt-2">
                   EXPLORE NEW TOKENS BEFORE AND AFTER LAUNCH
                 </p>
               </v-layout>

               </v-card>
             </v-hover>
           </v-col>

           <v-col
              cols="12"
              sm="4"
            >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card
                  :elevation="hover ? 16 : 2"
                  :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                  :dark="dark"
                  min-height="120"
                  max-height="450"
                  max-width="300"
                >

                <v-layout column align-center pt-2>
                  <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-select-search</v-icon>
                  <p style="padding-left:5px;padding-right:5px;font-size:16px" class="text-center font-weight-bold mt-2">
                    FAST INDEXED SEARCH AND FIND FUNCTION
                  </p>
                </v-layout>

                </v-card>
              </v-hover>
            </v-col>

            <v-col
               cols="12"
               sm="4"
             >
               <v-hover
                 v-slot:default="{ hover }"
                 open-delay="200"
               >
                 <v-card
                   :elevation="hover ? 16 : 2"
                   :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                   :dark="dark"
                   min-height="120"
                   max-height="450"
                   max-width="300"
                 >

                 <v-layout column align-center pt-2>
                   <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-comment-text-multiple-outline</v-icon>
                   <p style="padding-left:5px;padding-right:5px;font-size:16px" class="text-center font-weight-bold mt-2">
                     UNMODERATED COMMENTS AND DISCUSSIONS
                   </p>
                 </v-layout>

                 </v-card>
               </v-hover>
             </v-col>

             <v-col
                cols="12"
                sm="4"
              >
                <v-hover
                  v-slot:default="{ hover }"
                  open-delay="200"
                >
                  <v-card
                    :elevation="hover ? 16 : 2"
                    :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                    :dark="dark"
                    min-height="120"
                    max-height="450"
                    max-width="300"
                  >

                  <v-layout column align-center pt-2>
                    <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-format-list-bulleted-type</v-icon>
                    <p style="padding-left:5px;padding-right:5px;font-size:16px" class="text-center font-weight-bold mt-2">
                      MARKET CAP, AGE AND TOKEN HOLDERS IN ONE LIST
                    </p>
                  </v-layout>

                  </v-card>
                </v-hover>
              </v-col>

              <v-col
                 cols="12"
                 sm="6"
               >
                 <v-hover
                   v-slot:default="{ hover }"
                   open-delay="200"
                 >
                   <v-card
                     :elevation="hover ? 16 : 2"
                     :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                     :dark="dark"
                     min-height="120"
                     max-height="450"
                     max-width="300"
                   >

                   <v-layout column align-center pt-3>
                     <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-rocket-launch-outline</v-icon>
                     <p style="padding-left:5px;padding-right:5px;font-size:16px" class="text-center font-weight-bold mt-2">
                       LAUNCH QUEUE WITH EASY OVERVIEW OF UPCOMING LAUNCH EVENTS
                     </p>
                   </v-layout>

                   </v-card>
                 </v-hover>
               </v-col>

               <v-col
                  cols="12"
                  sm="6"
                >
                  <v-hover
                    v-slot:default="{ hover }"
                    open-delay="200"
                  >
                    <v-card
                      :elevation="hover ? 16 : 2"
                      :class="dark ? 'mx-auto myDivDark' : 'mx-auto myDiv'"
                      :dark="dark"
                      min-height="120"
                      max-height="450"
                      max-width="300"
                    >

                    <v-layout column align-center pt-3>
                      <v-icon style="opacity:0.5" size="60" :dark="dark">mdi-skull-scan-outline</v-icon>
                      <p style="padding-left:5px;padding-right:5px;font-size:16px" class="text-center font-weight-bold mt-2">
                        LINKS TO TOOLS, BSCSCAN AND GUIDANCE ON HOW TO DETECT POSSIBLE SCAMS
                      </p>
                    </v-layout>

                    </v-card>
                  </v-hover>
                </v-col>

      </v-layout>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
    </v-layout>
  </v-sheet>
</template>

<script>
export default {
  name: 'why',
  props: {
    dark: Boolean,
    windowWidth: Number,
    drawer: Boolean
  },
  data () {
    return {
      logoSrcWalletDark: '',
      logoSrcWalletLight: ''
    }
  },
  watch: {
  },
  created () {
    // this.windowWidth = this.$props.windowWidth
    this.logoSrcWalletDark = './img/icons/my_wallet_new_dark.png'
    this.logoSrcWalletLight = './img/icons/my_wallet_new_light.png'
  },
  methods: {
    openRealness () {
      this.$emit('realnessClicked')
    },
    openReadMoreTMO () {

    }
  }
}
</script>

<style>
  .myDiv {
    background:url('/img/app/card_lime_light_02.jpeg') no-repeat;
  }
  .myDivDark {
    background:url('/img/app/card_lime_dark.jpeg') no-repeat;
  }
</style>
