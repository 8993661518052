<template>
  <div id="tokencomments" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column align-center v-if="loading" >

         <v-layout column style="width:100%">
           <p class="mt-2"></p>
           <v-spacer></v-spacer>
             <template v-for="n in 5" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="list-item-avatar-two-line"
                 width="100%"
                 height="60"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout >

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else style="width:100%">

           <v-layout column pa-2 pt-4 style="width:100%">
             <v-alert
              border="top"
              colored-border
              color="#388E3C"
              elevation="2"
              :dark="dark"
            >
              <strong>Coming Soon!</strong><br><br>
              Token vetting will be done by Token Monster and the Token Owners when 300 likes are reached.
            </v-alert>
          </v-layout>

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

      </v-layout>
    </v-slide-y-transition>

    <v-snackbar
      :timeout="4000" :bottom="'bottom'" v-model="snackbar"
    >
      <v-icon color="green" style="margin-right:10px">mdi-check-circle-outline</v-icon>
      <span style="padding-left:10px">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#388E3C"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span >{{ snackbarText }}</span>
    </v-snackbar>

  </div>
</template>

<script>
import store from '@/store/index'
import firebase from 'firebase/app'
import { db } from '@/main'
// var resizebase64 = require('resize-base64')
const dateformat = require('dateformat')
export default {
  name: 'tokenVetting',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    tokenItem: Object
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      showBottomNav: false,
      now: dateformat(new Date().getTime(), 'dd mmm, yyyy'),
      show: false,
      resultInterval: null,
      tokenVetting: [],
      report: '',
      firstReply: true,
      replyComment: '',
      replyKeepOpenId: '',
      valid: false,
    }
  },
  components: {
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getUser () {
      return this.$store.state.user
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
    this.loading = true
    // this.tokenComments = []
  },
  mounted () {
  // this.viewWidth = Math.round(this.windowWidth / 2)
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    this.currentUser = firebase.auth().currentUser
    this.init()
  },
  methods: {
    init () {
      // this.getTokenVetting()
      this.loading = false
    },
    checkNew () {
      // console.log('Check new token opened 2...')
      // this.getTokenVetting()
      this.loading = false
    },
    setLoadingTrue () {
      this.loading = true
    },
    getTokenVetting () {
      this.loading = true
      // console.log('Get token vetting details...')
      this.tokenScamReports = []
      this.tokenScamReportsArr = []
      let scamQuery = db.collection('tokens').doc(this.tokenItem.id).collection('vetting').where('status', '==', 2).orderBy('created', 'desc')
      this.commentsRef = scamQuery.onSnapshot(querySnapshot => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`)
        if (querySnapshot.empty) {
          console.log('No matching documents.')
          this.loadingData = false
          this.loading = false
          return
        }
        this.tokenVetting = []
        querySnapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          var obj = doc.data()
          obj.id = doc.id
          this.tokenVetting.push(obj)
          this.loading = false
        })
        this.loadingData = false
        this.loading = false
        // console.log(this.tokenVetting)
        // ...
        }, err => {
          console.log(`Encountered error: ${err}`)
          // this.loadingData = false
        })
    },
    connectWallet() {
      this.$emit('connectWalletClicked')
    },
    makeDate (date) {
      return dateformat(new Date(date), 'dd mmm, yyyy HH:MM')
    },
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e);
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
  .titleText {
    font-size:28px;
    font-weight: 900;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
  .my-text-field-1 .v-input__append-inner .v-icon {
    color: #388E3C;
  }
</style>
