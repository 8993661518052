<template>
  <div id="tokencomments" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column align-center v-if="loading" >

         <v-layout column style="width:100%">
           <p class="mt-2"></p>
           <v-spacer></v-spacer>
             <template v-for="n in 5" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="list-item-avatar-two-line"
                 width="100%"
                 height="60"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout >

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else style="width:100%">

           <v-layout column pa-2 pt-4 style="width:100%" v-if="isMobileDevice">

             <span class="text-center font-weight-bold">How to use Wallet connect with MetaMask on Mobile devices.</span>

             <p class="mt-0"></p>

             <v-stepper
                v-model="e6"
                vertical
              >
                <v-stepper-step
                  :complete="e6 > 1"
                  step="1"
                >
                  Click Connect from the menu
                  <small>Then select "Wallet Connect"</small>
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-card
                    color="grey lighten-1"
                    class="mb-12"
                    height="300px"
                  >
                    <v-img src="img/connect/connect_01.jpeg" height="300">
                    </v-img>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="e6 = 2"
                  >
                    Continue
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step
                  :complete="e6 > 2"
                  step="2"
                >
                  With Mobile Tab selected click connect
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card
                    color="grey lighten-1"
                    class="mb-12"
                  >
                    <v-img src="img/connect/connect_02.jpeg">
                    </v-img>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="e6 = 3"
                  >
                    Continue
                  </v-btn>
                  <v-btn tex @click="e6 = 1">
                    Back
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step
                  :complete="e6 > 3"
                  step="3"
                >
                  Then select MetaMask
                  <small>This will open MetaMask mobile app</small>
                  <small>If not installed, please install first</small>
                </v-stepper-step>

                <v-stepper-content step="3">
                  <v-card
                    color="grey lighten-1"
                    class="mb-12"
                  >
                    <v-img src="img/connect/connect_03.jpeg">
                    </v-img>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="e6 = 4"
                  >
                    Continue
                  </v-btn>
                  <v-btn tex @click="e6 = 2">
                    Back
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step
                  :complete="e6 > 4"
                  step="4">
                  Now Connect
                </v-stepper-step>

                <v-stepper-content step="4">
                  <v-card
                    color="grey lighten-1"
                    class="mb-12"
                  >
                    <v-img src="img/connect/connect_04.jpeg" >
                    </v-img>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="e6 = 5"
                  >
                    Continue
                  </v-btn>
                  <v-btn tex @click="e6 = 3">
                    Back
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step
                  :complete="e6 > 5"
                  step="5"
                >
                  Connection successfull
                  <small>Go back to the Token Monster browser</small>
                </v-stepper-step>

                <v-stepper-content step="5">
                  <v-card
                    color="grey lighten-1"
                    class="mb-12"
                  >
                    <v-img src="img/connect/connect_05.jpeg">
                    </v-img>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="e6 = 6"
                  >
                    Continue
                  </v-btn>
                  <v-btn tex @click="e6 = 4">
                    Back
                  </v-btn>
                </v-stepper-content>


                <v-stepper-step
                  :complete="e6 > 6"
                  step="6"
                >
                  You are now connected
                  <small>See your Account menu for options</small>
                </v-stepper-step>

                <v-stepper-content step="6">
                  <v-card
                    color="grey lighten-1"
                    class="mb-12"
                  >
                    <v-img src="img/connect/connect_06.jpeg">
                    </v-img>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="e6 = 1"
                  >
                    Done
                  </v-btn>
                  <v-btn tex @click="e6 = 5">
                    Back
                  </v-btn>
                </v-stepper-content>

              </v-stepper>

          </v-layout>

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

      </v-layout>
    </v-slide-y-transition>

    <v-snackbar
      :timeout="4000" :bottom="'bottom'" v-model="snackbar"
    >
      <v-icon color="green" style="margin-right:10px">mdi-check-circle-outline</v-icon>
      <span style="padding-left:10px">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#388E3C"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span >{{ snackbarText }}</span>
    </v-snackbar>

  </div>
</template>

<script>
import store from '@/store/index'
// var resizebase64 = require('resize-base64')
const dateformat = require('dateformat')
export default {
  name: 'learnMore',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    isMobileDevice: Boolean
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      e6: 1,
      now: dateformat(new Date().getTime(), 'dd mmm, yyyy'),
      show: false
    }
  },
  components: {
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getUser () {
      return this.$store.state.user
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    }
  },
  beforeDestroy () {
    // clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
    this.loading = true
    // this.tokenComments = []
  },
  mounted () {
  // this.viewWidth = Math.round(this.windowWidth / 2)
  },
  created () {
    store.commit('ShowNav')
    this.init()
  },
  methods: {
    init () {
      // this.getTokenVetting()
      this.loading = false
    },
    makeDate (date) {
      return dateformat(new Date(date), 'dd mmm, yyyy HH:MM')
    },
    handleSuccess(e) {
      console.log(e);
    },
    handleError(e) {
      console.log(e);
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
  .titleText {
    font-size:28px;
    font-weight: 900;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
  .my-text-field-1 .v-input__append-inner .v-icon {
    color: #388E3C;
  }
</style>
