<template>
  <v-sheet
    class="mx-auto"
    :dark="dark"
    :style="dark ? 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(69,110,112,1) 0%, rgba(52,52,52,1) 100%);' : 'width: 100%;height: auto;background: linear-gradient(180deg, rgba(112,131,132,1) 0%, rgba(234,254,255,1) 100%)'"
    max-width="100%"
    tile
  >
  <v-layout column align-center :style="windowWidth < 800 ? '' : 'margin-right:80px;margin-left:80px'">
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <div class="display-1 text--center">Meet the Team</div>
      <p class="mt-1"></p>
      <div class="title text--center" :style="windowWidth < 770 ? 'padding-right:40px;padding-left:40px' : ''">TEAM THAT IS WORKING FOR YOUR SUCCESS</div>
      <p class="mt-2"></p>
  </v-layout>

      <v-row :align="windowWidth < 770 ? 'center' : ''" :style="windowWidth < 770 ? 'padding-right:40px;padding-left:40px' : 'margin-right:10%;margin-left:10%'">
        <template v-for="(item, index) in team1">
            <v-col
              :key="index + 'C'"
              :cols="windowWidth < 770 ? 12 : 4"
            >
            <v-layout :key="index + 'A'" column :align-center="windowWidth < 800">
              <v-card
                class="ma-4"
                max-height="170"
                max-width="170"
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-scale-transition>
                    <v-img :src="item.src" :size="170" style="border-radius:5px"></v-img>
                  </v-scale-transition>
                </v-row>
              </v-card>
              <p class="mt-2"></p>
              <v-layout>
                <span style="padding-left:17px;font-size:18px" class="subheading font-weight-medium">{{ item.name }}</span>
                <v-icon style="margin-left:90px" color="blue" @click="gotoLinkedIn(item)">{{ index === 2 ? 'mdi-telegram'  : 'mdi-linkedin'}}</v-icon>
              </v-layout>
              <span style="padding-left:17px;" class="font-weight-bold">{{ item.title }}</span>
              <p class="mt-0"></p>
              <p style="padding-left:17px;width:180px" class="text-wrap body-2" v-html="item.readmore ? item.story : checkStoryLength1(item.story)"></p>
              <v-btn style="width:180px;margin-top:-10px" v-if="!item.readmore && item.story.length > 150" text small color="blue" @click="readMoreClicked1(item, index)">
                <v-icon small color="blue">mdi-chevron-down</v-icon>
                Read more
              </v-btn>
            </v-layout>
          </v-col>
        </template>
      </v-row>

      <p class="mt-4"></p>
      <p class="mt-4"></p>

      <!-- <v-row :align="windowWidth < 800 ? 'center' : ''">
        <template v-for="(item, index) in team3">
            <v-col
              :key="index + 'C'"
              :cols="windowWidth < 800 ? 12 : 4"
            >
            <v-layout :key="index + 'A'" column :align-center="windowWidth < 800">
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                height="170"
                width="170"
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-scale-transition>
                    <v-img :src="item.src" :size="170" style="border-radius:5px"></v-img>
                  </v-scale-transition>
                </v-row>
              </v-card>
              <p class="mt-2"></p>
              <v-layout>
                <span style="padding-left:17px" class="subheading font-weight-medium">{{ item.name }}</span>
                <v-spacer></v-spacer>
                <v-icon color="blue" @click="gotoLinkedIn(item)">{{ index === 4 ? 'mdi-instagram' : 'mdi-linkedin'}}</v-icon>
              </v-layout>
              <span style="padding-left:17px" class="body-2 grey--text font-weight-bold">{{ item.title }}</span>
              <p class="mt-0"></p>
              <p style="padding-left:17px;width:180px" class="text-wrap body-2 grey--text" v-html="item.readmore ? item.story : checkStoryLength2(item.story)"></p>
              <v-btn v-if="!item.readmore && item.story.length > 200" text small color="blue" @click="readMoreClicked3(item, index)"><v-icon small color="blue">mdi-chevron-down</v-icon>Read more</v-btn>
            </v-layout>
          </v-col>
        </template>
      </v-row> -->

    <!-- <v-expand-transition>
      <v-layout column align-center>
        <v-sheet
          v-if="model != null"
          color="grey lighten-4"
          height="250"
          width="300"
          tile
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <h3 class="title">Selected {{ model }}</h3>
          </v-row>
        </v-sheet>
        <p class="mt-4"></p>
      </v-layout>
    </v-expand-transition> -->
  </v-sheet>
</template>

<script>
export default {
  name: 'team',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number
  },
  data () {
    return {
      model: null,
      active: true,
      team1: [
        {
          name: 'Martin',
          title: 'Fullstack Dev',
          story: 'Martin is a Creative, results driven technology leader with over 20 years of IT, Telecoms, FinTech and Energy Industry experience. He\'s experienced in Blockchain oriented startups and has a passion for technology and innovation.<br><br>Martin has Co-founded several IT companies and is skilful in understanding the big picture, conceptualising, developing and implementing solutions. He excels in developing and motivating teams that successfully meet and achieve objectives.',
          src: './img/team/martin_01.png',
          // linkedin: 'https://www.linkedin.com/in/martin-kuijs-b144766a/',
          linkedin: '',
          readmore: false
        },
        {
          name: 'Davey',
          title: 'Tokenomics & Dev',
          story: 'Bonezyard Music Platform, Blockchain developer, Crypto currency enthusiast, and musician. <br><br> After finishing his diploma in hard and software engineering David has worked in the computer industry leading over the last few years, into blockchain projects.',
          src: './img/team/davey.png',
          linkedin: '',
          readmore: false
        },
        {
          name: 'Naima',
          title: 'Admin & Crowd Control',
          story: 'Naima loves crypto and is very strong in graphical design and administration. <br><br> She will help moderating and answering all your questions on Telegram and our other channels.',
          src: './img/team/naima_01.png',
          linkedin: 'https://t.me/Token_Monster',
          readmore: false
        },
        {
          name: 'Richard',
          title: 'Legal, Marketing & Announcements',
          story: 'Richard has varied business skills honed in a myriad of industries and environments sharpened with a touch of OCD. Richard brings his unique style to his roles at Token Monster.',
          src: './img/team/richard_01.png',
          linkedin: '',
          // linkedin: 'https://www.linkedin.com/in/richard-clark-829117169/',
          readmore: false
        },
        {
          name: 'Hendo',
          title: 'Head of Marketing',
          story: 'David is Director of Global Partnerships and Senior Marketing lecturer at Monash College. He has been involved in Service Marketing consultancy work for over 20 years covering a wide range of industries including health, tourism and now financial services. An early crypto investor, music fanatic and dreadful golfer.',
          src: './img/team/hendo.png',
          linkedin: '',
          // linkedin: 'https://www.linkedin.com/in/david-henderson-52a520106/',
          readmore: false
        },
      ]
    }
  },
  watch: {
  },
  created () {
    // this.windowWidth = this.$props.windowindowWidth
  },
  methods: {
    checkStoryLength1 (story) {
      if (story.length < 85) {
        return story
      } else {
        return story.substr(0, 85) + '...'
      }
    },
    checkStoryLength2 (story) {
      if (story.length < 95) {
        return story
      } else {
        return story.substr(0, 95) + '...'
      }
    },
    readMoreClicked1 (item, index) {
      this.team1[index].readmore = true
    },
    readMoreClicked2 (item, index) {
      this.team2[index].readmore = true
    },
    readMoreClicked3 (item, index) {
      this.team3[index].readmore = true
    },
    gotoLinkedIn (item) {
      window.open(item.linkedin, '_blank')
    },
  }
}
</script>

<style>
.wrapper{
  background: #fafafa;
  display: flex;
  justify-content: center;
}
.cardsH {
  background: #FFF;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: grid;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}
.cardsV {
  background: #FFF;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: table-column;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}
</style>
