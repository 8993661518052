<template>
  <div class="home" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout column v-if="loading" :style="windowWidth < 770 ? 'margin-top:30px' : 'margin-top:30px;margin-left:5%;margin-right:5%'">

          <v-layout >
           <v-spacer></v-spacer>
             <template >
               <v-skeleton-loader
                 class="mx-auto"
                 width="70%"
                 height="60"
                 type="image"
               ></v-skeleton-loader>
             </template>
           <v-spacer></v-spacer>
         </v-layout>

         <p class="mt-2"></p>

          <v-layout :column="windowWidth < 770" pa-4>
            <v-skeleton-loader
              class="mx-auto"
              :width="windowWidth < 770 ? '95%' : '39%'"
              height="500"
              type="image"
            ></v-skeleton-loader>

            <v-spacer v-if="windowWidth > 770" style="width:300px"></v-spacer>
            <p class="mt-0" v-if="windowWidth < 770"></p>

            <v-skeleton-loader
              class="mx-auto"
              :width="windowWidth < 770 ? '95%' : '39%'"
              height="500"
              type="image"
            ></v-skeleton-loader>
          </v-layout>

          <v-layout v-if="windowWidth > 770">
           <v-spacer></v-spacer>
             <template v-for="n in 3" >
               <v-skeleton-loader
                 class="mx-auto"
                 :width="windowWidth < 770 ? '50' : '250'"
                 :height="windowWidth < 770 ? '50' : '50'"
                 type="image"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'" v-if="n < 3"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout>


        </v-layout>

        <v-layout column align-center v-else-if="slugLoaded" style="margin-top:150px">
          <v-progress-circular
             indeterminate
             color="#1B5E20"
           ></v-progress-circular>
          <p class="mt-2"></p>
        </v-layout>

        <!-- ############################################## COMPONENTS  ##############################################  -->

        <v-layout column v-else style="margin-top:-64px">

          <v-layout id="home" column >

            <v-card flat
              style="border-radius:0px"
              color="black"
              :width="windowWidth < 770 ? '' : '100%'"
              min-height="750"
              :class="dark ? 'myBgDark' : 'myBgLight'"
            >
              <!-- <v-img
                :src="dark ? 'img/home/home_dark_01.png' : 'img/home/home_light_01.jpeg'"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.4)"
                :height="windowWidth < 768 ? '350' : windowHeight"
                :max-height="windowHeight"
              > -->

                <v-layout column style="margin-bottom:30px;" v-if="windowWidth > 770 && show">
                  <!-- <span style="margin-bottom:50px;" class="homeTextLarge">Everybody likes me!</span> -->
                  <!-- <v-speed-dial
                    style="margin-top:70px"
                    v-model="fab"
                    top
                    right
                    fixed
                    direction="left"
                    open-on-hover
                    transition="slide-y-reverse-transition"
                  >
                    <template v-slot:activator>
                      <v-btn
                      style="margin-top:70px"
                        v-model="fab"
                        fixed
                        fab
                        :dark="!dark"
                        top
                        right
                        color="#1B5E20"
                      >
                          <v-icon>mdi-wallet</v-icon>
                      </v-btn>
                    </template>
                    <v-btn
                      fab
                      dark
                      small
                      color="green"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      dark
                      small
                      color="red"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-speed-dial> -->

                  <v-layout column align-center style="margin-top:70px;">

                    <v-carousel
                      v-model="titleCarousel"
                      cycle
                      interval="4000"
                      :show-arrows="false"
                      hide-delimiters
                      :height="windowWidth < 770 ? 60 : 80"
                      >
                      <v-carousel-item>
                        <p :class="dark ? 'homeTitle white--text font-weight-bold mt-4' : 'homeTitle font-weight-bold mt-4'">
                          Explore and promote multichain projects & tokens
                        </p>
                      </v-carousel-item>
                      <v-carousel-item>
                        <p :class="dark ? 'homeTitle white--text font-weight-bold mt-4' : 'homeTitle font-weight-bold mt-4'">
                          Evaluate new tokens before and after they launch
                        </p>
                      </v-carousel-item>
                      <v-carousel-item>
                        <p :class="dark ? 'homeTitle white--text font-weight-bold mt-4' : 'homeTitle font-weight-bold mt-4'">
                          Switch seamlessly between multiple chains
                        </p>
                      </v-carousel-item>
                    </v-carousel>
                    <p class="mt-4"></p>
                  </v-layout>

                  <transition name="fade">
                    <v-layout row wrap :style="windowWidth < 1400 ? '' : 'margin-right:20px;margin-left:20px;margin-bottom:20px'">

                      <v-col
                         cols="12"
                         sm="5"
                       >
                         <v-hover
                           v-slot:default="{ hover }"
                           open-delay="200"
                         >
                           <v-card
                             :elevation="hover ? 16 : 2"
                             class="mx-auto"
                             height="500"
                             max-width="500"
                             style="background: linear-gradient(180deg, rgba(43,119,3,1) 0%, rgba(214,255,193,1) 100%)"
                           >
                           <v-toolbar flat color="transparent" pa-2 pb-0>
                             <v-layout column align-center>
                               <p class="cardTitle font-weight-bold mt-2">
                                 PROMOTED TOKENS
                               </p>
                             </v-layout>
                           </v-toolbar>

                           <v-layout column align-center v-if="loadingPromoted">
                             <v-progress-circular
                                indeterminate
                                color="#1B5E20"
                              ></v-progress-circular>
                             <p class="mt-2"></p>
                           </v-layout>

                            <v-layout column align-center style="margin-top:-25px" pr-2 pl-2>
                              <v-list dense shaped color="transparent" style="width:100%">
                                <template v-for="(item, index) in localPromotedTokens">
                                 <v-list-item
                                   link
                                   :key="index"
                                   style="height:55px"
                                   @click="openTokenDetail(item)"
                                 >
                                 <v-list-item-avatar style="border-radius:2px">
                                    <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                                    <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                                  </v-list-item-avatar>

                                   <v-list-item-content>
                                     <span :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:17px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }}) </span>
                                     <v-list-item-subtitle class="wrap-text font-weight-bold" style="margin-top:5px">MC ${{ numberWithCommas(item.mc) }} - {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.holders) }} Holders</v-list-item-subtitle>
                                   </v-list-item-content>

                                   <v-list-item-action>
                                    <v-layout >
                                      <v-tooltip right v-if="item.scam_count > 0">
                                        <template v-slot:activator="{ on }">
                                          <v-btn icon v-on="on" style="margin-right:15px;margin-top:-2px">
                                            <v-icon color="amber">
                                              mdi-alert-outline
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Someone has reported a scam alert!</span>
                                      </v-tooltip>

                                      <v-layout column align-center>
                                        <v-btn icon small color="#1B5E20" @click.stop="likeThisItem(item)">
                                          <v-icon>
                                            {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                                          </v-icon>
                                        </v-btn>
                                        <span style="font-size:10px"> {{ item.likes }} </span>
                                      </v-layout>
                                    </v-layout>
                                   </v-list-item-action>

                                 </v-list-item>
                                 <v-divider
                                   v-if="parseInt(index + 1) < getPromotedTokens.length"
                                   :key="index + 'd'"
                                 ></v-divider>
                               </template>
                             </v-list>

                             <v-btn to="/tokens" text color="#1B5E20" style="width:250px">
                               <v-icon left size="25">mdi-chevron-right</v-icon>Show All
                             </v-btn>

                           </v-layout>
                           </v-card>
                         </v-hover>
                       </v-col>

                       <v-col
                          cols="12"
                          sm="2"
                        >
                        <v-layout column align-center style="margin-top:-20px">
                          <v-img v-if="!dark" :src="logoSrc" width="160" height="160" style="filter: drop-shadow(-3px 3px 3px #454545);-webkit-filter: drop-shadow(-3px 3px 3px #454545);"></v-img>
                          <v-img v-if="dark" :src="logoSrcDark" width="160" height="160" style="filter: drop-shadow(-1px 1px 1px #000);-webkit-filter: drop-shadow(-1px 1px 1px #000);"></v-img>

                          <p class="mt-2"></p>

                          <v-btn style="width:200px;" shaped color="lime lighten-5" large to="/tokens">See All Tokens</v-btn>

                          <v-card
                            class="mx-auto"
                            :dark="dark"
                            height="170"
                            :min-width="drawer || windowWidth < 1200 ? 170 : 200"
                            max-width="200"
                            :style="dark ? 'margin-top:122px;background: linear-gradient(180deg, rgba(33,82,35,1) 0%, rgba(59,90,92,1) 100%);' : 'margin-top:122px;background: linear-gradient(180deg, rgba(216,255,196,1) 0%, rgba(255,255,255,1) 100%)'"
                          >
                          <!-- <p style="font-size:14px" class="text-center font-weight-bold mt-2">
                            TMO Launch Countdown
                          </p> -->

                          <p style="font-size:16px" class="text-center font-weight-bold mt-2">
                            TMO Token<br>
                            Launching soon!<br><br>
                          </p>

                          <!-- <v-layout column align-center style="margin-top:-15px">
                            <v-btn color="green darken-1" small  to="/privatesale" style="font-size:12px;text-transform: none !important;">
                              Join Private Sale
                            </v-btn>
                            <p style="font-size:14px" class="text-center font-weight-bold mt-2">
                              * Limited Allocation *<br>
                                * register early *
                            </p>
                          </v-layout> -->

                          <!-- <vue-countdown :time="launch" v-slot="{ days, hours, minutes, seconds }">
                            <v-layout column align-center pl-2 pr-2>
                              <p style="margin-top:-15px;" :class="dark ? 'clockDarkText text-center;' : 'clockText text-center'">{{ days }} Days</p>
                              <v-layout style="width:100%;margin-top:-25px">
                                <v-spacer></v-spacer>
                                <span :class="dark ? 'clockDark' : 'clock'">{{ hours }}</span>
                                <v-spacer></v-spacer>
                                <span :class="dark ? 'clockDark' : 'clock'">{{ minutes }}</span>
                                <v-spacer></v-spacer>
                                <span :class="dark ? 'clockDark' : 'clock'">{{ seconds }}</span>
                                <v-spacer></v-spacer>
                              </v-layout>
                              <v-layout style="width:100%;margin-top:-10px">
                                <v-spacer></v-spacer>
                                <span >Hrs</span>
                                <v-spacer></v-spacer>
                                <span >Min</span>
                                <v-spacer></v-spacer>
                                <span >Sec</span>
                                <v-spacer></v-spacer>
                              </v-layout>
                            </v-layout>
                          </vue-countdown> -->
                          </v-card>
                        </v-layout>
                      </v-col>

                      <v-col
                          cols="12"
                          sm="5"
                        >
                          <v-hover
                            v-slot:default="{ hover }"
                            open-delay="200"
                          >
                            <v-card
                              :elevation="hover ? 16 : 2"
                              class="mx-auto"
                              height="500"
                              max-width="470"
                              style="background: linear-gradient(180deg, rgba(43,119,3,1) 0%, rgba(214,255,193,1) 100%)"
                            >
                            <v-toolbar flat color="transparent" >
                              <!-- <v-icon :dark="!dark" x-large >mdi-key-outline</v-icon> -->
                              <v-layout column align-center>
                                <p class="cardTitle font-weight-bold mt-2">
                                  PROMOTED LAUNCH QUEUE
                                </p>
                              </v-layout>
                            </v-toolbar>

                            <v-layout column align-center v-if="loadingPromoted">
                              <v-progress-circular
                                 indeterminate
                                 color="#1B5E20"
                               ></v-progress-circular>
                              <p class="mt-2"></p>
                            </v-layout>

                             <v-layout column align-center style="margin-top:-25px" pr-2 pl-2>
                               <v-list dense shaped color="transparent" style="width:100%">
                                 <template v-for="(item, index) in localLaunchQueueTokens">
                                  <v-list-item
                                    link
                                    :key="index"
                                    style="height:55px"
                                    @click="openTokenDetail(item)"
                                  >
                                  <v-list-item-avatar style="border-radius:2px">
                                     <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                                     <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                                   </v-list-item-avatar>

                                    <v-list-item-content>

                                      <v-layout column >
                                          <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                            <v-chip  v-if="item.presale"
                                              color="grey" label
                                              x-small
                                            >
                                              Presale
                                            </v-chip>
                                          </span>
                                          <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                            <v-chip  v-if="item.presale && nowList < item.launch_date"
                                              color="yellow darken-1" label
                                              x-small
                                            >
                                              Presale Live
                                            </v-chip>
                                          </span>
                                       </v-layout>

                                      <v-list-item-subtitle class="wrap-text font-weight-bold" style="margin-top:5px">
                                        <vue-countdown v-if="item.presale && nowList < item.launch_date_presale" :time="item.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                                          Presale in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}
                                        </vue-countdown>
                                        <vue-countdown v-else :time="item.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                                          Launching in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}
                                        </vue-countdown>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                     <v-layout >
                                       <v-icon color="amber" v-if="item.scam_count > 0" style="margin-right:5px">
                                         mdi-alert-outline
                                       </v-icon>
                                       <v-layout column align-center>
                                         <v-btn icon small color="#1B5E20" @click.stop="likeThisItem(item)">
                                           <v-icon>
                                             {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                                           </v-icon>
                                         </v-btn>
                                         <span style="font-size:10px"> {{ item.likes }} </span>
                                       </v-layout>
                                     </v-layout>
                                    </v-list-item-action>

                                  </v-list-item>
                                  <v-divider
                                    v-if="parseInt(index + 1) < getLaunchQueueTokens.length"
                                    :key="index + 'd'"
                                  ></v-divider>
                                </template>
                              </v-list>

                              <v-btn to="/tokens/queue" text color="#1B5E20" style="width:250px">
                                <v-icon left size="25">mdi-chevron-right</v-icon>Show All
                              </v-btn>

                            </v-layout>

                            </v-card>
                          </v-hover>
                        </v-col>

                        <v-layout style="margin-top:30px;">
                          <v-spacer></v-spacer>
                          <v-btn style="width:260px;" color="lime lighten-5" x-large to="/promote">Add / Promote a Token</v-btn>
                          <v-spacer ></v-spacer>
                          <v-spacer ></v-spacer>
                          <!-- <v-btn style="width:260px;" color="lime lighten-5" x-large to="/tokens">See All Tokens</v-btn> -->
                          <v-spacer ></v-spacer>
                          <v-btn style="width:260px;" color="lime lighten-5"  v-scroll-to="'#about'" x-large>Learn More</v-btn>
                          <v-spacer></v-spacer>
                        </v-layout>

                    </v-layout>
                  </transition>
                </v-layout>

                <!-- ############################### MOBILE VIEW ###############################-->

                <v-layout column style="margin-top:50px" v-if="windowWidth < 770 && show">

                    <v-carousel
                      v-model="titleCarousel"
                      cycle
                      interval="4000"
                      hide-delimiters
                      :show-arrows="false"
                      :height="windowWidth < 770 ? 70 : 80"
                      >
                      <v-carousel-item >
                        <p :class="dark ? 'homeTitleMobile white--text font-weight-bold mt-4' : 'homeTitleMobile font-weight-bold mt-4'">
                          Explore and promote multichain projects & tokens
                        </p>
                      </v-carousel-item>
                      <v-carousel-item>
                        <p :class="dark ? 'homeTitleMobile white--text font-weight-bold mt-4' : 'homeTitleMobile font-weight-bold mt-4'">
                          Evaluate new tokens before and after they launch!
                        </p>
                      </v-carousel-item>
                      <v-carousel-item>
                        <p :class="dark ? 'homeTitleMobile white--text font-weight-bold mt-4' : 'homeTitleMobile font-weight-bold mt-4'">
                          Switch seamlessly between multiple chains
                        </p>
                      </v-carousel-item>
                    </v-carousel>

                  <v-col
                     cols="12"
                     sm="12"
                   >
                     <v-hover
                       v-slot:default="{ hover }"
                       open-delay="200"
                     >
                       <v-card
                         :elevation="hover ? 16 : 2"
                         class="mx-auto"
                         height="500"
                         style="background: linear-gradient(180deg, rgba(43,119,3,1) 0%, rgba(214,255,193,1) 100%)"
                       >
                       <v-toolbar flat color="transparent" pa-2 pb-0>
                         <v-layout column align-center>
                           <p class="cardTitleMobile font-weight-bold mt-2">
                             PROMOTED TOKENS
                           </p>
                         </v-layout>
                       </v-toolbar>

                       <v-layout column align-center v-if="loadingPromoted">
                         <v-progress-circular
                            indeterminate
                            color="#1B5E20"
                          ></v-progress-circular>
                         <p class="mt-2"></p>
                       </v-layout>

                        <v-layout column align-center style="margin-top:-25px" pr-2 pl-2>
                          <v-list dense shaped color="transparent" style="width:100%">
                            <template v-for="(item, index) in filterPromotedTokens">
                             <v-list-item
                               link
                               :key="index"
                               style="height:55px"
                               @click="openTokenDetail(item)"
                             >
                             <v-list-item-avatar style="border-radius:2px">
                                <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                                <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                              </v-list-item-avatar>

                               <v-list-item-content>

                                 <v-layout column >
                                     <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                       <v-chip  v-if="item.presale"
                                         color="grey" label
                                         x-small
                                       >
                                         Presale
                                       </v-chip>
                                     </span>
                                     <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                       <v-chip  v-if="item.presale && nowList < item.launch_date"
                                         color="yellow darken-1" label
                                         x-small
                                       >
                                         Presale Live
                                       </v-chip>
                                     </span>
                                  </v-layout>

                                 <v-list-item-subtitle class="wrap-text font-weight-bold" style="margin-top:5px">MC ${{ numberWithCommas(item.mc) }} - {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ item.holders }} Holders</v-list-item-subtitle>
                               </v-list-item-content>

                               <v-list-item-action>
                                <v-layout >
                                  <v-icon color="amber" v-if="item.scam_count > 0" style="margin-right:5px">
                                    mdi-alert-outline
                                  </v-icon>
                                  <v-layout column align-center>
                                    <v-btn icon small color="#1B5E20" @click.stop="likeThisItem(item)">
                                      <v-icon>
                                        {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                                      </v-icon>
                                    </v-btn>
                                    <span style="font-size:10px"> {{ item.likes }} </span>
                                  </v-layout>
                                </v-layout>
                               </v-list-item-action>

                             </v-list-item>
                             <v-divider
                               v-if="parseInt(index + 1) < getPromotedTokens.length"
                               :key="index + 'd'"
                             ></v-divider>
                           </template>
                         </v-list>

                         <v-btn to="/tokens" text color="#1B5E20" style="width:250px">
                           <v-icon left size="25">mdi-chevron-right</v-icon>Show All
                         </v-btn>

                       </v-layout>
                       </v-card>
                     </v-hover>
                   </v-col>

                   <v-col
                       cols="12"
                       sm="12"
                     >
                       <v-hover
                         v-slot:default="{ hover }"
                         open-delay="200"
                       >
                         <v-card
                           :elevation="hover ? 16 : 2"
                           class="mx-auto"
                           height="500"
                           style="background: linear-gradient(180deg, rgba(43,119,3,1) 0%, rgba(214,255,193,1) 100%)"
                         >
                         <v-toolbar flat color="transparent" >
                           <!-- <v-icon :dark="!dark" x-large >mdi-key-outline</v-icon> -->
                           <v-layout column align-center>
                             <p class="cardTitleMobile font-weight-bold mt-2">
                               PROMOTED LAUNCH QUEUE
                             </p>
                           </v-layout>
                         </v-toolbar>

                         <v-layout column align-center v-if="loadingPromoted">
                           <v-progress-circular
                              indeterminate
                              color="#1B5E20"
                            ></v-progress-circular>
                           <p class="mt-2"></p>
                         </v-layout>

                          <v-layout column align-center style="margin-top:-25px" pr-2 pl-2>
                            <v-list dense shaped color="transparent" style="width:100%">
                              <template v-for="(item, index) in filterLaunchQueueTokens">
                               <v-list-item
                                 link
                                 :key="index"
                                 style="height:55px"
                                 @click="openTokenDetail(item)"
                               >
                               <v-list-item-avatar style="border-radius:2px">
                                  <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                                  <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                                </v-list-item-avatar>

                                 <v-list-item-content>

                                   <v-layout column >
                                       <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                         <v-chip  v-if="item.presale"
                                           color="grey" label
                                           x-small
                                         >
                                           Presale
                                         </v-chip>
                                       </span>
                                       <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                         <v-chip  v-if="item.presale && nowList < item.launch_date"
                                           color="yellow darken-1" label
                                           x-small
                                         >
                                           Presale Live
                                         </v-chip>
                                       </span>
                                    </v-layout>

                                   <v-list-item-subtitle class="wrap-text font-weight-bold" style="margin-top:5px">

                                     <vue-countdown v-if="item.presale && nowList < item.launch_date_presale" :time="item.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                                       <v-icon small style="margin-top:-3px">mdi-clock-start</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}
                                     </vue-countdown>
                                     <vue-countdown v-else :time="item.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                                        <v-icon small style="margin-top:-3px">mdi-rocket-launch</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}
                                     </vue-countdown>

                                   </v-list-item-subtitle>
                                 </v-list-item-content>

                                 <v-list-item-action>
                                  <v-layout >
                                    <v-icon color="amber" v-if="item.scam_count > 0" style="margin-right:5px">
                                      mdi-alert-outline
                                    </v-icon>
                                    <v-layout column align-center>
                                      <v-btn icon small color="#1B5E20" @click.stop="likeThisItem(item)">
                                        <v-icon>
                                          {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                                        </v-icon>
                                      </v-btn>
                                      <span style="font-size:10px"> {{ item.likes }} </span>
                                    </v-layout>
                                  </v-layout>
                                 </v-list-item-action>

                               </v-list-item>
                               <v-divider
                                 v-if="parseInt(index + 1) < getLaunchQueueTokens.length"
                                 :key="index + 'd'"
                               ></v-divider>
                             </template>
                           </v-list>

                           <v-btn to="/tokens/queue" text color="#1B5E20" style="width:250px">
                             <v-icon left size="25">mdi-chevron-right</v-icon>Show All
                           </v-btn>

                         </v-layout>

                         </v-card>
                       </v-hover>
                     </v-col>

                     <v-col
                        cols="12"
                        sm="2"
                      >
                      <v-layout column align-center >
                        <v-card
                          class="mx-auto"
                          :dark="dark"
                          height="170"
                          width="100%"
                          :style="dark ? 'background: linear-gradient(180deg, rgba(87,94,6,1) 0%, rgba(75,75,75,1) 100%);' : 'background: linear-gradient(180deg, rgba(214,255,193,1) 0%, rgba(255,255,255,1) 100%);'"
                        >
                        <!-- <p style="font-size:14px" class="text-center font-weight-bold mt-2">
                          TMO Launch Countdown
                        </p> -->

                        <p style="font-size:16px" class="text-center font-weight-bold mt-2">
                          TMO Token<br>
                          Launching soon!<br><br>
                        </p>

                        <!-- <v-layout column align-center style="margin-top:-15px">
                          <v-btn color="green darken-1" small  to="/privatesale" style="font-size:12px;text-transform: none !important;">
                            Join the Private Sale
                          </v-btn>
                          <p style="font-size:14px" class="text-center font-weight-bold mt-2">
                            * Limited Allocation *<br>
                              * register early *
                          </p>
                        </v-layout> -->

                        <!-- <vue-countdown :time="launch" v-slot="{ days, hours, minutes, seconds }">
                          <v-layout column align-center pl-2 pr-2>
                            <p style="margin-top:-15px;" :class="dark ? 'clockDarkText text-center;' : 'clockText text-center'">{{ days }} Days</p>
                            <v-layout style="width:100%;margin-top:-25px">
                              <v-spacer></v-spacer>
                              <span :class="dark ? 'clockDark' : 'clock'">{{ hours }}</span>
                              <v-spacer></v-spacer>
                              <span :class="dark ? 'clockDark' : 'clock'">{{ minutes }}</span>
                              <v-spacer></v-spacer>
                              <span :class="dark ? 'clockDark' : 'clock'">{{ seconds }}</span>
                              <v-spacer></v-spacer>
                            </v-layout>
                            <v-layout style="width:100%;margin-top:-10px">
                              <v-spacer></v-spacer>
                              <span >Hrs</span>
                              <v-spacer></v-spacer>
                              <span >Min</span>
                              <v-spacer></v-spacer>
                              <span >Sec</span>
                              <v-spacer></v-spacer>
                            </v-layout>
                          </v-layout>
                        </vue-countdown> -->
                        </v-card>
                      </v-layout>
                    </v-col>

                    <v-layout column pa-3>
                      <v-btn style="width:100%;" color="lime lighten-5"  to="/promote">Add / Promote a Token</v-btn>
                      <p class="mt-0"></p>
                      <v-btn style="width:100%;" color="lime lighten-5"  to="/tokens">See All Tokens</v-btn>
                      <p class="mt-0"></p>
                      <v-btn style="width:100%;" color="lime lighten-5"  v-scroll-to="'#about'" >Learn More</v-btn>
                      <p class="mt-0"></p>
                    </v-layout>

                </v-layout>
            </v-card>
          </v-layout>

          <HowToBuy id="buytmo" :dark="dark" :drawer="drawer" :windowWidth="windowWidth" ></HowToBuy>

          <About id="about" :dark="dark" :drawer="drawer" :windowWidth="windowWidth" v-on:realnessClicked="openRealness"></About>

          <v-parallax
            :height="windowWidth < 770 ? 500 : 350"
            src="/img/home/paralax_01.jpeg"
          >
            <p class="mt-2"></p>
            <span style="font-size:26px" class="text-center white--text">Supported Chains</span>
            <p class="mt-2"></p>
            <v-layout row wrap :style="windowWidth < 770 ? 'text-align: center;paddin-bottom:10px' : 'margin-right:20px;margin-left:20px;text-align: center;max-height:120px'">
              <v-col
                 cols="12"
                 sm="3"
                 :style="windowWidth < 770 ? 'max-height:50px' : ''"
               >
                 <v-btn color="white" text x-large style="text-transform: none !important;">
                   <v-img :src="srcBinance" max-width="32" max-height="32" style="margin-right:10px">
                   </v-img>Binance Smart Chain
                 </v-btn>
             </v-col>

             <v-col
                cols="12"
                sm="3"
                :style="windowWidth < 770 ? 'max-height:50px' : ''"
              >
                <v-btn color="white" text x-large style="text-transform: none !important;">
                  <v-icon x-large color="blue-grey lighten-1">
                    mdi-ethereum
                  </v-icon>Ethereum
                </v-btn>
             </v-col>

             <v-col
               cols="12"
               sm="3"
               :style="windowWidth < 770 ? 'max-height:50px' : ''"
             >
               <v-btn color="white" text x-large style="text-transform: none !important;">
                 <v-img :src="srcPolygon" max-width="32" max-height="32" style="margin-right:10px">
                 </v-img>Polygon (MATIC)
               </v-btn>
             </v-col>

             <v-col
               cols="12"
               sm="3"
               :style="windowWidth < 770 ? 'max-height:50px' : ''"
             >
               <v-btn color="white" text x-large style="text-transform: none !important;">
                 <v-img :src="srcKuCoin" max-width="32" max-height="32" style="margin-right:10px">
                 </v-img>KuCoin (KCC)
               </v-btn>
            </v-col>

           </v-layout>

           <p class="mt-2"></p>
           <span style="font-size:20px" class="text-center white--text">More coming soon...</span>
           <p class="mt-1"></p>
           <v-layout row wrap :style="windowWidth < 770 ? 'text-align: center;' : 'margin-right:20px;margin-left:20px;text-align: center;max-height:120px'">

            <v-col
              cols="12"
              sm="2"
              :style="windowWidth < 770 ? 'max-height:50px' : ''"
            >
              <v-btn color="white" text x-large style="text-transform: none !important;">
                <v-img :src="srcPolkadot" max-width="32" max-height="32" style="margin-right:10px">
                </v-img>Polkadot (DOT)
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              :style="windowWidth < 770 ? 'max-height:50px' : ''"
            >
              <v-btn color="white" text x-large style="text-transform: none !important;">
                <v-img :src="srcCardano" max-width="32" max-height="32" style="margin-right:10px">
                </v-img>Cardano (ADA)
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              :style="windowWidth < 770 ? 'max-height:50px' : ''"
            >
              <v-btn color="white" text x-large style="text-transform: none !important;">
                <v-img :src="srcHeco" max-width="32" max-height="32" style="margin-right:10px">
                </v-img>Heco (HT)
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              :style="windowWidth < 770 ? 'max-height:50px' : ''"
            >
              <v-btn color="white" text x-large style="text-transform: none !important;">
                <v-img :src="srcSolana" max-width="32" max-height="32" style="margin-right:10px">
                </v-img>Solana (SOL)
              </v-btn>
            </v-col>

          <v-col
            cols="12"
            sm="2"
            :style="windowWidth < 770 ? 'max-height:50px' : ''"
          >
            <v-btn color="white" text x-large style="text-transform: none !important;">
              <v-img :src="srcTron" max-width="32" max-height="32" style="margin-right:10px">
              </v-img>Tron (TRX)
            </v-btn>
          </v-col>

          <v-col
            cols="12"
            sm="2"
            :style="windowWidth < 770 ? 'max-height:50px' : ''"
          >
            <v-btn color="white" text x-large style="text-transform: none !important;">
              <v-img :src="srcIcon" max-width="32" max-height="32" style="margin-right:10px">
              </v-img>Icon (ICX)
            </v-btn>
          </v-col>

          </v-layout>
          </v-parallax>


          <Tokenomics id="tokenomics" :dark="dark" :windowWidth="windowWidth"></Tokenomics>

          <TokenHolders id="tokenholders" :dark="dark" :windowWidth="windowWidth"></TokenHolders>

          <Timeline id="when" :dark="dark" :windowWidth="windowWidth"></Timeline>

          <!-- Collect Samples -->
          <v-parallax
            height="300"
            src="/img/home/paralax_02.jpeg"
          ></v-parallax>

          <Team id="team" :dark="dark" :windowWidth="windowWidth"></Team>

          <Contact id="contact" :dark="dark" :drawer="drawer" :windowWidth="windowWidth" v-on:signUpClicked="signUpDialog = true"
                   v-on:termsClicked="openTerms" v-on:privacyClicked="openPrivacy"></Contact>

        </v-layout>

      </v-layout>
    </v-slide-y-transition>


    <!-- ############################################## BOTTOM NAVIGATION ##############################################  -->

      <v-layout column align-center pt-2>
        <!-- v-show="!loadingData" -->
        <v-bottom-navigation v-if="showBottomNav"
          :input-value="showBottomNav"
          color="#1B5E20"
          background-color="lime lighten-5"
          fixed
        >

        <v-btn style="padding-left:20px;padding-right:0px">
          <span>Queue</span>
          <v-icon>mdi-format-list-bulleted-triangle</v-icon>
        </v-btn>

        <v-btn  style="padding-right:0px;padding-left:0px">
          <span>Launched</span>
          <v-icon>mdi-rocket-launch</v-icon>
        </v-btn>

        <v-btn style="padding-right:0px;padding-left:0px">
          <span>Promoted</span>
          <v-icon>mdi-bullhorn</v-icon>
        </v-btn>

        <v-btn  style="padding-right:0px;padding-left:0px">
          <span>New</span>
          <v-icon>mdi-expand-all</v-icon>
        </v-btn>

        <v-btn  style="padding-right:20px;padding-left:0px">
          <span>Favorites</span>
          <v-icon>mdi-star</v-icon>
        </v-btn>
        </v-bottom-navigation>

      </v-layout>


      <!-- ########################## COIN DETAIL DIALOG ########################## -->

      <v-dialog v-model="welcomeDialog" :fullscreen="windowWidth < 770" max-width="1000" persistent transition="dialog-bottom-transition">
        <v-card :dark="dark">
          <v-toolbar flat :color="dark ? '#454545' : 'green lighten-5'">
            <v-btn v-if="windowWidth < 770" icon @click="closeWelcomeClicked">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-img v-if="windowWidth > 770" :src="logoSrcHeader" max-width="280" max-height="42"></v-img>
            <v-img v-if="windowWidth < 770" :src="logoSrcHeader" max-width="200" max-height="30"></v-img>
            <v-spacer></v-spacer>
          </v-toolbar>
          <p class="mt-0"></p>
          <v-layout column align-center pa-4>
            <span style="font-size:20px" class="text-center font-weight-bold">Welcome to Token Monster (TMO)</span>
            <p class="mt-0"></p>
            <span style="font-size:18px" >Token Monster allows users to evaluate new tokens that come to market and see real-time information like Age, Market Capital (MC) and total Holders. It’s a one stop shop that will help investors to avoid/detect scams, all centralised in one platform.</span>
            <p class="mt-0"></p>
            <v-layout row wrap>
              <v-col
                 cols="12"
                 sm="6"
               >
                   <v-card
                     class="mx-auto"
                     :dark="dark"
                     min-height="360"
                     max-height="600"
                     :color="dark ? '#454545' : 'green lighten-5'"
                   >
                   <v-toolbar flat color="transparent" pa-2>
                     <!-- <v-icon :dark="!dark" x-large >mdi-script-text-outline</v-icon> -->
                     <p style="font-size:20px" class="font-weight-bold  mt-4">
                       FOR INVESTORS
                     </p>
                   </v-toolbar>

                        <v-list dense color="transparent">

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <span >Explore and search for Tokens and see real-time MC and Total holder information.</span>
                            </v-list-item-content>
                          </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <span >First token listing platform to discuss in chat with other holders/investors when sale/presale is on and social channels (Telegram) are muted.</span>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <span >More features and benefits for TMO Holders. Read more on the Tiers section.</span>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-icon>
                                <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <span >The TMO Token focuses on the implementation of tools and features to loyal token holders and the community.</span>
                              </v-list-item-content>
                            </v-list-item>
                        </v-list>

                   </v-card>
               </v-col>

               <v-col
                  cols="12"
                  sm="6"
                >
                    <v-card
                      class="mx-auto"
                      :dark="dark"
                      min-height="360"
                      max-height="600"
                      :color="dark ? '#454545' : 'green lighten-5'"
                    >
                    <v-toolbar flat color="transparent" pa-2>
                      <!-- <v-icon :dark="!dark" x-large >mdi-script-text-outline</v-icon> -->
                      <p style="font-size:20px" class="font-weight-bold  mt-4">
                        FOR USERS & PROMOTERS
                      </p>
                    </v-toolbar>
                    <v-list dense color="transparent">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span >Multi chain Promotions BSC/ETH/MATIC and more to come.</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span >Real-time countdowns to launch events.</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span >Like / voting system.</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span >Token Detail with Open Chat Discussion and Report Scams feature.</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span >Token Vetting Scores.</span>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="#388E3C">mdi-arrow-right-thin-circle-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span >See the Roadmap for more features to be released</span>
                          </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    </v-card>
                </v-col>
              </v-layout>

          </v-layout>

          <v-card-actions>
            <v-checkbox
              v-model="showWelcomeCheck"
              label="Do not show this window again"
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
               :color="dark ? '#388E3C' : 'green lighten-4'"
               :dark="dark"
               large
               @click="closeWelcomeClicked"
             >
               Close
             </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

       <v-dialog v-model="tokenDetailDialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
         <v-card :dark="dark">
           <v-toolbar v-if="windowWidth > 770" flat :color="dark ? 'grey darken-4' : 'grey lighten-3'">
             <v-btn text @click="closeTokenDetail">
               <v-icon style="margin-top:-2px" left>mdi-arrow-left</v-icon>Back to Home
             </v-btn>
             |
             <v-btn text to="/tokens/promoted">
               See All Tokens
             </v-btn>
             <v-spacer></v-spacer>
             <v-img v-if="windowWidth > 770" :src="logoSrcHeader" max-width="280" max-height="41"></v-img>
             <v-img v-if="windowWidth < 770" :src="logoSrcHeader" max-width="200" max-height="30"></v-img>
             <v-spacer></v-spacer>
             <v-spacer></v-spacer>
           </v-toolbar>
           <v-toolbar v-else flat :color="dark ? 'grey darken-4' : 'grey lighten-3'">
             <v-btn icon @click="closeTokenDetail">
               <v-icon>mdi-arrow-left</v-icon>
             </v-btn>
             <v-img v-if="windowWidth > 770" :src="logoSrcHeader" max-width="280" max-height="42"></v-img>
             <v-img v-if="windowWidth < 770" :src="logoSrcHeader" max-width="200" max-height="30"></v-img>
             <v-spacer></v-spacer>
           </v-toolbar>

           <TokenDetail ref="tokenDetailComp" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark"
                          :tokenItem="tokenItem" :drawer="drawer" v-on:connectWalletClicked="connectWallet">
           </TokenDetail>
         </v-card>
       </v-dialog>


       <v-dialog v-model="realnessDialog"  transition="dialog-bottom-transition" max-width="600">
         <v-card :dark="dark">
           <p class="mt-0"></p>
           <v-toolbar flat>
             <v-card-title style="font-size:22px" class="wrap-text font-weight-bold">Token Monster Realness Check</v-card-title>
             <v-spacer></v-spacer>
             <v-btn icon @click="realnessDialog = false">
               <v-icon>mdi-close</v-icon>
             </v-btn>
           </v-toolbar>

           <v-layout column pa-4 style="margin-left:20px"> <!-- We intend to do AMA on twitch very soon. -->

             <p>Token Monster has released the platform prior to any token raise' We are here to stay!<br><br>With so many valueless, shady projects and scams going around in this space, we believe it is a necessity for you to be able to see the real humans behind this project. Please, also see the Team Section for More info.</p>

             <p>We want you to have full transparency in who we are and we do, not hiding behind the crypto doors of anonymity.
This project is gonna be real and so are we.</p>

             <p>Therefore you can contact us directly via email, Telegram or phone.
We’ll be happy to answer your questions.</p>

             <p>Email: hello@tokenmonster.app<br><br>

<!-- Phone: +6281236664755 (Direct Line, WA and Telegram)<br><br> -->

No BS as we only want to create the real thing here.<br><br>

~ Try it out and enjoy a real human response ~</p>

           </v-layout>
         </v-card>
       </v-dialog>

      <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
        <v-icon style="margin-left:10px" color="green">mdi-check-circle-outline</v-icon>
        <span >{{ snackbarText }}</span>
              <v-btn text color="#1B5E20" @click.native="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
        <v-icon color="amber">mdi-alert-outline</v-icon>
        <span style="padding-left:10px">{{ snackbarText }}</span>
        <v-spacer></v-spacer>
      </v-snackbar>


  </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
import { db } from '@/main'
import HowToBuy from './HowToBuy'
import About from './About'
import Tokenomics from './Tokenomics'
import TokenHolders from './TokenHolders'
import Timeline from './Timeline'
import Team from './Team'
import Contact from './Contact'
import TokenDetail from './comp/TokenDetail'
import VueCountdown from '@chenfengyuan/vue-countdown'
import {scroller} from 'vue-scrollto/src/scrollTo'
const now = new Date()
const launchDateTime = new Date('2021-10-13 00:00:00')
export default {
  name: 'Home',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    welcome: String,
    isMobileDevice: Boolean
  },
  data () {
    return {
      // lang: this.$store.state.lang,
      fab: false,
      showBottomNav: false,
      loading: true,
      loadingData: false,
      loadingPromoted: true,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      titleCarousel: 0,
      launch: launchDateTime - now,
      nowList: new Date().getTime(),
      show: true,
      slugLoaded: false,
      resultInterval: null,
      welcomeDialog: false,
      showWelcomeCheck: false,
      tokenDetailDialog: false,
      realnessDialog: false,
      contractAddress: '0x49c248641eb904c9d5a33904d388212a89218187',
      srcPancake: '',
      srcPoocoin: '',
      srcBsc: '',
      srcBscDark: '',
      logoSrc: '',
      logoSrcDark: '',
      logoSrcHeader: '',
      srcTikTok: '',
      tokenItem: {},
      limitPromoted: 10,
      tempImage: '',
      srcPolygon: '',
      srcBinance: '',
      srcKuCoin: '',
      srcTron: '',
      srcPolkadot: '',
      srcCardano: '',
      srcSolana: '',
      srcHeco: '',
      localPromotedTokens: [],
      localLaunchQueueTokens: []
    }
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 770) {
        this.showBottomNav = false   // for now
      } else {
        this.showBottomNav = false
      }
    }
  },
  computed: {
    // getLanguage () {
      // return this.$store.state.user.language
    // },
    getUser () {
      return this.$store.state.user
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    getTheme () {
      return this.$store.state.navigation.theme
    },
    getPromotedTokens () {
      return this.$store.state.fb.promotedTokens
    },
    getLaunchQueueTokens () {
      return this.$store.state.fb.launchQueue
    },
    getLikes () {
      return this.$store.state.user.likes
    },
    getLatestBNB () {
      return this.$store.state.latestBNB
    },
    getLatestETH () {
      return this.$store.state.latestETH
    },
    getLatestMATIC () {
      return this.$store.state.latestMATIC
    },
    filterPromotedTokens () {
      var n = 7
      var array = this.getPromotedTokens
      // shuffled.sort((a, b) => {
        // return b.likes - a.likes
      // })
      var shuffled = array.sort(function(){return .5 - Math.random()}) // .sort((a, b) => { return b.likes - a.likes })
      // console.log(shuffled.slice(0, n))
      this.countImpressions(shuffled.slice(0, n))
      return shuffled.slice(0,n).sort((a, b) => { return b.likes - a.likes })
    },
    filterLaunchQueueTokens () {
      var n = 7
      var array = this.getLaunchQueueTokens
      var shuffled = array.sort(function(){return .5 - Math.random()}) // .sort((a, b) => { return a.launch_date - b.launch_date })
      // shuffled.sort((a, b) => {
        // return a.launch_date - b.launch_date
      // })
      this.countImpressions(shuffled.slice(0, n))
      return shuffled.slice(0, n).sort((a, b) => { return a.launch_date - b.launch_date })
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
  },
  components: {
    HowToBuy,
    About,
    Tokenomics,
    TokenHolders,
    Timeline,
    Team,
    Contact,
    TokenDetail,
    VueCountdown
  },
  created () {
    store.commit('ShowNav')
    this.init ()
  },
  methods: {
    init () {
      // console.log('### window width #######')
      // console.log(this.windowWidth)
      this.loading = true
      this.loadingPromoted = true
      this.showButtons()
      this.logoSrc = './img/logo/tokenmonster_logo.png'
      this.logoSrcHeader = './img/logo/tokenmonster_logo_01.png'
      this.logoSrcDark = './img/logo/tokenmonster_logo.png'
      this.srcdachshundWhite = './img/icons/dachshund_white.png'
      this.srcTikTok = './img/icons/tiktok_light_orange.png'
      this.srcTikTokWhite = './img/icons/tiktok_white.png'
      this.srcPancake = './img/icons/pancakeswap.png'
      this.srcPoocoin = './img/icons/poocoin.png'
      this.srcBsc = './img/icons/bscscan_light.png'
      this.srcBscDark = './img/icons/bscscan_dark.png'
      this.srcPolygon = './img/icons/polygon.png'
      this.srcBinance = './img/icons/binance-coin-logo.png'
      this.srcKuCoin = './img/icons/kucoin.png'
      this.srcTron = './img/icons/tron.png'
      this.srcPolkadot = './img/icons/polkadot.png'
      this.srcCardano = './img/icons/cardano.png'
      this.srcSolana = './img/icons/solana.png'
      this.srcHeco = './img/icons/hecochain.png'
      this.srcIcon = './img/icons/icon.png'

      // console.log('this.$route.params.slug')
      // console.log(this.$route.params.slug)
      if (this.$route.params.slug === undefined || this.$route.params.slug === null) {
        // console.log('no unique slug loading needed')
        this.loadPromotedTokens()
        this.checkWelcomeWindow()

        // Set the interval for loading the price quotes every minute
        this.resultInterval = setInterval(() => {
          this.pollPromotedTokens()
        }, 60000)
      } else {
        // console.log('show video - from slug')
        // console.log(this.$route.params.slug)
        //this.loading = false
        this.slugLoaded = true
        this.lookupTokenBySlug()
      }
    },
    checkWelcomeWindow() {
      if (this.getUser.welcome === null) {
        setTimeout(() => {
          this.checkWelcomeWindow()
          // console.log('this.getUser.welcome')
          // console.log(this.getUser.welcome)
          return
        }, 1000)
      }

      if (!this.getUser.showWelcomeSession && !this.getUser.showWelcome && !this.getUser.welcome && this.getUser.welcome !== null && !this.isMobileDevice) {
        this.welcomeDialog = true // check in store or user bucket to show or not
      }
    },
    showButtons () {
      this.show = false
      setTimeout(() => {
        this.show = true
        // this.loading = false
      }, 500)
    },
    connectWallet() {//
      this.$emit('connectWalletClicked')
    },
    loadPromotedTokens () {
      // Check if ad reload is needed.
      // To limit the database calls we refresh ads once per hour
      if (this.getPromotedTokens.length > 0) {
        let checkTime = Math.round(new Date().getTime() / 1000)
        if (this.getPromotedTokens[0].checkTime > Math.round(checkTime)) {
          var n = 7
          var array = this.getPromotedTokens
          var shuffled = array.sort(function(){return .5 - Math.random()}) // .sort((a, b) => { return b.likes - a.likes })
          this.countImpressions(shuffled.slice(0, n))
          this.localPromotedTokens = shuffled.slice(0,n).sort((a, b) => { return b.likes - a.likes })

          var array2 = this.getLaunchQueueTokens
          var shuffled2 = array2.sort(function(){return .5 - Math.random()}) // .sort((a, b) => { return b.likes - a.likes })
          this.countImpressions(shuffled2.slice(0, n))
          this.localLaunchQueueTokens = shuffled2.slice(0,n).sort((a, b) => { return a.launch_date - b.launch_date })

          this.loading = false
          this.loadingPromoted = false
          setTimeout(() => {
            // console.log('this.pollPromotedTokens() when this.getPromotedTokens.length > 0')
            this.pollPromotedTokens()
          }, 2000)
          return
        }
      }
      // console.log('Reload needed')
      this.loadingPromoted = true
      let dispatchObj = {
        limit: this.limitPromoted
      }
      this.$store.dispatch('getPromotedTokens', dispatchObj)
        .then(() => {
          var interval = setInterval(() => {
            if (this.getPromotedTokens.length > 0) {
              var n = 7
              var array = this.getPromotedTokens
              var shuffled = array.sort(function(){return .5 - Math.random()}) // .sort((a, b) => { return b.likes - a.likes })
              this.countImpressions(shuffled.slice(0, n))
              this.localPromotedTokens = shuffled.slice(0,n).sort((a, b) => { return b.likes - a.likes })

              this.pollPromotedTokens()
              this.loading = false
              this.loadingPromoted = false
              clearInterval(interval)

              /* if (this.$route.name === 'Tiers') {
                const firstScrollTo = scroller()
                setTimeout(() => {
                  firstScrollTo('#tokenholders')
                }, 3000)
              } */
            }
          }, 2000)

          var interval2 = setInterval(() => {
            if (this.getLaunchQueueTokens.length > 0) {
              var n = 7
              var array = this.getLaunchQueueTokens
              var shuffled = array.sort(function(){return .5 - Math.random()}) // .sort((a, b) => { return b.likes - a.likes })
              this.countImpressions(shuffled.slice(0, n))
              this.localLaunchQueueTokens = shuffled.slice(0,n).sort((a, b) => { return a.launch_date - b.launch_date })

              this.loading = false
              this.loadingPromoted = false
              clearInterval(interval2)
            }
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.loadingPromoted = false
        })
    },
    async pollPromotedTokens () {
      // console.log('pollPromotedTokens called')
      // console.log(this.getPromotedTokens.length)
      if (this.getPromotedTokens.length === 0) {
        setTimeout(() => {
          this.pollPromotedTokens()
        }, 1000)
        return
      }
      for (var i in this.getPromotedTokens) {
        let dispatchObj = {
          contract_address: this.getPromotedTokens[i].contract_address,
          network: this.getPromotedTokens[i].network,
          total_supply: this.getPromotedTokens[i].total_supply,
          latestBNB: this.getLatestBNB,
          latestETH: this.getLatestETH,
          latestMATIC: this.getLatestMATIC,
          list: 'promotedTokens'
        }
        if (this.getPromotedTokens[i].contract_address !== '') {
          this.$store.dispatch('getTokenPriceQuote', dispatchObj)
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.loadingPromoted = false
            })
        }
        await this.timer(1000)
      }
    },
    lookupTokenBySlug () {
      // console.log('lookupTokenBySlug called')
      // console.log(this.$route.params.slug)
      this.loadingData = true
      var slug = this.$route.params.slug
      let query
      if (slug.indexOf('0x') > -1) {
        query = db.collection('tokens').where('contract_address', '==', slug).limit(1)
      } else {
        query = db.collection('tokens').where('slug', '==', slug).limit(1)
      }
      console.log(query)
      query.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.')
          this.loadingData = false
          this.loading = false
          return
        }
        var obj
        snapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          obj = doc.data()
          obj.id = doc.id
          obj.readmore = true
          obj.price = 0
          obj.price_text = '0'
          obj.mc = 0
          // this.news.push(obj)
          // this.metaTitle = doc.data().title
        })
        // console.log(obj)
        this.openTokenDetailSlug(obj)
        this.loadingData = false
        this.loading = false
        // this.molaVideoDialog = true
      })
      .catch(err => {
        console.log('Error getting documents', err)
      })
    },
    timer(ms) {
      return new Promise(res => setTimeout(res, ms));
    },
    likeThisItem (item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = 'You have already liked this item'
        this.snackbarWarning = true
        return
      }
      store.commit('SetLikes', item.id)
      let dispatchObj = {
        likes: item.likes += 1
      }
      db.collection('tokens').doc(item.id).update(dispatchObj)
      .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = 'Your like has been recorded'
          this.snackbar = true
        })
      .catch(error => {
          console.log(error)
        })
    },
    lookupLiked (item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1
    },
    openTokenDetail (item) {
      this.tokenItem = item
      this.tokenDetailDialog = true
      if (item.contract_address !== '') {
        this.$router.replace('/token/' + item.contract_address)
      } else {
        this.$router.replace('/token/' + item.slug)
      }
      setTimeout(() => {
        this.$refs.tokenDetailComp.checkNewTokenOpened()
      }, 1000)
      this.countViews(item)
    },
    openTokenDetailSlug (item) {
      this.tokenItem = item
      this.tokenDetailDialog = true
      setTimeout(() => {
        this.$refs.tokenDetailComp.checkNewTokenOpened()
      }, 1000)
      this.countViews(item)
    },
    countImpressions (arr) {
      for (var i in arr) {
        let dispatchObj = {
          impressions: parseInt(arr[i].impressions) + 1
        }
        db.collection('tokens').doc(arr[i].id).update(dispatchObj)
        .then(() => {
            // console.log('Impressions  in token bucket updated')
            // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
            // this.snackbar = true
          })
        .catch(error => {
            console.log(error)
          })
      }
    },
    countViews (item) {
      let dispatchObj = {
        views: item.views += 1
      }
      db.collection('tokens').doc(item.id).update(dispatchObj)
      .then(() => {
          // console.log('Item views in token bucket updated')
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
      .catch(error => {
          console.log(error)
        })
    },
    closeTokenDetail () {
      this.tokenDetailDialog = false
      this.$router.replace('/')
      if (this.slugLoaded) {
        this.loadPromotedTokens()
        // Set the interval for loading the price quotes every minute
        this.resultInterval = setInterval(() => {
          // this.pollPromotedTokens()
        }, 60000)
        this.slugLoaded = false
      }
    },
    closeWelcomeClicked () {
      this.welcomeDialog = false
      store.commit('setWelcomeSession', true)
      if (this.showWelcomeCheck) {
        store.commit('setWelcome', true)
        if (this.getUser.isLoggedIn) {
          this.setWelcomeUser()
        }
      }
      const firstScrollTo = scroller()
      setTimeout(() => {
        firstScrollTo('#home')
      }, 200)
    },
    setWelcomeUser () {
      var obj = {
        welcome: true,
      }
      this.saveSettingsData(obj)
    },
    saveSettingsData (obj) {
      db.collection('users').doc(this.getUser.docId).update(obj)
        .then(() => {
            // console.log('User Account in bucket updated')
          })
        .catch(error => {
            console.log(error)
          })
    },
    openTerms () {
      this.$emit('termsClicked')
    },
    openPrivacy () {
      this.$emit('privacyClicked')
    },
    openRealness () {
      this.realnessDialog = true
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    gotoLink (link) {
      window.open(link, '_blank')
    },
    handleSuccess (e) {
      console.log(e);
      this.snackbarText = 'Contract Address copied.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' Sec ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' Min ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' Hrs ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' Days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' Months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' Years ago'
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap');
.homeTitle {
  font-family: 'Comfortaa';
  color:#2b7703;
  font-size: 32px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.homeTitleMobile {
  font-family: 'Comfortaa';
  color:#2b7703;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitle {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitleMobile {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.homeTextLarge {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 28px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.homeText {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.clockText {
  // font-family: 'Digital-7', monospace;
  font-size: 22px;
  // transform: translate(-50%, -50%);
  color: #1B5E20;
  text-shadow: 0 0 2px rgba(163, 163, 163, 1),  0 0 2px rgba(163, 163, 163, 1);
}
.clockDarkText{
  // font-family: 'Digital-7', monospace;
  font-size: 22px;
  // transform: translate(-50%, -50%);
  color: #F9FBE7;
  text-shadow: 0 0 2px rgba(163, 163, 163, 1),  0 0 2px rgba(163, 163, 163, 1);
}
.clock {
  font-family: 'Digital-7', monospace;
  font-size: 40px;
  // transform: translate(-50%, -50%);
  color: #1B5E20;
  text-shadow: 0 0 5px rgba(163, 163, 163, 1),  0 0 5px rgba(163, 163, 163, 1);
}
.clockDark{
  font-family: 'Digital-7', monospace;
  font-size: 40px;
  // transform: translate(-50%, -50%);
  color: #F9FBE7;
  text-shadow: 0 0 5px rgba(163, 163, 163, 1),  0 0 5px rgba(163, 163, 163, 1);
}
.myBgLight {
  background: linear-gradient(to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)), url('/img/home/home_light_01.jpeg') no-repeat;
  background-size: cover;
}
.myBgDark {
  background:url('/img/home/home_dark_02.jpeg') no-repeat;
  background-size: cover;
}
.v-skeleton-loader__image {
    height: 500px;
    border-radius: 0;
}
.wrap-text {
  word-break: normal;
}

/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

</style>
